import React, { forwardRef, useImperativeHandle } from "react";
import {
  InputAdornment,
  IconButton,
  Input,
  withStyles,
} from "@material-ui/core";
import { CloseCircle } from "mdi-material-ui";

const styles = {
  clearIcon: {
    width: 18,
    height: 18,
  },
};

function SearchTextField({ classes, onChange, ...other }, ref) {
  const searchRef = React.useRef();
  const [searchInput, setSearchInput] = React.useState("");

  useImperativeHandle(ref, () => ({
    setValue: (value) => {
      setSearchInput(value);
      onChange(value);
    },
  }));

  const handleChange = React.useCallback(
    (e) => {
      setSearchInput(e.target.value);
      onChange(e.target.value);
    },
    [onChange]
  );

  const handleClear = React.useCallback(() => {
    setSearchInput("");
    onChange("");
    searchRef.current.focus();
  }, [onChange]);

  React.useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "f" && e.ctrlKey) {
        e.preventDefault();
        searchRef.current.focus();
      } else if (
        e.key === "Escape" &&
        document.activeElement === searchRef.current
      ) {
        setSearchInput("");
        onChange("");
        searchRef.current.blur();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onChange]);

  return (
    <Input
      value={searchInput}
      onChange={handleChange}
      endAdornment={
        searchInput.length > 0 && (
          <InputAdornment position="end">
            <IconButton onClick={handleClear}>
              <CloseCircle className={classes.clearIcon} />
            </IconButton>
          </InputAdornment>
        )
      }
      inputRef={searchRef}
      {...other}
    />
  );
}

export default withStyles(styles)(forwardRef(SearchTextField));
