import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { from, concat, ApolloLink } from "apollo-link";
import { ProgressiveFragmentMatcher } from "apollo-progressive-fragment-matcher";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";

const fragmentMatcher = new ProgressiveFragmentMatcher();

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("skl-access-token");
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const offlineListeners = [];

export const addOfflineListener = (listener) => {
  offlineListeners.push(listener);
  return () => {
    const index = offlineListeners.indexOf(listener);
    if (index >= 0) {
      offlineListeners.splice(index, 1);
    }
  };
};

export const serverUrl =
  process.env.NODE_ENV === "production"
    ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
    : "http://localhost:3000";

export const createApolloClient = ({ onAuthError }) =>
  new ApolloClient({
    cache: new InMemoryCache({ fragmentMatcher }),
    link: from([
      onError(({ networkError, graphQLErrors }) => {
        if (
          networkError?.statusCode === 401 ||
          graphQLErrors?.some((e) => e.extensions?.exception?.status === 401)
        ) {
          onAuthError();
        }
      }),
      fragmentMatcher.link(),
      new ApolloLink((operation, forward) => {
        return forward(operation).map((response) => {
          const context = operation.getContext();
          const {
            response: { headers },
          } = context;

          if (headers.get("X-Offline") === "true") {
            // this instance if behind a proxy but upstream is offline
            offlineListeners.forEach((fn) => fn(true, headers.get("Date")));
          } else {
            offlineListeners.forEach((fn) => fn(false));
          }

          return response;
        });
      }),
      onError(({ networkError }) => {
        if (networkError?.response?.headers?.get("X-Offline") === "true") {
          // this instance if behind a proxy but upstream is offline and the proxy couldn't serve this request
          offlineListeners.forEach((fn) => fn(true, new Date()));
        }
      }),
      concat(
        authLink,
        createUploadLink({
          uri: `${serverUrl}/graphql`,
        })
      ),
    ]),
  });

export function getAuthConfig(token) {
  return fetch(`${serverUrl}/authentication`, {
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  }).then((res) => res.json());
}

export function getAccessToken(credentials) {
  if (credentials.accessToken) {
    const token = credentials.accessToken;
    return fetch(`${serverUrl}/authentication/profile`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    })
      .then((res) => res.json())
      .then((user) => ({ user, accessToken: token }));
  }

  return fetch(`${serverUrl}/authentication`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((res) => {
    if (res.status !== 201) {
      throw new Error("Not authenticated");
    }
    return res.json();
  });
}

export function getCurrentUser() {
  return fetch(`${serverUrl}/authentication/profile`, {
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  }).then((res) => {
    return res.json();
  });
}

export function getAuthorizationHeader() {
  const token = localStorage.getItem("skl-access-token");
  return token ? `Bearer ${token}` : undefined;
}
