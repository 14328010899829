import React from "react";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

export default function DraggablePaper({
  handle = "#draggable-paper-handle",
  DraggableProps,
  ...props
}) {
  return (
    <Draggable handle={handle} defaultClassName="draggable" {...DraggableProps}>
      <Paper {...props} />
    </Draggable>
  );
}

export function DraggableDiv({
  handle = "> *:first-child",
  DraggableProps,
  ...props
}) {
  return (
    <Draggable
      defaultClassNameDragging="draggableDivDragging"
      {...DraggableProps}
    >
      <div {...props} />
    </Draggable>
  );
}
