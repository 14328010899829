import React, { useMemo } from "react";
import { gql } from "graphql.macro";
import { useQuery, useMutation } from "react-apollo";
import Stock from "../components/Stock";
import {
  useArticleType,
  useEmployee,
  useFeatures,
} from "../../core/context/applicationContext";

export const articleFragment = gql`
  fragment StockArticle on Article {
    id
    name
    sku
    type
    minimumStock
    type
    details {
      ... on LongGoodDetails {
        specificWeight
        length
        material
      }
      ... on SheetDetails {
        specificWeight
        length
        width
        area
        thickness
        material
      }
      ... on BreakBulkDetails {
        weight
      }
      ... on CoilDetails {
        length
        width
        thickness
        weight
        material
      }
    }
    note {
      text
      lastUpdated
    }
    note2 {
      text
      lastUpdated
    }
    note3 {
      text
      lastUpdated
    }
  }
`;

const stockItemFragment = gql`
  fragment StockItemFragment on StockItem {
    id
    sku
    type
    initial
    in
    out
    details {
      ... on RestSheetStockItemDetails {
        width
        length
        area
        cutouts {
          x1
          y1
          x2
          y2
        }
      }
      ... on SheetStockItemDetails {
        restSheetCount
      }
      ... on LongGoodStockItemDetails {
        restLongGoodCount
      }
      ... on RestLongGoodStockItemDetails {
        length
      }
    }
    loadCarrier {
      id
      name
      compartment {
        id
        compartment
        tower {
          id
          location
        }
      }
    }
    compartment {
      id
      compartment
      tower {
        id
        location
      }
    }
    temporaryLocation
    temporaryCompartment
    note {
      text
      lastUpdated
    }
    note2 {
      text
      lastUpdated
    }
    note3 {
      text
      lastUpdated
    }
  }
`;

const aggregatedStockItemsFragment = gql`
  fragment AggregatedStockItemsFragment on AggregatedStockItems {
    id
    type
    initial
    in
    out
    article {
      ...StockArticle
    }
    stockItems {
      ...StockItemFragment
    }
    details {
      ... on AggregatedSheetStockItemDetails {
        restSheetCount
      }
      ... on AggregatedLongGoodStockItemDetails {
        restLongGoodCount
      }
    }
  }
  ${articleFragment}
  ${stockItemFragment}
`;

export const GET_STOCK = gql`
  query GetStock($type: StockType!, $articleType: ArticleType!) {
    aggregatedStock(type: $type, articleType: $articleType) {
      ...AggregatedStockItemsFragment
    }
    articleNoteColumns(articleType: $articleType)
    stockItemNoteColumns(stockItemType: $type)
  }
  ${aggregatedStockItemsFragment}
`;

const ADD_DELTA = gql`
  mutation AddDelta($itemId: ID!, $change: ChangeStockInput!) {
    updatedArticle: changeStock(id: $itemId, change: $change) {
      id
      type
      initial
      in
      out
      article {
        ...StockArticle
      }
    }
  }
  ${articleFragment}
`;

const CREATE_ITEM = gql`
  mutation CreateItem(
    $item: CreateItemInput!
    $initialStock: Float!
    $changeItemStock: Float
    $employee: String
    $note: String
  ) {
    createItem(
      item: $item
      initialStock: $initialStock
      changeItemStock: $changeItemStock
      employee: $employee
      note: $note
    ) {
      id
    }
  }
`;

const UPDATE_ARTICLE_NOTE = gql`
  mutation UpdateArticleNote(
    $articleId: ID!
    $noteIndex: Int!
    $text: String!
    $employee: String
  ) {
    updatedArticle: updateArticleNote(
      articleId: $articleId
      noteIndex: $noteIndex
      text: $text
      employee: $employee
    ) {
      ...StockArticle
    }
  }
  ${articleFragment}
`;

const UPDATE_ARTICLE_NOTE_COLUMN_TITLE = gql`
  mutation UpdateArticleNoteColumnTitle(
    $articleType: ArticleType!
    $noteIndex: Int!
    $title: String!
  ) {
    newTitle: setArticleNoteColumnTitle(
      articleType: $articleType
      index: $noteIndex
      title: $title
    ) {
      index
      title
    }
  }
`;

const UPDATE_STOCK_ITEM_NOTE = gql`
  mutation UpdateStockItemNote(
    $stockItemId: ID!
    $noteIndex: Int!
    $text: String!
    $employee: String
  ) {
    updatedItem: updateStockItemNote(
      stockItemId: $stockItemId
      noteIndex: $noteIndex
      text: $text
      employee: $employee
    ) {
      ...StockItemFragment
    }
  }
  ${stockItemFragment}
`;

const UPDATE_STOCK_ITEM_NOTE_COLUMN_TITLE = gql`
  mutation UpdateStockItemNoteColumnTitle(
    $stockItemType: StockType!
    $noteIndex: Int!
    $title: String!
  ) {
    newTitle: setStockItemNoteColumnTitle(
      stockItemType: $stockItemType
      index: $noteIndex
      title: $title
    ) {
      index
      title
    }
  }
`;

export default function StockContainer({ match }) {
  const [articleType] = useArticleType();
  const stockItemType = match || articleType;
  const [employee] = useEmployee();
  const features = useFeatures();

  //#region Mutations
  const [addDelta] = useMutation(ADD_DELTA);

  const [createItem] = useMutation(CREATE_ITEM, {
    refetchQueries: () => [
      { query: GET_STOCK, variables: { type: stockItemType, articleType } },
    ],
  });

  const [hideItem] = useMutation(
    gql`
      mutation HideStockItem($id: ID!) {
        hideItem(id: $id) {
          id
        }
      }
    `,
    {
      refetchQueries: () => [
        { query: GET_STOCK, variables: { type: stockItemType, articleType } },
      ],
    }
  );

  const [updateArticleNote] = useMutation(UPDATE_ARTICLE_NOTE);

  const [updateArticleNoteColumnTitle] = useMutation(
    UPDATE_ARTICLE_NOTE_COLUMN_TITLE,
    {
      update: (cache, { data: { newTitle } }) => {
        const data = cache.readQuery({
          query: GET_STOCK,
          variables: { type: stockItemType, articleType },
        });
        cache.writeQuery({
          query: GET_STOCK,
          variables: { type: stockItemType, articleType },
          data: {
            ...data,
            articleNoteColumns: data.articleNoteColumns.map((title, i) =>
              i === newTitle.index ? newTitle.title : title
            ),
          },
        });
      },
    }
  );

  const [updateStockItemNote] = useMutation(UPDATE_STOCK_ITEM_NOTE);

  const [updateStockItemNoteColumnTitle] = useMutation(
    UPDATE_STOCK_ITEM_NOTE_COLUMN_TITLE,
    {
      update: (cache, { data: { newTitle } }) => {
        const data = cache.readQuery({
          query: GET_STOCK,
          variables: { type: stockItemType, articleType },
        });
        cache.writeQuery({
          query: GET_STOCK,
          variables: { type: stockItemType, articleType },
          data: {
            ...data,
            stockItemNoteColumns: data.stockItemNoteColumns.map((title, i) =>
              i === newTitle.index ? newTitle.title : title
            ),
          },
        });
      },
    }
  );
  //#endregion

  // loading the articles for each stock item (which are the same as the aggregated stock itemarticle) is about
  // 10 times slower due to the huge payload that graphql needs to verify, so we don't do that and assemble it manually
  const { data, loading, error } = useQuery(GET_STOCK, {
    variables: {
      type: stockItemType,
      articleType,
    },
    fetchPolicy: "network-only",
    pollInterval: 15000,
  });
  const aggregatedStock = useMemo(
    () =>
      data?.aggregatedStock?.map((aggregatedStockItem) => {
        const row = {
          ...aggregatedStockItem,
          id: `aggregated-${aggregatedStockItem.id}`,
          stockItems: aggregatedStockItem.stockItems.map((stockItem) => ({
            ...stockItem,
            article: aggregatedStockItem.article,
          })),
        };
        row.stockItems.forEach((item) => (item.parent = row));
        return row;
      }) ?? [],
    [data]
  );

  return (
    <Stock
      stock={aggregatedStock}
      type={stockItemType}
      features={features}
      articleNoteColumns={data?.articleNoteColumns}
      stockItemNoteColumns={data?.stockItemNoteColumns}
      onCreateItem={(
        articleId,
        { initialStock, loadCarrierId, compartmentId },
        note
      ) =>
        createItem({
          variables: {
            item: {
              articleId,
              type: stockItemType,
              loadCarrierId,
              compartmentId,
            },
            initialStock,
            note,
            employee,
          },
        })
      }
      onUpdateItem={(item, { delta, note }) =>
        addDelta({
          variables: {
            itemId: item.id,
            change: {
              delta,
              note,
              employee,
            },
          },
        })
      }
      onHideStockItem={(item) => hideItem({ variables: { id: item.id } })}
      onUpdateArticleNote={(item, noteIndex, text) =>
        updateArticleNote({
          variables: {
            articleId: item.article.id,
            noteIndex,
            text,
            employee,
          },
        })
      }
      onUpdateArticleNoteColumnTitle={(noteIndex, title) =>
        updateArticleNoteColumnTitle({
          variables: {
            noteIndex,
            title,
            articleType,
          },
        })
      }
      onUpdateStockItemNote={(item, noteIndex, text) =>
        updateStockItemNote({
          variables: {
            stockItemId: item.id,
            noteIndex,
            text,
            employee,
          },
        })
      }
      onUpdateStockItemNoteColumnTitle={(noteIndex, title) =>
        updateStockItemNoteColumnTitle({
          variables: {
            noteIndex,
            title,
            stockItemType,
          },
        })
      }
      error={error}
      loading={loading}
    />
  );
}
