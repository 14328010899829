import { ChangeEvent, useCallback, useState } from "react";

export default function useInputHandlers<T extends string>(
  fields: Record<
    T,
    {
      defaultValue?: string | number | boolean;
    }
  >
): [
  Record<T, string>,
  (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void,
  (newValues: Partial<Record<T, string | number | boolean>>) => void
] {
  const [values, setValues] = useState<Record<T, string>>(
    Object.fromEntries(
      Object.entries(fields).map(([name, field]) => [
        name,
        (
          field as { defaultValue?: string | number | boolean }
        ).defaultValue?.toString() ?? "",
      ])
    ) as any
  );

  const handleChange = useCallback(
    (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
      const fieldName = e.currentTarget.dataset.field;
      if (!fieldName) {
        console.error("Missing data-field attribute", e.currentTarget);
        return;
      }
      let value = e.currentTarget.value ?? "";
      setValues((oldValues) => ({
        ...oldValues,
        [fieldName]: value,
      }));
    },
    []
  );

  const publicSetValues = useCallback(
    (newValues: Partial<Record<T, string | number | boolean>>) => {
      setValues((values) => ({
        ...values,
        ...Object.fromEntries(
          Object.entries(newValues).map(([name, value]: [string, any]) => [
            name,
            value?.toString() ?? "",
          ])
        ),
      }));
    },
    []
  );

  return [values, handleChange, publicSetValues];
}
