export function escape(str, delimiter = ";", quotes = '"') {
  if (str.indexOf(delimiter) >= 0 || str.indexOf(quotes) >= 0) {
    const escaped = (str + "").replace(/"/g, '""').replace(/[\n\r]/g, " ");
    return escaped.length > 0 ? `${quotes}${escaped}${quotes}` : "";
  }
  return (str + "").replace(/[\n\r]/g, " ");
}

export function concat(rows, delimiter = ";", quotes = '"', newline = "\r\n") {
  return rows
    .map((cells) =>
      cells.map((c) => escape(c, delimiter, quotes)).join(delimiter)
    )
    .join(newline);
}

export async function saveCsv(rows, filename) {
  const [{ saveAs }, iconv] = await Promise.all([
    import("file-saver"),
    import("iconv-lite"),
  ]);
  saveAs(
    new Blob([iconv.encode(concat(rows), "win1252")], {
      type: "text/csv;charset=win1252",
    }),
    filename
  );
}

export async function saveXlsx(
  [titleRow, ...rows],
  filename,
  worksheetName,
  customizeWorksheet
) {
  const [{ saveAs }, ExcelJS] = await Promise.all([
    import("file-saver"),
    import("exceljs"),
  ]);

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(worksheetName);
  worksheet.addRow(titleRow);
  worksheet.views = [{ state: "frozen", ySplit: 1 }];
  worksheet.properties.defaultColWidth = 20;
  worksheet.addRows(rows);

  if (customizeWorksheet) {
    customizeWorksheet(worksheet);
  }

  const data = await workbook.xlsx.writeBuffer();
  saveAs(
    new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
    filename
  );
}
