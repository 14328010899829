import React from "react";
import {
  withStyles,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import UnitTextField from "../../../components/UnitTextField";
import { useRegistry } from "../../plugins/registry";
import ComponentExtensionPoint from "../../plugins/ComponentExtensionPoint";
import DefaultArticleLocation from "../../articles/components/DefaultArticleLocation";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  method: {
    margin: "4px 0 0 -8px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  fields: {
    maxWidth: 420,
    margin: "8px 0 16px",
    display: "flex",
    "& > *": {
      flex: 1,
    },
    "& > *:first-child:not(:last-child)": {
      marginRight: 8,
    },
    "& > *:last-child:not(:first-child)": {
      marginLeft: 8,
    },
  },
  noteField: {
    margin: "4px 0 16px",
    width: "100%",
    "& textarea": {
      minHeight: 20, // fix scroll bar showing if empty
    },
  },
  bottom: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    "& > *": {
      flex: 1,
    },
  },
  checkbox: { padding: "6px 6px 6px 12px" },
  submit: {
    flex: "none",
  },
});

function CreateRestLongGoodForm({ classes, item, onSubmit, withCount }) {
  const { t } = useTranslation();
  const registry = useRegistry();

  const [length, setLength] = React.useState(0);
  const [lengthInput, setLengthInput] = React.useState("0");

  const [count, setCount] = React.useState(1);
  const [countInput, setCountInput] = React.useState("1");

  const [compartment, setCompartment] = React.useState(item.compartment?.id);
  const [loadCarrier, setLoadCarrier] = React.useState(item.loadCarrier?.id);
  const [note, setNote] = React.useState("");

  const currentStock = item.initial + item.in - item.out;
  const [updateStock, setUpdateStock] = React.useState(currentStock > 0);

  const handleSelectInput = (e) => e.target.select();

  const handleChangeDelta = (e) => {
    setLengthInput(e.target.value);
    setLength(parseFloat(e.target.value) / 1000);
  };

  const handleChangeCount = (e) => {
    setCountInput(e.target.value);
    setCount(parseInt(e.target.value, 10));
  };

  const handleSubmit = () => {
    onSubmit({
      articleId: item.article.id,
      length,
      count,
      loadCarrier,
      compartment,
      updateStock: updateStock && canUpdateStock,
      note,
    });
  };

  const itemType = registry.stockItemTypes[item.type];

  const stockDelta = Math.ceil(
    (length * count) / (item.details?.length ?? item.article.details.length)
  );
  const canUpdateStock =
    currentStock > 0 &&
    stockDelta > 0 &&
    (item.type !== "longGood" ||
      (length * count) / item.article.details.length > 0);

  const invalidCount =
    isNaN(count) ||
    length * count <= 0 ||
    (item.type === "longGood"
      ? length * count > item.initial + item.in - item.out
      : length * count >
        (item.initial + item.in - item.out) * item.details?.length);
  const invalidLength =
    isNaN(length) ||
    length <= 0 ||
    (item.type === "longGood"
      ? length > item.article.details.length
      : length > item.details?.length);

  return (
    <React.Fragment>
      <Typography variant="body1">{t("Restlänge anlegen")}</Typography>
      <Typography variant="caption">
        {itemType.displayName(1, { t })} &middot; {item.article.name} &middot;{" "}
        {item.sku ?? item.article.sku}
      </Typography>
      <div className={classes.fields}>
        {withCount && (
          <UnitTextField
            type="number"
            label={t("Anzahl")}
            autoFocus
            InputLabelProps={{ shrink: true }}
            unit={t("Stk.")}
            error={updateStock && canUpdateStock && invalidCount}
            value={countInput}
            onChange={handleChangeCount}
            onFocus={handleSelectInput}
          />
        )}
        <UnitTextField
          type="number"
          label={t("Länge")}
          autoFocus={!withCount}
          InputLabelProps={{ shrink: true }}
          unit="mm"
          error={updateStock && canUpdateStock && invalidLength}
          value={lengthInput}
          onChange={handleChangeDelta}
          onFocus={handleSelectInput}
        />
      </div>
      <div className={classes.fields}>
        <ComponentExtensionPoint
          name="articleFormLocation"
          variant="single"
          innerProps={{
            variant: "standard",
            margin: "normal",
            value: { compartment, loadCarrier },
            onChange: ({ compartment, loadCarrier }) => {
              setCompartment(compartment);
              setLoadCarrier(loadCarrier);
            },
            InputLabelProps: { shrink: true },
          }}
          DefaultComponent={DefaultArticleLocation}
        />
      </div>
      <TextField
        className={classes.noteField}
        label={t("Bemerkung")}
        InputLabelProps={{ shrink: true }}
        placeholder={t("(optional)")}
        fullWidth
        multiline
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      <div className={classes.bottom}>
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkbox}
              onChange={(e) => setUpdateStock(e.target.checked)}
              checked={updateStock && canUpdateStock}
              color="primary"
            />
          }
          label={
            canUpdateStock
              ? t("Bestand verändern (-{{count}})", {
                  count: itemType.stockInfo.formatWithUnit(
                    item.type === "longGood"
                      ? stockDelta * item.article.details.length
                      : stockDelta,
                    item.article,
                    { t }
                  ),
                })
              : t("Bestand verändern")
          }
          disabled={!canUpdateStock}
        />
        <Button
          className={classes.submit}
          variant="outlined"
          color="primary"
          disabled={
            updateStock && canUpdateStock && (invalidCount || invalidLength)
          }
          onClick={handleSubmit}
        >
          {t("Restlänge anlegen", { count })}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles)(CreateRestLongGoodForm);
