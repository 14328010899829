import React from "react";
import PropTypes from "prop-types";
import { blueGrey } from "@material-ui/core/colors";
import {
  Popover,
  Typography,
  Select,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import { DraggableDiv } from "../../../components/DraggablePaper";
import ShapeSelector from "./ShapeSelector";
import { useTranslation } from "react-i18next";

const paint = (canvas, { sheetWidth, sheetLength, cutouts }) => {
  const ctx = canvas.getContext("2d");
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  const scale = Math.min(
    canvas.width / sheetWidth,
    canvas.height / sheetLength
  );

  ctx.save();
  ctx.translate(
    Math.floor(0.5 * (canvas.width - sheetWidth * scale)),
    Math.floor(0.5 * (canvas.height - sheetLength * scale))
  );
  ctx.fillStyle = blueGrey[400];
  ctx.scale(scale, scale);
  ctx.beginPath();
  ctx.rect(0, 0, sheetWidth, sheetLength);
  ctx.fill();
  ctx.closePath();

  if (cutouts != null) {
    for (const cutout of cutouts) {
      ctx.clearRect(
        cutout.x1,
        cutout.y1,
        cutout.x2 - cutout.x1,
        cutout.y2 - cutout.y1
      );
    }
  }
  ctx.restore();
};

const styles = {
  popoverPaper: {
    padding: 16,
    "&.draggableDivDragging": {
      transition: "opacity 355ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
    },
  },
  gridHeader: {
    cursor: "default",
    display: "flex",
    alignItems: "flex-end",
    marginBottom: 8,
    "& *:first-child": {
      flex: 1,
    },
  },
};

function ShapePreview({
  classes,
  cutouts,
  sheetWidth,
  sheetLength,
  sku,
  ...other
}) {
  const { t } = useTranslation();
  const canvasRef = React.useRef();
  const setCanvasRef = (canvas) => {
    canvasRef.current = canvas;
    if (canvas != null) {
      paint(canvasRef.current, { sheetWidth, sheetLength, cutouts });
    }
  };

  React.useEffect(() => {
    if (canvasRef.current != null) {
      paint(canvasRef.current, { sheetWidth, sheetLength, cutouts });
    }
  }, [sheetWidth, sheetLength, cutouts]);

  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [gridSize, setGridSize] = React.useState(100);

  return (
    <>
      <canvas
        ref={setCanvasRef}
        {...other}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setPopoverOpen(true);
        }}
      />
      <Popover
        anchorEl={canvasRef.current}
        open={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        classes={{ paper: classes.popoverPaper }}
        onClick={(e) => e.stopPropagation()}
        PaperProps={{
          component: DraggableDiv,
          DraggableProps: {
            handle: `.${classes.gridHeader}`,
          },
        }}
      >
        <div className={classes.gridHeader}>
          <div>
            <Typography variant="body1">{t("Ausschnitt")}</Typography>
            <Typography variant="caption">
              {t("Restblech")} &middot; {sku}
            </Typography>
          </div>
          <Select
            value={gridSize}
            onChange={(e) => setGridSize(e.target.value)}
            renderValue={(value) =>
              `${t("Rastergröße")}: ${value.toLocaleString()} mm`
            }
            disableUnderline
          >
            <MenuItem value={10}>10 mm</MenuItem>
            <MenuItem value={100}>100 mm</MenuItem>
            <MenuItem value={500}>500 mm</MenuItem>
          </Select>
        </div>
        <ShapeSelector
          grid={gridSize}
          value={cutouts}
          sheetWidth={sheetWidth}
          sheetLength={sheetLength}
          width={400}
          height={400}
        />
      </Popover>
    </>
  );
}

ShapePreview.propTypes = {
  classes: PropTypes.object.isRequired,
  cutouts: PropTypes.arrayOf(
    PropTypes.shape({
      x1: PropTypes.number.isRequired,
      y1: PropTypes.number.isRequired,
      x2: PropTypes.number.isRequired,
      y2: PropTypes.number.isRequired,
    })
  ),
  sheetWidth: PropTypes.number.isRequired,
  sheetLength: PropTypes.number.isRequired,
  sku: PropTypes.string,
};

export default withStyles(styles)(ShapePreview);
