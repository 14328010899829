import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import preval from "babel-plugin-preval/macro";

const styles = (theme) => ({
  version: { marginBottom: 24 },
  category: { fontWeight: "bold", color: theme.palette.text.primary },
  changes: { marginTop: 0, color: theme.palette.text.primary },
});

const changelogs = {
  en: preval`module.exports = require('./parse.js')('../../../../../CHANGELOG.md')`,
  de: preval`module.exports = require('./parse.js')('../../../../../CHANGELOG.de.md')`,
};

const categories = [
  "added",
  "changed",
  "deprecated",
  "removed",
  "fixed",
  "security",
];

function ChangelogDialog({ classes, onClose, ...other }) {
  const { t, i18n } = useTranslation();
  const changelog = changelogs[i18n.language] || changelogs.en;
  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} {...other}>
      <DialogTitle>{t("Änderungshistorie")}</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          {changelog
            .filter(({ notes }) =>
              Object.values(notes).some((notes) => notes.length > 0)
            )
            .map(({ version, date, notes }) => (
              <div key={version} className={classes.version}>
                <Typography variant="h6">
                  StoreKeeper Smart {version}
                </Typography>
                {date && (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    gutterBottom
                  >
                    {new Date(date).toLocaleDateString(undefined, {
                      dateStyle: "long",
                    })}
                  </Typography>
                )}
                {categories
                  .map((category) => ({ category, items: notes[category] }))
                  .filter(({ items }) => items?.length > 0)
                  .map(({ category, items }) => (
                    <React.Fragment key={category}>
                      <Typography variant="body1" className={classes.category}>
                        {t(`changelog:${category}`)}
                      </Typography>
                      <ul className={classes.changes}>
                        {items.map((f, i) => (
                          <li key={i}>{f}</li>
                        ))}
                      </ul>
                    </React.Fragment>
                  ))}
              </div>
            ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus={other.open} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(ChangelogDialog);
