import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { gql } from "graphql.macro";
import { Button, Popover, withStyles } from "@material-ui/core";
import LoadCarriersTable from "./LoadCarriersTable";
import { useSnackbar } from "material-ui-snackbar-provider";
import LoadCarrierForm from "./LoadCarrierForm";
import TablePlaceholder from "../../components/TablePlaceholder";
import { Trans, useTranslation } from "react-i18next";

const loadCarrierFragment = gql`
  fragment LoadCarrierFragment on LoadCarrier {
    id
    name
    rfidTags {
      id
      rfidTag
    }
    compartment {
      id
      compartment
      tower {
        id
        location
      }
    }
    stockItems {
      id
      sku
      type
      article {
        id
        name
        sku
      }
    }
  }
`;

export const GET_LOAD_CARRIERS = gql`
  {
    loadCarriers {
      ...LoadCarrierFragment
    }
  }
  ${loadCarrierFragment}
`;

const CREATE_LOAD_CARRIER = gql`
  mutation CreateLoadCarrier($loadCarrier: CreateLoadCarrierInput!) {
    createLoadCarrier(loadCarrier: $loadCarrier) {
      ...LoadCarrierFragment
    }
  }
  ${loadCarrierFragment}
`;

const UPDATE_LOAD_CARRIER = gql`
  mutation UpdateLoadCarrier($id: ID!, $loadCarrier: UpdateLoadCarrierInput!) {
    updateLoadCarrier(id: $id, loadCarrier: $loadCarrier) {
      ...LoadCarrierFragment
    }
  }
  ${loadCarrierFragment}
`;

const REMOVE_LOAD_CARRIER = gql`
  mutation RemoveLoadCarrier($id: ID!) {
    removeLoadCarrier(id: $id) {
      id
    }
  }
`;

const ASSIGN_RFID_BY_COMPARTMENT = gql`
  mutation AssignLoadCarrierRfidByCompartment(
    $loadCarrierId: ID!
    $compartmentId: ID!
  ) {
    addLoadCarrierRfidTagByCompartment(
      loadCarrierId: $loadCarrierId
      compartmentId: $compartmentId
    ) {
      loadCarrier {
        ...LoadCarrierFragment
      }
      compartment {
        id
        compartment
      }
      newRfidTag {
        id
        rfidTag
      }
    }
  }
  ${loadCarrierFragment}
`;

const styles = {
  root: {
    padding: "24px 24px 14px",
    flex: 1,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    marginBottom: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  table: {
    flex: 1,
  },
  deltaPopover: {
    padding: 16,
    marginTop: -8,
    marginLeft: 24,
    minWidth: 350,
  },
};

function LoadCarriersContainer({ classes }) {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const { data, error, loading } = useQuery(GET_LOAD_CARRIERS, {
    fetchPolicy: "network-only",
    pollInterval: 15000,
  });

  const [createLoadCarrierMutation] = useMutation(CREATE_LOAD_CARRIER, {
    update: (cache, { data: { createLoadCarrier: newLoadCarrier } }) => {
      const { loadCarriers } = cache.readQuery({ query: GET_LOAD_CARRIERS });
      cache.writeQuery({
        query: GET_LOAD_CARRIERS,
        data: { loadCarriers: [...loadCarriers, newLoadCarrier] },
      });
    },
  });

  const [updateLoadCarrierMutation] = useMutation(UPDATE_LOAD_CARRIER);

  const [selectedLoadCarrier, setSelectedLoadCarrier] = useState();
  const [anchorEl, setAnchorEl] = useState();

  const handleShowNewLoadCarrierForm = useCallback((e) => {
    setAnchorEl(e.currentTarget);
    setSelectedLoadCarrier({ id: null, name: "", rfidTag: null });
    setUpdateLoadCarrierDialogOpen(true);
  }, []);

  const handleSaveLoadCarrier = useCallback(
    async (loadCarrier) => {
      if (loadCarrier.id == null) {
        try {
          await createLoadCarrierMutation({
            variables: {
              loadCarrier: {
                name: loadCarrier.name,
              },
            },
          });
          snackbar.showMessage(
            t('Der Ladungsträger "{{loadCarrier}}" wurde angelegt', {
              loadCarrier: loadCarrier.name,
            })
          );
          setUpdateLoadCarrierDialogOpen(false);
        } catch (e) {
          console.error(e);
          snackbar.showMessage(
            t("Der Ladungsträger konnte nicht erstellt werden")
          );
        }
      } else {
        try {
          await updateLoadCarrierMutation({
            variables: {
              id: loadCarrier.id,
              loadCarrier: { name: loadCarrier.name },
            },
          });
          snackbar.showMessage(
            t('Der Ladungsträger "{{loadCarrier}}" wurde aktualisiert', {
              loadCarrier: loadCarrier.name,
            })
          );
          setUpdateLoadCarrierDialogOpen(false);
        } catch (e) {
          console.error(e);
          snackbar.showMessage(
            t("Der Ladungsträger konnte nicht aktualisiert werden")
          );
        }
      }
    },
    [createLoadCarrierMutation, updateLoadCarrierMutation, snackbar, t]
  );

  const [removeLoadCarrierMutation] = useMutation(REMOVE_LOAD_CARRIER, {
    update: (cache, { data: { removeLoadCarrier } }) => {
      const { loadCarriers } = cache.readQuery({ query: GET_LOAD_CARRIERS });
      cache.writeQuery({
        query: GET_LOAD_CARRIERS,
        data: {
          loadCarriers: loadCarriers.filter(
            (l) => l.id !== removeLoadCarrier.id
          ),
        },
      });
    },
  });
  const handleRemoveLoadCarrier = useCallback(
    async ({ id, name }) => {
      try {
        await removeLoadCarrierMutation({ variables: { id } });
        snackbar.showMessage(
          t('Der Ladungsträger "{{loadCarrier}}" wurde entfernt', {
            loadCarrier: name,
          })
        );
        setUpdateLoadCarrierDialogOpen(false);
      } catch (e) {
        console.error(e);
        snackbar.showMessage(
          t(
            'Der Ladungsträger "{{loadCarrier}}" konnte nicht entfernt werden',
            { loadCarrier: name }
          )
        );
      }
    },
    [removeLoadCarrierMutation, snackbar, t]
  );

  const [autoRfidMutation] = useMutation(ASSIGN_RFID_BY_COMPARTMENT, {
    refetchQueries: [{ query: GET_LOAD_CARRIERS }],
  });
  const handleAutoRfid = useCallback(
    async (loadCarrier, compartmentId) => {
      try {
        const {
          data: { addLoadCarrierRfidTagByCompartment: data },
        } = await autoRfidMutation({
          variables: { loadCarrierId: loadCarrier.id, compartmentId },
        });
        if (data.newRfidTag) {
          snackbar.showMessage(
            t(
              'Der RFID-Tag "{{rfidTag}}" wurde dem Ladungsträger "{{loadCarrier}}" zugewiesen',
              {
                rfidTag: data.newRfidTag.rfidTag,
                loadCarrier: loadCarrier.name,
              }
            )
          );
        } else {
          snackbar.showMessage(
            t('Am Fach "{{compartment}}" wurde kein RFID-Tag erkannt', {
              compartment: data.compartment.compartment,
            })
          );
        }
      } catch (e) {
        console.error(e);
        snackbar.showMessage(
          t(
            'Der RFID-Tag für den Ladungsträger "{{loadCarrier}}" konnte nicht übernommen werden',
            { loadCarrier: loadCarrier.name }
          )
        );
      }
    },
    [autoRfidMutation, snackbar, t]
  );

  const [updateLoadCarrierDialogOpen, setUpdateLoadCarrierDialogOpen] =
    useState(false);
  const handleRowClick = useCallback(
    (e, rowId) => {
      setAnchorEl(e.currentTarget.getElementsByTagName("TD")[0]);
      setSelectedLoadCarrier(data.loadCarriers.find((l) => l.id === rowId));
      setUpdateLoadCarrierDialogOpen(true);
    },
    [data]
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Button variant="outlined" onClick={handleShowNewLoadCarrierForm}>
          {t("Ladungsträger hinzufügen")}
        </Button>
      </div>
      <LoadCarriersTable
        data={loading ? null : data?.loadCarriers ?? []}
        className={classes.table}
        size="small"
        EnhancedTableHeadProps={{ size: "medium" }}
        onRowClick={handleRowClick}
        placeholder={
          <TablePlaceholder>
            {error ? (
              <>
                {t("Beim Laden der Ladungsträger ist ein Fehler aufgetreten.")}
                <br />
                <br />
                {error.graphQLErrors?.length === 0 &&
                  t("Bitte überprüfen Sie Ihre Internetverbindung.")}
              </>
            ) : (
              <>
                {t("Es wurden noch keine Ladungsträger angelegt.")}
                <br />
                <br />
                <Trans>
                  Klicken Sie auf <em>Ladungsträger hinzufügen</em>, um einen
                  neuen Ladungsträger anzulegen.
                </Trans>
              </>
            )}
          </TablePlaceholder>
        }
      />
      <Popover
        open={updateLoadCarrierDialogOpen}
        onClose={() => setUpdateLoadCarrierDialogOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: classes.deltaPopover }}
      >
        {selectedLoadCarrier != null && (
          <LoadCarrierForm
            loadCarrier={selectedLoadCarrier}
            onSubmit={handleSaveLoadCarrier}
            onRemove={handleRemoveLoadCarrier}
            onAutoRfid={handleAutoRfid}
          />
        )}
      </Popover>
    </div>
  );
}

export default withStyles(styles)(LoadCarriersContainer);
