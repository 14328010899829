import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/fr";
import "moment/locale/es";
import "moment/locale/ru";
import "moment/locale/it";
import "moment/locale/bg";

export const languages = {
  de: { name: "Deutsch", moment: "de" },
  en: { name: "English", moment: "en-gb" },
  fr: { name: "Français", moment: "fr" },
  es: { name: "Español", moment: "es" },
  ru: { name: "Русский", moment: "ru" },
  it: { name: "Italiano", moment: "it" },
  bg: { name: "Италиански", moment: "bg" },
};
