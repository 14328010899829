export const commonCreateArticleFields = ({ allowDecimals }) => ({
  sku: {
    defaultValue: "",
    error: (sku) => sku.length === 0,
  },
  name: {
    defaultValue: "",
  },
  initialStock: {
    defaultValue: [],
    error: (initialStock) => initialStock.some((item) => item.amount < 0),
    convertOnSubmit: (initialStock) =>
      initialStock.map(({ amount, location }) => ({
        ...location,
        amount:
          nullIfNaN(
            allowDecimals ? parseFloat(amount) : parseInt(amount, 10)
          ) ?? 0,
      })),
  },
  minimumStock: {
    defaultValue: 0,
    error: (minimumStock) => minimumStock < 0,
    convert: allowDecimals
      ? (value) => nullIfNaN(parseFloat(value))
      : (value) => nullIfNaN(parseInt(value, 10)),
  },
  note: {
    defaultValue: "",
  },
  note2: {
    defaultValue: "",
  },
  note3: {
    defaultValue: "",
  },
});

export const commonEditArticleFields = ({ allowDecimals }) => ({
  sku: {
    defaultValue: "",
    error: (sku) => sku.length === 0,
  },
  name: {
    defaultValue: "",
  },
  minimumStock: {
    defaultValue: null,
    error: (minimumStock) => minimumStock < 0,
    convert: allowDecimals
      ? (value) => nullIfNaN(parseFloat(value))
      : (value) => nullIfNaN(parseInt(value, 10)),
    convertBack: (value) => (value == null ? "" : value),
  },
});

export function nullIfNaN(number) {
  return isNaN(number) ? null : number;
}
