import { commonEditArticleFields } from "../../../articles/components/articleForm";

export default {
  ...commonEditArticleFields({ allowDecimals: false }),
  "details.weight": {
    defaultValue: null,
    error: weight => !isNaN(weight) && parseFloat(weight) <= 0,
    convert: value => parseFloat(value)
  }
};
