import React from "react";
import PropTypes from "prop-types";
import { Popover } from "@material-ui/core";
import { PopoverOrigin, PopoverProps } from "@material-ui/core/Popover";
import TooltipIconButton, { TooltipIconButtonProps } from "./TooltipIconButton";

export type PopoverIconButtonProps = {
  icon: React.ComponentType;
  IconProps?: any;
  children:
    | React.ReactElement<unknown, string | React.JSXElementConstructor<any>>
    | ((params: { closePopover: () => void }) => React.ReactChild);
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  closeOnClick?: boolean;
  PopoverProps?: PopoverProps;
  onOpen?: () => void;
} & Omit<TooltipIconButtonProps, "buttonRef" | "onClick">;

export default function PopoverIconButton({
  icon: Icon,
  IconProps,
  children,
  anchorOrigin,
  transformOrigin,
  closeOnClick,
  PopoverProps,
  onOpen,
  ...other
}: PopoverIconButtonProps) {
  const [open, setOpen] = React.useState(false);
  const anchorEl = React.useRef<HTMLElement>();

  return (
    <React.Fragment>
      <TooltipIconButton
        {...other}
        buttonRef={anchorEl}
        onClick={() => {
          const wasOpen = open;
          setOpen(!open);
          if (!wasOpen && onOpen) {
            onOpen();
          }
        }}
      >
        <Icon {...IconProps} />
      </TooltipIconButton>
      <Popover
        open={open}
        anchorEl={anchorEl.current}
        onClose={(e) => {
          if (!anchorEl.current?.contains(e.target as Node)) {
            setOpen(false);
          }
        }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClick={closeOnClick ? () => setOpen(false) : undefined}
        {...PopoverProps}
      >
        {React.isValidElement(children)
          ? children
          : children({
              closePopover: () => setOpen(false),
            })}
      </Popover>
    </React.Fragment>
  );
}

PopoverIconButton.propTypes = {
  icon: PropTypes.elementType.isRequired,
  IconProps: PropTypes.object,
  PopoverProps: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  /**
   * If set to true, the popover will close on click. This is perfect for menus.
   */
  closeOnClick: PropTypes.bool,
  onOpen: PropTypes.func,
};
