import { MenuItem, Select } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { languages } from "../i18n";

export default function LanguageSelector(props) {
  const { i18n } = useTranslation();

  return (
    <Select
      value={i18n.language}
      onChange={(e) => i18n.changeLanguage(e.target.value)}
      inputProps={{
        name: "language",
        id: "language",
      }}
      {...props}
    >
      {Object.entries(languages).map(([code, { name }]) => (
        <MenuItem key={code} value={code}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
}
