import React, { useMemo } from "react";
import EnhancedTable from "@wa/werkstoff-data-table";
import withLocalStorageConfig from "@wa/werkstoff-data-table/lib/withLocalStorageConfig";
import withVerticalScroll from "@wa/werkstoff-data-table/lib/withVerticalScroll";
import withFixedColumns from "@wa/werkstoff-data-table/lib/withFixedColumns";
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import { useRegistry } from "../plugins/registry";
import { useTranslation } from "react-i18next";
import { useFeatures } from "../core/context/applicationContext";

const RawCompartmentsTable = withLocalStorageConfig("storekeeper-compartments")(
  withFixedColumns(1)(withVerticalScroll()(EnhancedTable))
);

const getColumns = (registry, t, features) =>
  [
    {
      id: "compartment",
      title: t("Fach"),
      filter: {
        type: StringSearchFilter,
      },
    },
    {
      id: "led.range",
      title: t("LEDs"),
      content: (row) => {
        const { ledCount, ledOffset } = row;
        if (ledCount === 0 || ledCount == null || ledOffset == null) {
          return "";
        }
        return t("{{start}} bis {{end}} ({{count}} Stk.)", {
          start: ledOffset + 1,
          end: ledOffset + ledCount,
          count: ledCount,
        });
      },
    },
    {
      id: "led.color",
      title: t("LED-Farbe"),
      content: (row) => (
        <div
          style={{
            background: row.ledColor,
            width: 20,
            height: 20,
            borderRadius: 4,
            border: "1px solid rgba(0,0,0,0.54)",
          }}
        />
      ),
      sortable: false,
    },
    features.loadCarriers && {
      id: "content.loadCarrier",
      title: t("Aktueller Inhalt (Ladungsträger)"),
      content: (row) => row.content?.name ?? "",
      filter: {
        type: StringSearchFilter,
      },
    },
    {
      id: "content.stockItems",
      title: features.loadCarriers
        ? t("Aktueller Inhalt (ohne Ladungsträger)")
        : t("Aktueller Inhalt"),
      contentString: (row) =>
        row.stockItems
          ?.map((item) => item.sku ?? item.article.sku)
          .join(", ") ?? "",
      filter: {
        type: StringSearchFilter,
      },
    },
    {
      id: "rfidReader",
      title: t("RFID-Leser"),
      hideByDefault: true,
      content: (row) => row.rfidReader?.serial ?? "",
    },
    {
      id: "content.rfidTag",
      title: t("Aktueller Inhalt (RFID-Tag)"),
      content: (row) => row.rfidReader?.content?.rfidTag ?? "",
      filter: {
        type: StringSearchFilter,
      },
      hideByDefault: true,
    },
  ].filter((column) => !!column);

export default function CompartmentsTable(props) {
  const { t } = useTranslation();
  const registry = useRegistry();
  const features = useFeatures();
  const columns = useMemo(
    () => getColumns(registry, t, features),
    [registry, t, features]
  );
  return (
    <RawCompartmentsTable
      rowId={(row) => row.id}
      columns={columns}
      orderBy="rfidReader"
      {...props}
    />
  );
}
