import React from "react";
import { getDeep, setDeep } from "./deepAccess";

function convertBackValues(values, formConfig) {
  let result = values;
  for (const [field, config] of Object.entries(formConfig)) {
    if (config.convertBackInitial) {
      result = setDeep(
        result,
        field,
        config.convertBackInitial(getDeep(values, field), values)
      );
    }
  }
  return result;
}

export function getSubmitValues(values, formConfig) {
  let result = values;
  for (const [field, config] of Object.entries(formConfig)) {
    if (config.convertOnSubmit) {
      result = setDeep(
        result,
        field,
        config.convertOnSubmit(getDeep(values, field), values)
      );
    }
  }
  return result;
}

function checkError(errorChecker, value, allValues) {
  return errorChecker && errorChecker(value, allValues);
}

export default function useFormState(config, initialValue) {
  const [state, setState] = React.useState({
    config,
    initialValues: {},
    values: {},
    errors: {},
    canSubmit: false,
    reset: () => {
      setState(state => ({
        ...state,
        values: state.initialValues,
        errors: {}
      }));
    },
    setValues: changes => {
      setState(state => {
        let values = state.values;
        for (const [name, value] of Object.entries(changes)) {
          values = setDeep(values, name, value);
        }
        const errors = {};
        Object.entries(state.config).forEach(([name, { error }]) => {
          errors[name] = checkError(error, getDeep(values, name), values);
        });

        return {
          ...state,
          values,
          errors,
          canSubmit: Object.entries(state.config).every(
            ([name, { error }]) =>
              !checkError(error, getDeep(values, name), values)
          )
        };
      });
    }
  });

  React.useEffect(() => {
    if (config == null) {
      return;
    }
    let initialValues = {};
    for (const [name, value] of Object.entries(config)) {
      initialValues = setDeep(
        initialValues,
        name,
        getDeep(initialValue, name) ?? value.defaultValue
      );
    }
    initialValues = convertBackValues(initialValues, config);
    setState(state => ({
      ...state,
      config,
      initialValues,
      values: initialValues,
      canSubmit: Object.entries(config).every(
        ([name, { error }]) =>
          !checkError(error, getDeep(initialValues, name), initialValues)
      )
    }));
  }, [config, initialValue]);

  const handleSetValue = React.useCallback(
    (name, value) => {
      setState(state => {
        const values = setDeep(state.values, name, value);
        const errors = {};
        Object.entries(config).forEach(([name, { error }]) => {
          errors[name] = checkError(error, getDeep(values, name), values);
        });

        return {
          ...state,
          values,
          errors,
          canSubmit: Object.entries(config).every(
            ([name, { error }]) =>
              !checkError(error, getDeep(values, name), values)
          )
        };
      });
    },
    [config]
  );

  const setValue = name => e => {
    const value = config[name].convert
      ? config[name].convert(e.target.value, state.values)
      : e.target.value;
    handleSetValue(name, value);
  };

  return [
    state,
    name => ({
      value:
        (config[name] && config[name].convertBack
          ? config[name].convertBack(getDeep(state.values, name), state.values)
          : getDeep(state.values, name)) ?? null,
      error: !!state.errors[name],
      onChange: setValue(name),
      onBlur: () => {
        if (
          config[name] &&
          checkError(
            config[name].error,
            getDeep(state.values, name),
            state.values
          )
        ) {
          setState(state => ({
            ...state,
            errors: {
              ...state.errors,
              [name]: true
            }
          }));
        }
      },
      required: config[name].required,
    }),
  ];
}
