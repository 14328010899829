import React, { useMemo } from "react";
import EnhancedTable from "@wa/werkstoff-data-table";
import withLocalStorageConfig from "@wa/werkstoff-data-table/lib/withLocalStorageConfig";
import withVerticalScroll from "@wa/werkstoff-data-table/lib/withVerticalScroll";
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import { numberColumn } from "../../util/enhancedTableUtils";
import { useTranslation } from "react-i18next";

const RawTowersTable = withLocalStorageConfig("storekeeper-towers")(
  withVerticalScroll()(EnhancedTable)
);

const getColumns = (t) => [
  {
    id: "location",
    title: t("Bezeichnung"),
    filter: {
      type: StringSearchFilter,
    },
  },
  numberColumn({
    id: "compartments",
    title: t("Fächer"),
    getNumber: (row) => row.compartments?.length ?? 0,
  }),
  {
    id: "bus",
    title: t("RFID/LED-Modul"),
    content: (row) => row.rfidBus?.serial ?? "",
    filter: {
      type: StringSearchFilter,
    },
  },
];

export default function TowersTable(props) {
  const { t } = useTranslation();
  const columns = useMemo(() => getColumns(t), [t]);
  return (
    <RawTowersTable rowId={(row) => row.id} columns={columns} {...props} />
  );
}
