import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Button } from '@material-ui/core'
import cx from 'classnames'

const styles = (theme) => ({
  root: {
    color: theme.palette.text.secondary
  },
  active: {
    color: theme.palette.primary.main
  },
  sizeTiny: {
    paddingTop: 0,
    paddingBottom: 0,
  }
})

function ToggleButton (props) {
  const { classes, className, size = 'medium', value, ...other } = props

  return (
    <Button
      className={cx(classes.root, className, {
        [classes.active]: value === true,
        [classes.sizeTiny]: size === 'tiny'
      })}
      variant='text'
      size={size === 'tiny' ? 'small' : size}
      {...other}
    />
  )
}

export default withStyles(styles)(ToggleButton)

ToggleButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  value: PropTypes.bool,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large'])
}
