import React, { useState, useCallback } from "react";
import {
  withStyles,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Select,
  MenuItem,
  Divider,
} from "@material-ui/core";
import { getContrastRatio } from "@material-ui/core/styles/colorManipulator";
import TooltipIconButton from "../../components/TooltipIconButton";
import { LedOutline, LedOn } from "mdi-material-ui";
import { useMutation } from "react-apollo";
import { gql } from "graphql.macro";
import { useTranslation } from "react-i18next";
import useThrottledEffect from "../../util/useThrottledEffect";

const styles = (theme) => ({
  inputField: {
    margin: "4px 0 16px",
    width: "100%",
  },
  bottom: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  submit: {
    float: "right",
    marginLeft: 24,
  },
  colorpicker: {
    "& input": {
      textTransform: "uppercase",
    },
    "& input[type=color]": {
      opacity: 0,
      display: "block",
      width: 20,
      height: 20,
      border: "none",
      "&:not(:disabled)": {
        cursor: "pointer",
      },
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    minWidth: 250,
    alignItems: "center",
    "& $inputField": {
      width: 120,
      flex: 1,
      marginRight: 16,
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
});

const TOGGLE_LEDS = gql`
  mutation HighlightLeds(
    $busId: ID!
    $ledPort: Int
    $offset: Int!
    $count: Int!
    $color: HexColor!
    $secondaryBusId: ID
    $secondaryLedPort: Int
  ) {
    highlightLeds(
      busId: $busId
      ledPort: $ledPort
      offset: $offset
      count: $count
      color: $color
      secondaryBusId: $secondaryBusId
      secondaryLedPort: $secondaryLedPort
    )
  }
`;

function ChangeCompartmentForm({
  classes,
  compartment: initialCompartment,
  onSubmit,
  onRemove,
  rfidReaders,
  rfidLedSupportEnabled,
  tower,
}) {
  const { t } = useTranslation();

  const [compartment, setCompartment] = useState({
    ...initialCompartment,
    ledOffset:
      initialCompartment.ledOffset != null
        ? initialCompartment.ledOffset + 1
        : null,
  });

  const handleChangeCompartment = useCallback((e) => {
    const value = e.target.value;
    setCompartment((compartment) => ({
      ...compartment,
      compartment: value,
    }));
  }, []);

  const handleChangeLedColor = useCallback((e) => {
    const ledColor = `#${e.target.value.replace(/[^0-9A-Fa-f]/g, "")}`;
    setCompartment((compartment) => ({
      ...compartment,
      ledColor,
    }));
  }, []);

  const handleChangeLedOffset = useCallback((e) => {
    const ledOffset = e.target.value;
    setCompartment((compartment) => ({
      ...compartment,
      ledOffset,
    }));
  }, []);

  const handleChangeLedCount = useCallback((e) => {
    const ledCount = e.target.value;
    setCompartment((compartment) => ({
      ...compartment,
      ledCount,
    }));
  }, []);

  const handleChangeRfidReader = useCallback(
    (e) => {
      const rfidReaderId = e.target.value === "" ? null : e.target.value;
      setCompartment((compartment) => ({
        ...compartment,
        rfidReader:
          rfidReaderId == null
            ? null
            : rfidReaders.find((reader) => reader.id === rfidReaderId),
      }));
    },
    [rfidReaders]
  );

  const isNew = compartment.id == null;

  const [toggleLedsMutation] = useMutation(TOGGLE_LEDS);

  const [ledOn, setLedOn] = useState(null);
  useThrottledEffect(
    () => {
      if (ledOn) {
        const variables = {
          busId: tower.rfidBus.id,
          secondaryBusId: tower.secondaryRfidBus?.id,
          ledPort: tower.ledPort,
          secondaryLedPort: tower.secondaryLedPort,
          color: compartment.ledColor ?? "#ffffff",
          offset: Math.max(0, (parseInt(compartment.ledOffset, 10) || 1) - 1),
          count: Math.max(0, parseInt(compartment.ledCount, 10) || 0),
        };
        if (variables.count <= 0) {
          setLedOn(null);
          return;
        }
        toggleLedsMutation({
          variables,
        }).catch((e) => {
          console.error(e);
          setLedOn(null);
        });
        return () => {
          toggleLedsMutation({
            variables: { ...variables, color: "#000000" },
          }).catch((e) => {
            console.error(e);
          });
        };
      }
    },
    1000,
    [ledOn, compartment.ledOffset, compartment.ledCount, compartment.ledColor]
  );

  const handleToggleLed = useCallback(() => {
    setLedOn((ledOn) => !ledOn);
  }, []);

  return (
    <>
      <Typography variant="body1">
        {isNew ? t("Fach hinzufügen") : t("Fach bearbeiten")}
      </Typography>
      <div className={classes.row}>
        <TextField
          label={t("Bezeichnung")}
          value={compartment.compartment}
          onChange={handleChangeCompartment}
          className={classes.inputField}
          autoFocus={isNew}
        />
        <FormControl
          className={classes.inputField}
          disabled={!rfidLedSupportEnabled || rfidReaders == null}
        >
          <InputLabel>{t("RFID-Leser")}</InputLabel>
          <Select
            value={compartment.rfidReader?.id ?? ""}
            onChange={handleChangeRfidReader}
          >
            <MenuItem value="">
              {t("Keiner", { context: "rfidReaderSelection" })}
            </MenuItem>
            <Divider />
            {rfidReaders?.map(({ id, serial }) => (
              <MenuItem key={id} value={id}>
                {serial}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.row}>
        <FormControl
          className={classes.inputField}
          disabled={!rfidLedSupportEnabled}
        >
          <InputLabel htmlFor="led-color">{t("LED-Farbe")}</InputLabel>
          <Input
            id="led-color"
            className={classes.colorpicker}
            value={`${compartment.ledColor ?? "#ffffff"}`}
            onChange={handleChangeLedColor}
            error={!/#[A-Fa-f0-9]{6}/.test(compartment.ledColor ?? "#ffffff")}
            endAdornment={
              <InputAdornment position="end">
                <div
                  style={{
                    background: `${compartment.ledColor ?? "#ffffff"}`,
                    width: 20,
                    height: 20,
                    borderRadius: 4,
                    border: "1px solid rgba(0,0,0,0.54)",
                    opacity: rfidLedSupportEnabled ? 1 : 0.3,
                  }}
                >
                  <input
                    type="color"
                    value={`${compartment.ledColor ?? "#ffffff"}`}
                    onChange={handleChangeLedColor}
                    tabIndex={-1}
                    disabled={!rfidLedSupportEnabled}
                  />
                </div>
              </InputAdornment>
            }
          />
        </FormControl>
        <TextField
          label={t("Erste LED")}
          type="number"
          inputProps={{ min: 1 }}
          value={compartment.ledOffset ?? ""}
          onChange={handleChangeLedOffset}
          className={classes.inputField}
          disabled={!rfidLedSupportEnabled}
        />
        <TextField
          label={t("Anzahl LEDs")}
          type="number"
          inputProps={{ min: 0 }}
          value={compartment.ledCount ?? ""}
          onChange={handleChangeLedCount}
          className={classes.inputField}
          disabled={!rfidLedSupportEnabled}
        />
        <TooltipIconButton
          tooltip={ledOn ? t("LEDs ausschalten") : t("LEDs einschalten")}
          onClick={handleToggleLed}
          disabled={
            !rfidLedSupportEnabled ||
            tower.rfidBus == null ||
            !/^#[A-Fa-f0-9]{6}$/.test(compartment.ledColor ?? "#ffffff") ||
            (parseInt(compartment.ledCount, 10) || 0) < 1
          }
          closeTooltipOnClick={false}
        >
          {ledOn ? (
            <LedOn
              style={{
                color:
                  getContrastRatio(compartment.ledColor ?? "#ffffff", "#fff") >=
                  3
                    ? compartment.ledColor ?? "#ffffff"
                    : undefined,
              }}
            />
          ) : (
            <LedOutline />
          )}
        </TooltipIconButton>
      </div>
      <div className={classes.bottom}>
        <Button
          className={classes.submit}
          variant="outlined"
          color="primary"
          onClick={() =>
            onSubmit({
              ...compartment,
              ledColor: /^#[A-Fa-f0-9]{6}$/.test(compartment.ledColor)
                ? compartment.ledColor
                : initialCompartment.ledColor ?? "#ffffff",
              ledOffset: Math.max(
                0,
                (parseInt(compartment.ledOffset, 10) || 1) - 1
              ),
              ledCount: Math.max(0, parseInt(compartment.ledCount, 10) || 0),
            })
          }
        >
          {isNew ? t("Hinzufügen") : t("Übernehmen")}
        </Button>
        {!isNew && (
          <Button
            variant="outlined"
            onClick={() => onRemove(initialCompartment)}
          >
            {t("Entfernen")}
          </Button>
        )}
      </div>
    </>
  );
}

export default withStyles(styles)(ChangeCompartmentForm);
