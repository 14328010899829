// @ts-ignore
import NumberRangeFilter from "@wa/werkstoff-data-table/lib/FilterPopover/NumberRangeFilter";
import { formatWithUnit } from "./numberFormat";

export function numberColumn({
  getNumber,
  range,
  unit,
  filter,
  fractionalDigits,
  ...other
}: {
  getNumber: (row: object) => number;
  range?: { min?: number | null; max?: number | null };
  unit?: { name: string; position: "start" | "end" };
  filter?: object;
  fractionalDigits?: number;
  title?: string;
  id: string;
}) {
  const content = (row: object) => {
    const number = getNumber(row);
    if (number == null || Number.isNaN(number)) {
      return "";
    }
    return formatWithUnit(number, unit?.name, fractionalDigits);
  };

  return {
    content,
    contentString: content,
    tooltip: content,
    compare(a: object, b: object) {
      return getNumber(a) - getNumber(b);
    },
    filter: {
      type: NumberRangeFilter,
      range,
      unit,
      getNumber,
      ...filter
    },
    alignment: "right",
    export: {
      // only used by the CSV export, not by the table
      getRawValue: getNumber,
      title: unit ? `${other.title} (${unit.name})` : other.title
    },
    ...other
  };
}
