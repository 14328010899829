import { createMuiTheme } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";
import amber from "@material-ui/core/colors/amber";

export default createMuiTheme({
  palette: {
    primary: {
      main: indigo[500],
    },
    secondary: {
      main: amber[500],
    },
  },
  typography: {
    useNextVariants: true,
  },
  props: {
    MuiMenuItem: {
      dense: true, // still okay for fingers but better for desktop
    },
  },
});
