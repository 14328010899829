import React from "react";
import PropTypes from "prop-types";
import {
  Popover,
  withStyles,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { Pencil } from "mdi-material-ui";
import { useTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    minWidth: "100%",
    maxWidth: 170,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflowX: "hidden",
    height: 18,
    display: "inline-block",
    verticalAlign: "bottom",
    "&:hover": {
      background: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: `0 0 0 4px ${theme.palette.background.paper}`,
      "& $icon": {
        display: "inline",
      },
    },
  },
  icon: {
    display: "none",
    fontSize: "18px",
    color: theme.palette.text.secondary,
    position: "relative",
    float: "right",
    zIndex: 1,
    background: theme.palette.background.paper,
    paddingLeft: 4,
    width: 22,
    marginLeft: -22,
  },
  noNote: {
    color: theme.palette.text.disabled,
  },
  popover: {
    padding: 8,
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    width: 350,
  },
  saveButton: {
    alignSelf: "flex-end",
  },
  row: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "flex-end",
    justifyContent: "space-between",
    minWidth: 250,
  },
});

function NoteButton({ classes, id, note, onSaveNote }) {
  const { t } = useTranslation();
  const [noteInput, setNoteInput] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState();
  const [noteDate, setNoteDate] = React.useState();

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.target);
    setNoteInput((note && note.text) || "");
    setNoteDate((note && new Date(note.lastUpdated)) || null);
  };

  const handleClickPopup = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClose = () => setAnchorEl(null);

  const handleNoteInputChange = (e) => {
    setNoteInput(e.target.value);
    setNoteDate(new Date());
  };

  const handleSaveNote = (e) => {
    onSaveNote(e, { id, note: noteInput });
    handleClose();
  };

  const handleClearNote = (e) => {
    onSaveNote(e, { id, note: "" });
    handleClose();
  };

  return (
    <React.Fragment>
      <span className={classes.root} onClick={handleClick}>
        <Pencil className={classes.icon} />
        {note?.text}
      </span>
      <Popover
        open={anchorEl != null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: classes.popover }}
        onClose={handleClose}
        onClick={handleClickPopup}
      >
        <TextField
          multiline
          rows={4}
          variant="outlined"
          placeholder={t("Notiz hinzufügen…")}
          value={noteInput}
          onChange={handleNoteInputChange}
          autoFocus
          className={classes.textField}
        />
        <Typography variant="caption" align="right">
          {noteDate
            ? noteDate.toLocaleString([], {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })
            : "\u00A0"}
        </Typography>
        <div className={classes.row}>
          <Button
            variant="text"
            color="primary"
            className={classes.saveButton}
            onClick={handleSaveNote}
          >
            {t("Speichern")}
          </Button>
          {note?.text && (
            <Button
              variant="text"
              className={classes.clearButton}
              onClick={handleClearNote}
            >
              {t("Entfernen")}
            </Button>
          )}
        </div>
      </Popover>
    </React.Fragment>
  );
}

NoteButton.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  note: PropTypes.shape({
    text: PropTypes.string,
    lastUpdated: PropTypes.string.isRequired,
  }),
  onSaveNote: PropTypes.func.isRequired,
};

export default withStyles(styles)(NoteButton);
