import React from "react";
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import { numberColumn } from "../../../util/enhancedTableUtils";
import {
  aggregatedRowSum,
  ifStockItem,
} from "../../stock/components/stockTable";
import { compareNaturalBy } from "../../../util/compareNatural";

function getStock(row) {
  return row.initial + row.in - row.out;
}

function getWeightByCount(row, count) {
  return row.details.length * row.article.details.specificWeight * count;
}

export default ({ t }) => [
  {
    id: "name",
    title: t("Artikel", { count: 1 }),
    contentString: (row) => row.article.name,
    compare: compareNaturalBy((row) => row.article.name),
    filter: {
      type: StringSearchFilter,
    },
  },
  {
    id: "sku",
    title: t("Artikelnr."),
    contentString: (row) => row.sku ?? row.article.sku,
    compare: compareNaturalBy((row) => row.sku ?? row.article.sku),
    filter: {
      type: StringSearchFilter,
    },
  },
  numberColumn({
    id: "current",
    title: t("Realer Bestand"),
    getNumber: aggregatedRowSum(getStock),
    content: ifStockItem(
      (row) => (
        <b>
          {getStock(row).toLocaleString()} {t("Stk.")}
        </b>
      ),
      (aggregatedRow) => (
        <b>
          {aggregatedRow.stockItems
            .reduce((sum, stockItem) => getStock(stockItem) + sum, 0)
            .toLocaleString()}{" "}
          {t("Stk.")}
        </b>
      )
    ),
    unit: { position: "end", name: t("Stk.") },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "length",
    title: t("Länge"),
    getNumber: (row) => row.details.length * 1000,
    unit: { position: "end", name: "mm" },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "currentWeight",
    title: t("Gesamtgewicht"),
    getNumber: aggregatedRowSum((row) => getWeightByCount(row, getStock(row))),
    unit: { position: "end", name: "kg" },
    range: { min: 0, max: null },
  }),
  {
    id: "article.material",
    title: t("Werkstoff"),
    content: (row) => row.article.details.material,
    contentString: (row) => row.article.details.material,
    filter: {
      type: StringSearchFilter,
    },
  },
];
