import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import { compareNaturalBy } from "../../../util/compareNatural";
import { numberColumn } from "../../../util/enhancedTableUtils";

export default ({ t }) => [
  {
    id: "name",
    title: t("Artikel", { count: 1 }),
    filter: {
      type: StringSearchFilter,
    },
    compare: compareNaturalBy((row) => row.name),
  },
  {
    id: "sku",
    title: t("Artikelnr."),
    filter: {
      type: StringSearchFilter,
    },
    compare: compareNaturalBy((row) => row.sku),
  },
  {
    id: "details.material",
    title: t("Werkstoff"),
    content: (row) => row.details.material,
    contentString: (row) => row.details.material,
    filter: {
      type: StringSearchFilter,
    },
  },
  numberColumn({
    id: "details.length",
    title: t("Länge"),
    getNumber: (row) => row.details.length,
    unit: {
      position: "end",
      name: "mm",
    },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "details.width",
    title: t("Breite"),
    getNumber: (row) => row.details.width,
    unit: {
      position: "end",
      name: "mm",
    },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "details.thickness",
    title: t("Dicke"),
    getNumber: (row) => row.details.thickness,
    fractionalDigits: 2,
    unit: {
      position: "end",
      name: "mm",
    },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "details.area",
    title: t("Fläche"),
    getNumber: (row) => row.details.width * row.details.length * 1e-6,
    unit: {
      position: "end",
      name: "m²",
    },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "details.specificWeight",
    title: t("Gewicht"),
    getNumber: (row) => row.details.specificWeight,
    unit: {
      position: "end",
      name: "kg/m²",
    },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "minimumStock",
    title: t("Meldebestand"),
    getNumber: (row) => row.minimumStock,
    range: { min: 0, max: null },
  }),
];
