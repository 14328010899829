import React, { useCallback, useMemo } from "react";
import { Calculator } from "mdi-material-ui";
import PopoverIconButton, {
  PopoverIconButtonProps,
} from "../../../components/PopoverIconButton";
import UnitTextField from "../../../components/UnitTextField";
import { useTranslation } from "react-i18next";
import {
  Button,
  FilledInput,
  FormControl,
  InputAdornment,
  InputLabel,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import useInputHandlers from "../../../util/useInputHandlers";
import { formatWithUnit } from "../../../util/numberFormat";
// @ts-ignore
import { SearchableSelect } from "@wa/werkstoff-select";

export const specificWeights = [
  {
    name: "Stahl",
    specificWeight: 7.85, // kg/dm³ (= g/cm³)
  },
  {
    name: "Edelstahl",
    specificWeight: 7.85,
  },
  {
    name: "Aluminium",
    specificWeight: 2.71,
  },
];

export function getSpecificWeight(name: string): number | undefined {
  return specificWeights.find(
    (w) => w.name.toLowerCase() === name.toLowerCase()
  )?.specificWeight;
}

export function calculateWeight({
  innerDiameter,
  outerDiameter,
  specificWeight,
  width,
}: {
  innerDiameter: number;
  outerDiameter: number;
  specificWeight: number;
  width: number;
}) {
  return (
    ((Math.pow(outerDiameter, 2) - Math.pow(innerDiameter, 2)) / 4) *
    Math.PI *
    specificWeight *
    1e-6 *
    width
  );
}

export function calculateLength({
  weight,
  width,
  thickness,
  specificWeight,
}: {
  weight: number;
  width: number;
  thickness: number;
  specificWeight: number;
}) {
  return weight / (specificWeight * 1e-6 * width * thickness);
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 2 * theme.spacing.unit,
      width: 250,
    },
    row: {},
    results: {
      marginTop: theme.spacing.unit,
    },
    buttons: {
      marginTop: theme.spacing.unit,
      display: "flex",
      flexDirection: "row-reverse",
    },
  });

function CoilCalculatorButton({
  ButtonProps,
  classes,
  defaultWidth,
  defaultSpecificWeight,
  defaultThickness,
  onSubmit,
}: {
  ButtonProps?: Partial<PopoverIconButtonProps>;
  defaultWidth?: number | string;
  defaultSpecificWeight?: number | string;
  defaultThickness?: number | string;
  onSubmit: (result: {
    weight: number;
    length: number;
    width: number;
    thickness: number;
  }) => void;
} & WithStyles<typeof styles>) {
  const { t } = useTranslation();
  const [values, handleChange, setValues] = useInputHandlers({
    innerDiameter: {},
    outerDiameter: {},
    width: { defaultValue: defaultWidth },
    specificWeight: { defaultValue: defaultSpecificWeight },
    thickness: { defaultValue: defaultThickness },
  });

  const handleOpen = useCallback(() => {
    setValues({
      width: defaultWidth ?? "",
      specificWeight: defaultSpecificWeight ?? "",
      thickness: defaultThickness ?? "",
    });
  }, [setValues, defaultWidth, defaultSpecificWeight, defaultThickness]);

  const results = useMemo(() => {
    const parsedValues = Object.fromEntries(
      Object.entries(values).map(([k, v]) => [k, parseFloat(v)])
    ) as {
      innerDiameter: number;
      outerDiameter: number;
      width: number;
      specificWeight: number;
      thickness: number;
    };
    if (Object.values(parsedValues).some((v) => isNaN(v))) {
      return {
        weight: null,
        length: null,
      };
    }
    const weight = calculateWeight(parsedValues);
    return {
      weight,
      length: calculateLength({ ...parsedValues, weight }),
      width: parseFloat(values.width),
      thickness: parseFloat(values.thickness),
    };
  }, [values]);

  const handleSubmit = useCallback(() => {
    if (results.length != null && results.weight != null) {
      onSubmit(results);
    }
  }, [onSubmit, results]);

  return (
    <PopoverIconButton
      icon={Calculator}
      IconProps={undefined}
      closeOnClick={false}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PopoverProps={undefined}
      onOpen={handleOpen}
      tooltip={t("Coil-Rechner anzeigen")}
      {...ButtonProps}
    >
      {({ closePopover }: { closePopover: () => void }) => (
        <div className={classes.root}>
          <Typography variant="body1">{t("Coil-Rechner")}</Typography>
          <div className={classes.row}>
            <UnitTextField
              label={t("Innendurchmesser")}
              margin="dense"
              variant="filled"
              fullWidth
              unit="mm"
              type="number"
              value={values.innerDiameter}
              onChange={handleChange}
              InputProps={{ inputProps: { "data-field": "innerDiameter" } }}
              autoFocus
            />
            <UnitTextField
              label={t("Außendurchmesser")}
              margin="dense"
              variant="filled"
              fullWidth
              unit="mm"
              type="number"
              value={values.outerDiameter}
              onChange={handleChange}
              InputProps={{ inputProps: { "data-field": "outerDiameter" } }}
            />
            <UnitTextField
              label={t("Breite")}
              margin="dense"
              variant="filled"
              fullWidth
              unit="mm"
              type="number"
              value={values.width}
              onChange={handleChange}
              InputProps={{ inputProps: { "data-field": "width" } }}
            />
            <FormControl margin="dense" variant="filled" fullWidth>
              <InputLabel>{t("Dichte")}</InputLabel>
              <SearchableSelect
                value={values.specificWeight?.toLocaleString() ?? ""}
                input={
                  <FilledInput
                    endAdornment={
                      <InputAdornment position="end">
                        <Typography variant="body1">kg/dm³</Typography>
                      </InputAdornment>
                    }
                  />
                }
                items={specificWeights.map((weight) => ({
                  label: `${weight.name} ${formatWithUnit(
                    weight.specificWeight,
                    "kg/dm³"
                  )}`,
                  value: weight.specificWeight,
                }))}
                freeSolo
                searchTextFieldProps={{
                  placeholder: t("Andere Dichte eingeben…"),
                }}
                addItemLabel={(value: string) =>
                  formatWithUnit(parseFloat(value.replace(",", ".")), "kg/dm³")
                }
                onChange={(value: string) => {
                  setValues({
                    specificWeight: parseFloat(value.replace(",", ".")),
                  });
                }}
                margin="dense"
                variant="filled"
              />
            </FormControl>
            <UnitTextField
              label={t("Dicke")}
              margin="dense"
              variant="filled"
              fullWidth
              unit="mm"
              type="number"
              value={values.thickness}
              onChange={handleChange}
              InputProps={{ inputProps: { "data-field": "thickness" } }}
            />
          </div>
          <div className={classes.results}>
            Gewicht:{" "}
            {(results.weight ?? 0) >= 0
              ? formatWithUnit(results.weight ?? 0, "kg")
              : "–"}
            <br />
            Länge:{" "}
            {(results.length ?? 0) >= 0
              ? formatWithUnit((results.length ?? 0) / 1000, "m")
              : "–"}
          </div>
          <div className={classes.buttons}>
            <Button
              color="primary"
              onClick={() => {
                handleSubmit();
                closePopover();
              }}
              disabled={
                results.length == null ||
                results.length <= 0 ||
                results.weight == null ||
                results.weight <= 0
              }
            >
              {t("Übernehmen")}
            </Button>
          </div>
        </div>
      )}
    </PopoverIconButton>
  );
}

export default withStyles(styles)(CoilCalculatorButton);
