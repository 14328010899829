import React, { useMemo } from "react";
import { FilledInput, FormControl, Input, InputLabel } from "@material-ui/core";
import { gql } from "graphql.macro";
import { useQuery } from "react-apollo";
import { SearchableSelect } from "@wa/werkstoff-select";
import ComponentExtensionPoint from "../../plugins/ComponentExtensionPoint";
import DefaultCompartmentSelector from "./DefaultCompartmentSelector";
import { useTranslation } from "react-i18next";
import { useFeatures } from "../../core/context/applicationContext";

const GET_LOAD_CARRIERS = gql`
  {
    loadCarriers {
      id
      name
      compartment {
        id
      }
    }
  }
`;

export default function DefaultArticleLocation({
  value,
  onChange,
  className,
  margin = "dense",
  variant = "filled",
  InputLabelProps,
}) {
  const { t } = useTranslation();

  const { data: loadCarriers } = useQuery(GET_LOAD_CARRIERS, {
    fetchPolicy: "cache-and-network",
  });

  const loadCarrier =
    value?.loadCarrier != null
      ? loadCarriers?.loadCarriers?.find((lc) => lc.id === value.loadCarrier)
      : null;
  const compartmentId = value?.compartment ?? loadCarrier?.compartment?.id;

  const loadCarrierItems = useMemo(
    () =>
      loadCarriers?.loadCarriers.map(({ id, name }) => ({
        value: id,
        label: name,
      })) ?? [],
    [loadCarriers]
  );

  const features = useFeatures();

  return (
    <>
      {features.loadCarriers && (
        <FormControl variant={variant} margin={margin} className={className}>
          <InputLabel shrink {...InputLabelProps}>
            {t("Ladungsträger")}
          </InputLabel>
          <SearchableSelect
            value={value?.loadCarrier ?? null}
            onChange={(loadCarrierId) =>
              onChange({
                loadCarrier: loadCarrierId || null,
                compartment: loadCarrierId ? null : value?.compartment,
              })
            }
            input={variant === "filled" ? <FilledInput /> : <Input />}
            items={loadCarrierItems}
            noSelectionLabel={<em>{t("Ohne Ladungsträger")}</em>}
            searchTextFieldProps={{ placeholder: t("Suchen…") }}
          />
        </FormControl>
      )}
      <ComponentExtensionPoint
        name="compartmentSelector"
        variant="single"
        innerProps={{
          className: className,
          value: compartmentId,
          variant,
          margin,
          onChange: (compartment) => {
            onChange({
              loadCarrier: compartment != null ? null : value?.loadCarrier,
              compartment,
            });
          },
          disabled: loadCarrier != null,
        }}
        DefaultComponent={DefaultCompartmentSelector}
      />
    </>
  );
}
