import React, { useState, useEffect, useCallback } from "react";
import {
  withStyles,
  Paper,
  TextField,
  IconButton,
  Popover,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Divider,
} from "@material-ui/core";
import { useQuery, useMutation } from "react-apollo";
import { gql } from "graphql.macro";
import LeftArrow from "mdi-material-ui/ArrowLeft";
import { useSnackbar } from "material-ui-snackbar-provider";
import CompartmentsTable from "./CompartmentsTable";
import ChangeCompartmentForm from "./ChangeCompartmentForm";
import PopoverIconButton from "../../components/PopoverIconButton";
import { DotsVertical } from "mdi-material-ui";
import { GET_TOWERS } from "./Towers";
import { useRegistry } from "../plugins/registry";
import TablePlaceholder from "../../components/TablePlaceholder";
import { Trans, useTranslation } from "react-i18next";

const towerFragment = gql`
  fragment TowerData on Tower {
    id
    rfidBus {
      id
      serial
    }
    ledPort
    secondaryRfidBus {
      id
      serial
    }
    secondaryLedPort
    location
    compartments {
      id
      rfidReader {
        id
        serial
        content {
          id
          rfidTag
        }
      }
      compartment
      content {
        id
        name
      }
      stockItems {
        id
        sku
        type
        article {
          id
          name
          sku
        }
      }
      ledOffset
      ledCount
      ledColor
    }
  }
`;

const GET_TOWER = gql`
  query GetTower($id: ID!) {
    tower(id: $id) {
      ...TowerData
    }
  }
  ${towerFragment}
`;

const UPDATE_TOWER = gql`
  mutation UpdateTower($id: ID!, $tower: UpdateTowerInput!) {
    updateTower(id: $id, tower: $tower) {
      ...TowerData
    }
  }
  ${towerFragment}
`;

const REMOVE_TOWER = gql`
  mutation RemoveTower($id: ID!) {
    removeTower(id: $id) {
      id
      location
    }
  }
`;

const GET_RFID_BUSES = gql`
  {
    rfidBuses {
      id
      serial
      readers {
        id
        serial
        content {
          id
          rfidTag
        }
      }
    }
  }
`;

const styles = {
  header: {
    marginBottom: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tower: {
    padding: 16,
  },
  back: {
    padding: 6,
    marginLeft: -8,
    alignSelf: "center",
  },
  moreButton: {
    margin: "-6px 0",
  },
  rfid: {
    marginLeft: 16,
    width: 200,
  },
  channel: {
    marginLeft: 8,
    width: 85,
  },
  compartments: {
    marginTop: 16,
    flex: 1,
  },
  deltaPopover: {
    padding: 16,
    marginTop: -8,
    marginLeft: 24,
    minWidth: 350,
  },
};

function Tower({ classes, id, onClose }) {
  const { t } = useTranslation();
  const registry = useRegistry();
  const rfidSupportEnabled = registry.pages.some((p) =>
    p.route.includes("rfid")
  );

  const snackbar = useSnackbar();
  const { data, loading, error } = useQuery(GET_TOWER, {
    fetchPolicy: "network-only",
    variables: { id },
  });
  const [tower, setTower] = useState(data?.tower);

  const { data: rfidBuses } = useQuery(GET_RFID_BUSES, {
    fetchPolicy: "cache-and-network",
    skip: !rfidSupportEnabled,
  });

  useEffect(() => {
    setTower(data?.tower);
  }, [data]);

  const handleChangeTowerLocation = useCallback((e) => {
    const location = e.target.value;
    setTower((tower) => ({ ...tower, location }));
  }, []);

  const handleChangeRfidBus = useCallback(
    (e) => {
      const rfidBusId = e.target.value === "" ? null : e.target.value;
      const rfidBus =
        rfidBusId == null
          ? null
          : rfidBuses?.rfidBuses.find((bus) => bus.id === rfidBusId);
      setTower((tower) => ({
        ...tower,
        rfidBus,
        compartments:
          rfidBusId !== tower.rfidBus?.id
            ? tower.compartments.map((c) => ({
                ...c,
                rfidReaderId: null,
                rfidReader: null,
              }))
            : tower.compartments,
      }));
    },
    [rfidBuses]
  );

  const handleChangeSecondaryRfidBus = useCallback(
    (e) => {
      const secondaryRfidBusId = e.target.value === "" ? null : e.target.value;
      const secondaryRfidBus = rfidBuses?.rfidBuses.find(
        (bus) => bus.id === secondaryRfidBusId
      );
      setTower((tower) => ({
        ...tower,
        secondaryRfidBus,
      }));
    },
    [rfidBuses]
  );

  const handleChangeLedPort = useCallback((e) => {
    setTower((tower) => ({
      ...tower,
      ledPort: parseInt(e.target.value, 10) || 0,
    }));
  }, []);

  const handleChangeSecondaryLedPort = useCallback((e) => {
    setTower((tower) => ({
      ...tower,
      secondaryLedPort: parseInt(e.target.value, 10) || 0,
    }));
  }, []);

  const [selectedCompartment, setSelectedCompartment] = useState();
  const [updateCompartmentDialogOpen, setUpdateCompartmentDialogOpen] =
    useState(false);

  const [anchorEl, setAnchorEl] = useState();
  const handleRowClick = useCallback(
    (e, rowId) => {
      setAnchorEl(e.currentTarget.getElementsByTagName("TD")[0]);
      setSelectedCompartment(tower.compartments.find((c) => c.id === rowId));
      setUpdateCompartmentDialogOpen(true);
    },
    [tower]
  );

  const handleAddCompartment = useCallback((e) => {
    setAnchorEl(e.currentTarget);
    setSelectedCompartment({ id: null });
    setUpdateCompartmentDialogOpen(true);
  }, []);

  const handleEditCompartment = useCallback((compartment) => {
    if (compartment.id != null) {
      setTower((tower) => ({
        ...tower,
        compartments: tower.compartments.map((c) =>
          c.id === compartment.id ? compartment : c
        ),
      }));
    } else {
      setTower((tower) => ({
        ...tower,
        compartments: [
          ...tower.compartments,
          { ...compartment, id: `new-${Date.now()}` },
        ],
      }));
    }
    setUpdateCompartmentDialogOpen(false);
  }, []);

  const handleRemoveCompartment = useCallback(({ id }) => {
    setUpdateCompartmentDialogOpen(false);
    setSelectedCompartment(null);
    setTower((tower) => ({
      ...tower,
      compartments: tower.compartments.filter((c) => c.id !== id),
    }));
  }, []);

  const [updateTowerMutation] = useMutation(UPDATE_TOWER, {
    refetchQueries: () => [{ query: GET_TOWERS }],
  });
  const handleSave = useCallback(async () => {
    try {
      await updateTowerMutation({
        variables: {
          id: tower.id,
          tower: {
            location: tower.location,
            compartments: tower.compartments.map(
              ({
                id,
                compartment,
                ledColor,
                ledCount,
                ledOffset,
                rfidReader,
              }) => ({
                id: id.startsWith("new-") ? undefined : id,
                compartment,
                ledColor,
                ledCount,
                ledOffset,
                rfidReaderId: rfidSupportEnabled
                  ? rfidReader?.id ?? null
                  : undefined,
              })
            ),
            rfidBusId: rfidSupportEnabled
              ? tower.rfidBus?.id ?? null
              : undefined,
            secondaryRfidBusId: rfidSupportEnabled
              ? tower.secondaryRfidBus?.id ?? null
              : undefined,
            ledPort: rfidSupportEnabled ? tower.ledPort ?? 0 : undefined,
            secondaryLedPort: rfidSupportEnabled
              ? tower.secondaryLedPort ?? 0
              : undefined,
          },
        },
      });
      snackbar.showMessage(t("Die Änderungen wurden gespeichert"));
      onClose();
    } catch (e) {
      console.error(e);
      snackbar.showMessage(
        t("Die Änderungen konnten nicht gespeichert werden"),
        t("Erneut versuchen"),
        () => handleSave()
      );
    }
  }, [tower, onClose, snackbar, updateTowerMutation, rfidSupportEnabled, t]);

  const [removeTowerMutation] = useMutation(REMOVE_TOWER, {
    update: (cache, { data: { removeTower } }) => {
      const { towers } = cache.readQuery({ query: GET_TOWERS });
      cache.writeQuery({
        query: GET_TOWERS,
        data: { towers: towers.filter((t) => t.id !== removeTower.id) },
      });
    },
  });
  const handleDeleteTower = useCallback(async () => {
    try {
      const { data } = await removeTowerMutation({ variables: { id } });
      snackbar.showMessage(
        t('Der Turm "{{tower}}" wurde gelöscht', {
          tower: data.removeTower.location,
        })
      );
      onClose();
    } catch (e) {
      console.error(e);
      snackbar.showMessage(t("Der Turm konnte nicht gelöscht werden"));
    }
  }, [snackbar, id, onClose, removeTowerMutation, t]);

  return (
    <>
      <div className={classes.header}>
        <IconButton onClick={onClose} className={classes.back}>
          <LeftArrow />
        </IconButton>
        <Button variant="outlined" onClick={handleAddCompartment}>
          {t("Fach hinzufügen")}
        </Button>
        <div>
          <Button variant="outlined" color="primary" onClick={handleSave}>
            {t("Speichern")}
          </Button>
          <PopoverIconButton
            className={classes.moreButton}
            icon={DotsVertical}
            closeOnClick
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            tooltip={t("Weitere Optionen")}
          >
            <MenuItem onClick={handleDeleteTower}>{t("Turm löschen")}</MenuItem>
          </PopoverIconButton>
        </div>
      </div>
      <Paper className={classes.tower}>
        <TextField
          label={t("Bezeichnung des Turmes")}
          value={tower?.location ?? ""}
          onChange={handleChangeTowerLocation}
          margin="dense"
        />
        <FormControl className={classes.rfid} margin="dense">
          <InputLabel>{t("RFID/LED-Modul")}</InputLabel>
          <Select
            value={tower?.rfidBus?.id ?? ""}
            onChange={handleChangeRfidBus}
            disabled={!rfidSupportEnabled}
          >
            <MenuItem value="">{t("Kein RFID/LED-Modul")}</MenuItem>
            <Divider />
            {rfidBuses?.rfidBuses.map(({ id, serial }) => (
              <MenuItem key={id} value={id}>
                {serial}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.channel} margin="dense">
          <InputLabel>{t("LED-Port")}</InputLabel>
          <Select
            value={tower?.rfidBus?.id ? tower?.ledPort ?? "0" : ""}
            onChange={handleChangeLedPort}
            disabled={!rfidSupportEnabled || tower?.rfidBus?.id == null}
          >
            <MenuItem value="0">1</MenuItem>
            <MenuItem value="1">2</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.rfid} margin="dense">
          <InputLabel>{t("Zweites LED-Modul")}</InputLabel>
          <Select
            value={tower?.secondaryRfidBus?.id ?? ""}
            onChange={handleChangeSecondaryRfidBus}
            disabled={!rfidSupportEnabled}
          >
            <MenuItem value="">{t("Kein zweites LED-Modul")}</MenuItem>
            <Divider />
            {rfidBuses?.rfidBuses.map(({ id, serial }) => (
              <MenuItem key={id} value={id}>
                {serial}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.channel} margin="dense">
          <InputLabel>{t("LED-Port")}</InputLabel>
          <Select
            value={
              tower?.secondaryRfidBus?.id ? tower?.secondaryLedPort ?? "0" : ""
            }
            onChange={handleChangeSecondaryLedPort}
            disabled={
              !rfidSupportEnabled || tower?.secondaryRfidBus?.id == null
            }
          >
            <MenuItem value="0">1</MenuItem>
            <MenuItem value="1">2</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      <CompartmentsTable
        className={classes.compartments}
        data={loading ? null : tower?.compartments ?? []}
        size="small"
        EnhancedTableHeadProps={{ size: "medium" }}
        highlightedRows={
          updateCompartmentDialogOpen ? [selectedCompartment.id] : undefined
        }
        onRowClick={handleRowClick}
        placeholder={
          <TablePlaceholder>
            {error ? (
              <>
                {t("Beim Laden des Turms ist ein Fehler aufgetreten.")}
                <br />
                <br />
                {error.graphQLErrors?.length === 0 &&
                  t("Bitte überprüfen Sie Ihre Internetverbindung.")}
              </>
            ) : (
              <>
                {t("Für diesen Turm wurden noch keine Fächer konfiguriert.")}
                <br />
                <br />
                <Trans>
                  Klicken Sie auf <em>Fach hinzufügen</em>, um ein Fach zu
                  diesem Turm hinzuzufügen.
                </Trans>
              </>
            )}
          </TablePlaceholder>
        }
      />
      <Popover
        open={updateCompartmentDialogOpen}
        onClose={() => setUpdateCompartmentDialogOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: classes.deltaPopover }}
      >
        {selectedCompartment != null && (
          <ChangeCompartmentForm
            key={selectedCompartment.id}
            compartment={selectedCompartment}
            onSubmit={handleEditCompartment}
            onRemove={handleRemoveCompartment}
            tower={tower}
            rfidReaders={
              rfidBuses?.rfidBuses.find((bus) => bus.id === tower.rfidBus?.id)
                ?.readers
            }
            rfidLedSupportEnabled={rfidSupportEnabled}
          />
        )}
      </Popover>
    </>
  );
}

export default withStyles(styles)(Tower);
