import articlesTableConfig from "./coilTable";
import ArticleForm from "./ArticleForm/CoilArticleForm";
import createArticleFormConfig from "./ArticleForm/createArticleForm";
import editArticleFormConfig from "./ArticleForm/editArticleForm";
import { addArticleType, articleProperties } from "../../plugins/registry";
import stockTableConfig from "./coilStockTable";
import { formatWithUnit } from "../../../util/numberFormat";
import CoilStockDeltaInputField from "./CoilStockDeltaInputField";

addArticleType({
  id: "coil",
  displayName: (n, { t }) => t("Coil", { count: n }),
  stockInfo: {
    unit: () => "m",
    unitName: ({ t }) => t("Länge"),
    deltaUnitName: ({ t }) => t("Länge", { context: "deltaUnitName" }),
    calculateNewStock: (currentStock, delta) =>
      currentStock + delta >= 0 ? currentStock + delta : NaN,
    supportsDeltaByWeight: (article) =>
      article.details.weight != null &&
      !isNaN(article.details.weight) &&
      article.details.length != null &&
      !isNaN(article.details.length),
    formatWithUnit: (value) => formatWithUnit(value, "m"),
    allowDecimals: true,
    weightByStock: (item, count) =>
      (count * item.article.details.weight) / item.article.details.length,
    StockDeltaInputField: CoilStockDeltaInputField,
  },
  articlesTableConfig,
  stockTableConfig,
  ArticleForm,
  createArticleFormConfig,
  editArticleFormConfig,
  properties: {
    ...articleProperties,
    "coil.material": { displayName: (_, { t }) => t("Werkstoff") },
    "coil.length": {
      displayName: (_, { t }) => t("Länge"),
      formatValue: (value) => formatWithUnit(parseFloat(value), "m"),
    },
    "coil.width": {
      displayName: (_, { t }) => t("Breite"),
      formatValue: (value) => formatWithUnit(parseFloat(value), "mm"),
    },
    "coil.thickness": {
      displayName: (_, { t }) => t("Dicke"),
      formatValue: (value) => formatWithUnit(parseFloat(value), "mm", 2),
    },
    "coil.weight": {
      displayName: (_, { t }) => t("Gewicht"),
      formatValue: (value) => formatWithUnit(parseFloat(value), "kg"),
    },
  },
});
