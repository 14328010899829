import React from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  MenuList,
  MenuItem,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import DotsVertical from "mdi-material-ui/DotsVertical";
import useFormState, { getSubmitValues } from "../../../util/useFormState";
import { useRegistry } from "../../plugins/registry";
import PopoverIconButton from "../../../components/PopoverIconButton";
import ButtonDialogTitle from "../../../components/ButtonDialogTitle";
import DraggablePaper from "../../../components/DraggablePaper";
import { useTranslation } from "react-i18next";

export default function EditArticleDialog({
  article,
  open,
  onClose,
  onSubmit,
  ternaryAction,
  archived,
  onArchive,
  onRestore,
  onRemove,
  onCopy,
  noteColumnTitles,
}) {
  const { t } = useTranslation();
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  React.useEffect(() => setShowConfirmDialog(false), [article]);

  const registry = useRegistry();

  const formConfig =
    registry.articleTypes[article?.type]?.editArticleFormConfig;
  const [form, inputProps] = useFormState(formConfig, article);

  const ArticleForm = article
    ? registry.articleTypes[article.type].ArticleForm
    : null;

  React.useEffect(() => {
    // reset the form to fix form not resetting when editing, cancelling and then editing the same article
    if (open) {
      form.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick
      PaperComponent={DraggablePaper}
    >
      <ButtonDialogTitle
        id="draggable-paper-handle"
        button={
          <PopoverIconButton
            icon={DotsVertical}
            closeOnClick
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            tooltip={t("Weitere Optionen")}
          >
            <MenuList>
              <MenuItem onClick={onCopy}>{t("Artikel kopieren")}</MenuItem>
              {archived ? (
                <MenuItem onClick={onRestore}>
                  {t("Artikel wiederherstellen")}
                </MenuItem>
              ) : (
                <MenuItem onClick={onArchive}>
                  {t("Artikel archivieren")}
                </MenuItem>
              )}
              <MenuItem onClick={() => setShowConfirmDialog(true)}>
                {t("Artikel löschen")}
              </MenuItem>
            </MenuList>
          </PopoverIconButton>
        }
      >
        {t("Artikel bearbeiten")}
      </ButtonDialogTitle>
      <DialogContent>
        {ArticleForm && (
          <ArticleForm
            inputProps={inputProps}
            form={form}
            variant="edit"
            noteColumnTitles={noteColumnTitles}
          />
        )}
      </DialogContent>
      <DialogActions>
        {ternaryAction}
        <div style={{ flex: 1 }} />
        <Button
          onClick={() => {
            onClose();
          }}
        >
          {t("Abbrechen")}
        </Button>
        <Button
          color="primary"
          onClick={async () => {
            await onSubmit(article, getSubmitValues(form.values, formConfig));
          }}
          disabled={!form.canSubmit}
        >
          {t("Speichern")}
        </Button>
      </DialogActions>

      {article && (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          PaperComponent={DraggablePaper}
          open={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          PaperProps={{ handle: "#draggable-paper-handle-delete" }}
        >
          <DialogTitle id="draggable-paper-handle-delete">
            {t("Soll dieser Artikel wirklich gelöscht werden?")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText color="textPrimary">
              {t(
                "Möchten Sie den {{type}}-Artikel {{article}} wirklich löschen? Eventuell vorhandene Bestände dieses Artikels werden anschließend nicht mehr angezeigt.",
                {
                  type: registry.articleTypes[article.type].displayName(1, {
                    t,
                  }),
                  article:
                    article.sku && article.name
                      ? `${article.sku} (${article.name})`
                      : article.sku,
                  context: article.type,
                }
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowConfirmDialog(false)}>
              {t("Abbrechen")}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setShowConfirmDialog(false);
                onRemove();
              }}
            >
              {t("Artikel löschen")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
}
