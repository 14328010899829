import React from "react";
import PropTypes from "prop-types";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fff"
    },
    type: "dark"
  },
  typography: {
    useNextVariants: true
  }
});

export function White({ children }) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
White.propTypes = {
  children: PropTypes.node
};
