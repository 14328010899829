import React, { useCallback } from "react";
import { withStyles, Typography, Button, TextField } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import cx from "classnames";
import UnitTextField from "../../../components/UnitTextField";
import ToggleButton from "../../../components/ToggleButton";
import { withRegistry } from "../../plugins/registry";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
  method: {
    margin: "4px 0 0 -8px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  deltaField: {
    margin: "4px 0 16px",
    width: "100%",
  },
  noteField: {
    margin: "4px 0 16px",
    width: "100%",
    "& textarea": {
      minHeight: 20, // fix scroll bar showing if empty
    },
  },
  bottom: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  summary: {
    border: "none",
    float: "left",
    borderSpacing: 0,
    "& td": {
      padding: 0,
    },
    "& td:last-child": {
      textAlign: "right",
    },
  },
  submit: {
    float: "right",
    marginLeft: 24,
  },
  minimumReached: {
    color: red[500],
    fontWeight: 500,
  },
  minimumStock: {
    color: theme.palette.text.secondary,
  },
});

function DefaultStockDeltaInputField({
  className,
  method,
  label,
  autoFocus,
  InputLabelProps,
  unit,
  inputRef,
  value,
  onChange,
  onFocus,
}) {
  const handleChange = useCallback(
    (e) => {
      onChange(e.target.value, e);
    },
    [onChange]
  );

  return (
    <UnitTextField
      className={className}
      label={label}
      method={method}
      autoFocus={autoFocus}
      InputLabelProps={InputLabelProps}
      inputRef={inputRef}
      value={value}
      onChange={handleChange}
      onFocus={onFocus}
      type="number"
      unit={unit}
    />
  );
}

class ChangeStockForm extends React.PureComponent {
  state = {
    delta: 0,
    deltaInput: "0",
    method: "unit", // unit or weight
    note: "",
  };
  deltaFieldRef = React.createRef();

  constructor(props) {
    super(props);

    const { item } = props;
    const currentStock = item.initial + item.in - item.out;
    if (currentStock > 0) {
      this.state.delta = -1; // taking one item out will be the most important action
      this.state.deltaInput = "-1";
    }
  }

  handleSelectInput = (e) => e.target.select();

  handleSetMethod = (method) => () => {
    if (method === "unit") {
      this.setState((state) => ({
        method: "unit",
        deltaInput: state.delta,
      }));
    } else if (method === "weight") {
      const { stockInfo } =
        this.props.registry.stockItemTypes[this.props.item.type];
      this.setState((state) => ({
        method: "weight",
        deltaInput:
          Math.round(
            stockInfo.weightByStock(this.props.item, state.delta) * 1000
          ) / 1000,
      }));
    }
    this.deltaFieldRef.current.focus();
    setTimeout(() => this.deltaFieldRef.current.select());
  };

  handleChangeDelta = (value, e) => {
    if (this.state.method === "unit") {
      if (
        this.props.registry.stockItemTypes[this.props.item.type].stockInfo
          .allowDecimals
      ) {
        this.setState({
          deltaInput: value,
          delta: parseFloat(value),
        });
      } else {
        this.setState({
          deltaInput: value,
          delta: parseInt(value, 10),
        });
      }
    } else {
      // weight
      const { stockInfo } =
        this.props.registry.stockItemTypes[this.props.item.type];
      let delta = null;
      const specificWeight = stockInfo.weightByStock(this.props.item, 1);
      if (!isNaN(specificWeight)) {
        if (stockInfo.allowDecimals) {
          delta = parseFloat(value) / specificWeight;
        } else {
          delta = Math.round(parseFloat(value) / specificWeight);
        }
      }
      this.setState({ deltaInput: value, delta });
    }
  };

  handleChangeNote = (e) => {
    this.setState({ note: e.target.value });
  };

  handleSubmit = () => {
    const { registry, item } = this.props;
    const { deltaToActual } = registry.stockItemTypes[item.type].stockInfo;

    this.props.onSubmit(item, {
      delta: deltaToActual
        ? deltaToActual(this.state.delta, item.article)
        : this.state.delta,
      details: { method: this.state.method, rawInput: this.state.deltaInput },
      note: this.state.note,
    });
  };

  render() {
    const { classes, item, aggregatedItem, registry, t } = this.props;
    const { delta, deltaInput, method, note } = this.state;

    const itemType = registry.stockItemTypes[item.type];
    const stockInfo = itemType.stockInfo;

    const currentStock = item.initial + item.in - item.out;
    const newStock = stockInfo.calculateNewStock(currentStock, delta, item);

    const isSingleItem = aggregatedItem.stockItems.length === 1;
    const aggregatedCurrentStock = isSingleItem
      ? currentStock
      : aggregatedItem.stockItems.reduce(
          (sum, stockItem) =>
            sum + stockItem.initial + stockItem.in - stockItem.out,
          0
        );
    const aggregatedNewStock = isSingleItem
      ? newStock
      : aggregatedItem.stockItems.reduce(
          (sum, stockItem) =>
            sum +
            (stockItem.id === item.id
              ? newStock
              : stockItem.initial + stockItem.in - stockItem.out),
          0
        );

    const StockDeltaInput =
      stockInfo.StockDeltaInputField ?? DefaultStockDeltaInputField;

    return (
      <React.Fragment>
        <Typography variant="body1">{t("Bestand verändern")}</Typography>
        <Typography variant="caption">
          {[
            itemType.displayName(1, { t }),
            item.article.name,
            item.sku ?? item.article.sku,
          ]
            .filter((text) => !!text?.trim())
            .join(" · ")}
        </Typography>
        {stockInfo.supportsDeltaByWeight(item.article) && (
          <div className={classes.method}>
            <ToggleButton
              size="small"
              value={method === "unit"}
              onClick={this.handleSetMethod("unit")}
            >
              {stockInfo.deltaUnitName?.({ t }) || stockInfo.unitName({ t })}
            </ToggleButton>
            <ToggleButton
              size="small"
              value={method === "weight"}
              onClick={this.handleSetMethod("weight")}
            >
              {t("Gewicht", { context: "deltaUnitName" })}
            </ToggleButton>
          </div>
        )}
        <StockDeltaInput
          className={classes.deltaField}
          label={t("Bestandsänderung")}
          method={method}
          autoFocus
          InputLabelProps={{ shrink: true }}
          unit={
            method === "unit"
              ? stockInfo.deltaUnit?.({ t }) || stockInfo.unit({ t })
              : "kg"
          }
          inputRef={this.deltaFieldRef}
          value={deltaInput}
          onChange={this.handleChangeDelta}
          onFocus={this.handleSelectInput}
          item={item}
          article={item.article}
        />
        <TextField
          className={classes.noteField}
          label={t("Bemerkung")}
          InputLabelProps={{ shrink: true }}
          placeholder={t("(optional)")}
          fullWidth
          multiline
          value={note}
          onChange={this.handleChangeNote}
        />
        <div className={classes.bottom}>
          <table className={classes.summary}>
            <tbody>
              <tr>
                <td>
                  <Typography variant="body2">
                    {t("Alter Bestand")}:&nbsp;
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2">
                    {stockInfo.formatWithUnit(currentStock, item.article, {
                      t,
                    })}
                    {!isSingleItem &&
                      ` (${stockInfo.formatWithUnit(
                        aggregatedCurrentStock,
                        item.article,
                        { t }
                      )})`}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="body2">
                    {t("Neuer Bestand")}:&nbsp;
                  </Typography>
                </td>
                <td>
                  <Typography
                    variant="body2"
                    className={cx({
                      [classes.minimumReached]:
                        item.type === item.article.type &&
                        !isNaN(delta) &&
                        item.article.minimumStock > 0 &&
                        aggregatedNewStock <= item.article.minimumStock,
                    })}
                  >
                    {isNaN(newStock)
                      ? "–"
                      : stockInfo.formatWithUnit(newStock, item.article, { t })}
                    {isNaN(aggregatedNewStock) || isSingleItem
                      ? null
                      : ` (${stockInfo.formatWithUnit(
                          aggregatedNewStock,
                          item.article,
                          { t }
                        )})`}
                  </Typography>
                </td>
              </tr>
              <tr className={classes.minimumStock}>
                <td>
                  <Typography variant="body2" color="inherit">
                    {t("Meldebestand")}:&nbsp;
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2" color="inherit">
                    {item.article.minimumStock != null
                      ? stockInfo.formatWithUnit(
                          item.article.minimumStock,
                          item.article,
                          { t }
                        )
                      : "–"}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            className={classes.submit}
            variant="outlined"
            color="primary"
            disabled={isNaN(delta) || delta === 0 || isNaN(newStock)}
            onClick={this.handleSubmit}
          >
            {delta < 0 ? t("Entnahme bestätigen") : t("Eingang bestätigen")}
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(
  withStyles(styles)(withRegistry()(ChangeStockForm))
);
