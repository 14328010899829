import React, { useCallback, useMemo } from "react";
import { Calculator } from "mdi-material-ui";
import PopoverIconButton, {
  PopoverIconButtonProps,
} from "../../../../components/PopoverIconButton";
import UnitTextField from "../../../../components/UnitTextField";
import { useTranslation } from "react-i18next";
import {
  Button,
  FilledInput,
  FormControl,
  InputAdornment,
  InputLabel,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import useInputHandlers from "../../../../util/useInputHandlers";
import { formatWithUnit } from "../../../../util/numberFormat";
// @ts-ignore
import { SearchableSelect } from "@wa/werkstoff-select";
import { specificWeights } from "../../coil/CoilCalculator";

export function calculateWeight({
  thickness,
  specificWeight,
}: {
  thickness: number; // mm
  specificWeight: number; // kg/dm³
}) {
  // returns kg/m² because [ mm * kg/dm³ ] = [ kg/m² ]
  return thickness * specificWeight;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 2 * theme.spacing.unit,
      width: 250,
    },
    row: {},
    results: {
      marginTop: theme.spacing.unit,
    },
    buttons: {
      marginTop: theme.spacing.unit,
      display: "flex",
      flexDirection: "row-reverse",
    },
  });

function SheetCalculatorButton({
  ButtonProps,
  classes,
  defaultSpecificWeight,
  defaultThickness,
  onSubmit,
}: {
  ButtonProps?: Partial<PopoverIconButtonProps>;
  defaultThickness?: number | string;
  defaultSpecificWeight?: number | string;
  onSubmit: (result: { specificWeight: number; thickness: number }) => void;
} & WithStyles<typeof styles>) {
  const { t } = useTranslation();
  const [values, handleChange, setValues] = useInputHandlers({
    thickness: { defaultValue: defaultThickness },
    specificWeight: { defaultValue: defaultSpecificWeight },
  });

  const handleOpen = useCallback(() => {
    setValues({
      thickness: defaultThickness ?? "",
      specificWeight: defaultSpecificWeight ?? "",
    });
  }, [setValues, defaultThickness, defaultSpecificWeight]);

  const results = useMemo(() => {
    const parsedValues = Object.fromEntries(
      Object.entries(values).map(([k, v]) => [k, parseFloat(v)])
    ) as {
      thickness: number;
      specificWeight: number;
    };
    if (isNaN(parsedValues.specificWeight) || isNaN(parsedValues.thickness)) {
      return {
        weight: null,
      };
    }
    const weight = calculateWeight({
      thickness: parsedValues.thickness,
      specificWeight: parsedValues.specificWeight,
    });
    return {
      weight,
      thickness: parseFloat(values.thickness),
    };
  }, [values]);

  const handleSubmit = useCallback(() => {
    if (results.weight != null) {
      onSubmit({
        thickness: results.thickness,
        specificWeight: results.weight,
      });
    }
  }, [onSubmit, results]);

  return (
    <PopoverIconButton
      icon={Calculator}
      IconProps={undefined}
      closeOnClick={false}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PopoverProps={undefined}
      onOpen={handleOpen}
      tooltip={t("Blech-Rechner anzeigen")}
      {...ButtonProps}
    >
      {({ closePopover }: { closePopover: () => void }) => (
        <div className={classes.root}>
          <Typography variant="body1">{t("Blech-Rechner")}</Typography>
          <div className={classes.row}>
            <UnitTextField
              label={t("Dicke")}
              margin="dense"
              variant="filled"
              fullWidth
              unit="mm"
              type="number"
              value={values.thickness}
              onChange={handleChange}
              InputProps={{ inputProps: { "data-field": "thickness" } }}
            />
            <FormControl margin="dense" variant="filled" fullWidth>
              <InputLabel>{t("Dichte")}</InputLabel>
              <SearchableSelect
                value={values.specificWeight?.toLocaleString() ?? ""}
                input={
                  <FilledInput
                    endAdornment={
                      <InputAdornment position="end">
                        <Typography variant="body1">kg/dm³</Typography>
                      </InputAdornment>
                    }
                  />
                }
                items={specificWeights.map((weight) => ({
                  label: `${weight.name} ${formatWithUnit(
                    weight.specificWeight,
                    "kg/dm³"
                  )}`,
                  value: weight.specificWeight,
                }))}
                freeSolo
                searchTextFieldProps={{
                  placeholder: t("Andere Dichte eingeben…"),
                }}
                addItemLabel={(value: string) =>
                  formatWithUnit(parseFloat(value.replace(",", ".")), "kg/dm³")
                }
                onChange={(value: string) => {
                  setValues({
                    specificWeight: parseFloat(value.replace(",", ".")),
                  });
                }}
                margin="dense"
                variant="filled"
              />
            </FormControl>
          </div>
          <div className={classes.results}>
            Gewicht:{" "}
            {(results.weight ?? 0) >= 0
              ? formatWithUnit(results.weight ?? 0, "kg/m²")
              : "–"}
          </div>
          <div className={classes.buttons}>
            <Button
              color="primary"
              onClick={() => {
                handleSubmit();
                closePopover();
              }}
              disabled={results.weight == null || results.weight <= 0}
            >
              {t("Übernehmen")}
            </Button>
          </div>
        </div>
      )}
    </PopoverIconButton>
  );
}

export default withStyles(styles)(SheetCalculatorButton);
