import { commonEditArticleFields } from "../../../articles/components/articleForm";

export default {
  ...commonEditArticleFields({ allowDecimals: true }),
  minimumStock: {
    defaultValue: 0,
    error: minimumStock => minimumStock < 0,
    convert: value => parseInt(value, 10),
    convertBackInitial: (value, { details }) =>
      Math.floor(value / details.length),
    convertOnSubmit: (value, { details }) => value * details.length
  },
  "details.material": {
    defaultValue: ""
  },
  "details.specificWeight": {
    defaultValue: null,
    error: specificWeight =>
      !isNaN(specificWeight) && parseFloat(specificWeight) <= 0,
    convert: value => parseFloat(value) || null, // input -> value
    convertBack: value => value || "" // value -> input
  },
  "details.length": {
    defaultValue: "",
    error: length => !isNaN(length) && parseFloat(length) <= 0,
    convert: value => parseFloat(value) || null, // input -> value
    convertBack: value => value || "" // value -> input
  }
};
