import { Button, TextField, Typography, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import UnitTextField from "../../../components/UnitTextField";
import DefaultArticleLocation from "../../articles/components/DefaultArticleLocation";
import ComponentExtensionPoint from "../../plugins/ComponentExtensionPoint";
import { useRegistry } from "../../plugins/registry";

const styles = {
  flex: {
    flex: 1,
  },
  fields: {
    maxWidth: 420,
    margin: "8px 0 16px",
    display: "flex",
    "& > *": {
      flex: 1,
    },
    "& > *:first-child:not(:last-child)": {
      marginRight: 8,
    },
    "& > *:last-child:not(:first-child)": {
      marginLeft: 8,
    },
  },
  noteField: {
    margin: "4px 0 16px",
    width: "100%",
    "& textarea": {
      minHeight: 20, // fix scroll bar showing if empty
    },
  },
  bottom: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    "& > *": {
      flex: 1,
    },
  },
};

function CreateStockItemForm({ classes, item, onSubmit }) {
  const { t } = useTranslation();
  const registry = useRegistry();
  const stockItemType = registry.stockItemTypes[item.type];

  const [amount, setAmount] = useState("");
  const [location, setLocation] = useState();
  const [note, setNote] = useState("");
  const parsedAmount = stockItemType.stockInfo.allowDecimals
    ? parseFloat(amount) || 0
    : parseInt(amount, 10) || 0;

  return (
    <>
      <Typography variant="body1">
        {t("{{type}}-Einheit hinzufügen", {
          type: registry.stockItemTypes[item.type].displayName(1, { t }),
          context: item.type,
        })}
      </Typography>
      <Typography variant="caption">
        {[item.article.name, item.article.sku]
          .filter((text) => !!text?.trim())
          .join(" · ")}
      </Typography>
      <UnitTextField
        label={t("Bestand")}
        margin="dense"
        fullWidth
        unit={
          stockItemType.stockInfo.deltaUnit?.({ t }) ??
          stockItemType.stockInfo.unit({ t })
        }
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        variant="standard"
        autoFocus
      />
      <div className={classes.fields}>
        <ComponentExtensionPoint
          name="articleFormLocation"
          variant="single"
          innerProps={{
            value: location,
            onChange: ({ compartment, loadCarrier }) => {
              setLocation({ compartment, loadCarrier });
            },
            variant: "standard",
          }}
          DefaultComponent={DefaultArticleLocation}
        />
      </div>
      <TextField
        className={classes.noteField}
        label={t("Bemerkung")}
        InputLabelProps={{ shrink: true }}
        placeholder={t("(optional)")}
        fullWidth
        multiline
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      <div className={classes.bottom}>
        <div className={classes.flex} />
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onSubmit(item.article, parsedAmount, location, note)}
          disabled={parsedAmount < 0}
        >
          {t("Hinzufügen")}
        </Button>
      </div>
    </>
  );
}

export default withStyles(styles)(CreateStockItemForm);
