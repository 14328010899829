import articlesTableConfig from "./articlesTable";
import stockTableConfig from "./stockTable";
import ArticleForm from "./ArticleForm/BreakBulkArticleForm";
import createArticleFormConfig from "./ArticleForm/createArticleForm";
import editArticleFormConfig from "./ArticleForm/editArticleForm";
import { addArticleType, articleProperties } from "../../plugins/registry";
import { formatWithUnit } from "../../../util/numberFormat";

const stockInfo = {
  unit: ({ t }) => t("Stk."),
  unitName: ({ t }) => t("Anzahl"),
  deltaUnitName: ({ t }) => t("Anzahl", { context: "deltaUnitName" }),
  formatWithUnit: (value, article, { t }) =>
    `${value.toLocaleString()} ${t("Stk.")}`,
  allowDecimals: false,
  calculateNewStock: (currentStock, delta) =>
    currentStock + delta >= 0 ? currentStock + delta : NaN,
  supportsDeltaByWeight: (article) =>
    article.details.weight != null && !isNaN(article.details.weight),
  weightByStock: (item, count) => count * item.article.details.weight,
};

addArticleType({
  id: "breakBulk",
  displayName: (_, { t }) => t("Stückgut"),
  stockInfo,
  articlesTableConfig,
  stockTableConfig,
  ArticleForm,
  createArticleFormConfig,
  editArticleFormConfig,
  properties: {
    ...articleProperties,
    "breakBulk.weight": {
      displayName: (_, { t }) => t("Gewicht"),
      formatValue: (value) => formatWithUnit(parseFloat(value), "kg"),
    },
  },
});
