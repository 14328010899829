import React, { useMemo } from "react";
import { FilledInput, FormControl, Input, InputLabel } from "@material-ui/core";
import { SearchableSelect } from "@wa/werkstoff-select";
import { useQuery } from "react-apollo";
import { gql } from "graphql.macro";
import { useTranslation } from "react-i18next";

const GET_TOWERS = gql`
  {
    towers {
      id
      location
      compartments {
        id
        compartment
      }
    }
  }
`;

export default function DefaultCompartmentSelector({
  variant,
  value,
  onChange,
  InputLabelProps,
  margin,
  className,
  disabled,
}) {
  const { t } = useTranslation();
  const { data: towers } = useQuery(GET_TOWERS, {
    fetchPolicy: "cache-and-network",
  });

  const towerItems = useMemo(
    () =>
      towers?.towers.flatMap(({ id, location, compartments }) => [
        {
          value: `location-${id}`,
          label: location,
          type: "subheader",
        },
        ...compartments.map((compartment) => ({
          value: compartment.id,
          label: compartment.compartment,
        })),
      ]) ?? [],
    [towers]
  );

  return (
    <FormControl variant={variant} margin={margin} className={className}>
      <InputLabel shrink {...InputLabelProps}>
        {t("Fach")}
      </InputLabel>
      <SearchableSelect
        value={value}
        onChange={(value) => onChange(value)}
        input={variant === "filled" ? <FilledInput /> : <Input />}
        disabled={disabled}
        noSelectionLabel={<em>{t("Kein Fach")}</em>}
        items={towerItems}
        searchTextFieldProps={{ placeholder: t("Suchen…") }}
      />
    </FormControl>
  );
}
