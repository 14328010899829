import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import { numberColumn } from "../../../util/enhancedTableUtils";
import { compareNaturalBy } from "../../../util/compareNatural";

export default ({ t }) => [
  {
    id: "name",
    title: t("Artikel", { count: 1 }),
    filter: {
      type: StringSearchFilter,
    },
    compare: compareNaturalBy((row) => row.name),
  },
  {
    id: "sku",
    title: t("Artikelnr."),
    filter: {
      type: StringSearchFilter,
    },
    compare: compareNaturalBy((row) => row.sku),
  },
  numberColumn({
    id: "details.weight",
    title: t("Gewicht"),
    getNumber: (row) => row.details.weight,
    unit: { position: "end", name: "kg" },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "minimumStock",
    title: t("Meldebestand"),
    getNumber: (row) => row.minimumStock,
    unit: { position: "end", name: t("Stk.") },
    range: { min: 0, max: null },
  }),
];
