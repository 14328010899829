import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const styles = (theme) => ({
  employee: {
    width: 250,
    margin: `${theme.spacing.unit * 3}px auto 0`,
    display: "block",
  },
});

function AccessKeyForm({ classes, onSubmit, loading, authenticationError }) {
  const { t } = useTranslation();
  const [accessKey, setAccessKey] = React.useState("");

  return (
    <>
      <DialogContent>
        <DialogContentText color="textPrimary" variant="body2">
          <Trans>
            Bitte geben Sie den Zugangscode ein, um den StoreKeeper Smart zu
            benutzen.
          </Trans>
        </DialogContentText>
        <TextField
          className={classes.employee}
          label={t("Zugangscode")}
          margin="dense"
          variant="filled"
          type="password"
          value={accessKey}
          onChange={(e) => setAccessKey(e.target.value)}
          autoFocus
          error={authenticationError}
          helperText={
            authenticationError ? t("Falscher Zugangscode") : undefined
          }
          InputLabelProps={{ shrink: true }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onSubmit({ accessKey });
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => onSubmit({ accessKey })}
          disabled={accessKey === "" || loading}
        >
          {t("Weiter", { context: "setup" })}
        </Button>
      </DialogActions>
    </>
  );
}

export default withStyles(styles)(AccessKeyForm);
