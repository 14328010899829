import { commonEditArticleFields } from "../../../articles/components/articleForm";

export default {
  ...commonEditArticleFields({ allowDecimals: false }),
  "details.material": {
    defaultValue: ""
  },
  "details.length": {
    defaultValue: null,
    error: (length, { details: { area } = {} }) => length <= 0 && !area,
    convert: value => parseFloat(value) || null, // input -> value
    convertBack: value => value || "" // value -> input
  },
  "details.width": {
    defaultValue: null,
    error: (width, { details: { area } = {} }) => width <= 0 && !area,
    convert: value => parseFloat(value) || null, // input -> value
    convertBack: value => value || "" // value -> input
  },
  "details.area": {
    defaultValue: null,
    error: (area, { details: { width, length } = {} }) =>
      area <= 0 && (!width || !length),
    convert: value => parseFloat(value) || null, // input -> value
    convertBack: value => value || "" // value -> input
  },
  "details.thickness": {
    defaultValue: "",
    error: thickness => !isNaN(thickness) && thickness <= 0,
    convert: value => parseFloat(value) || null, // input -> value
    convertBack: value => value || "" // value -> input
  },
  "details.specificWeight": {
    defaultValue: null,
    error: specificWeight =>
      !isNaN(specificWeight) && parseFloat(specificWeight) <= 0,
    convert: value => parseFloat(value) || null, // input -> value
    convertBack: value => value || "" // value -> input
  }
};
