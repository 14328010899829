import { AbilityBuilder, AnyAbility, PureAbility } from "@casl/ability";
import { createContextualCan } from "@casl/react";
import { createContext, useContext } from "react";

export const AbilityContext = createContext<AnyAbility>(
  new AbilityBuilder(PureAbility).build()
);

export const Can = createContextualCan(AbilityContext.Consumer);

export const useAbility = () => {
  return useContext(AbilityContext);
};
