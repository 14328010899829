import React from "react";

import { withStyles } from "@material-ui/core";
import debounce from "debounce";
import LogTable from "./LogTable";
import SearchTextField from "../../../components/SearchTextField";
import { useRegistry } from "../../plugins/registry";
import TablePlaceholder from "../../../components/TablePlaceholder";
import { useTranslation } from "react-i18next";

const styles = {
  root: {
    padding: "24px 24px 14px",
    flex: 1,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  toolbar: {
    marginBottom: 16,
    display: "flex",
    flexDirection: "row",
    height: 36,
  },
  search: {
    width: 250,
  },
  table: {
    flex: 1,
  },
};

function Log({ classes, items, articleType, loading, error }) {
  const { t } = useTranslation();
  const registry = useRegistry();
  const [search, setSearch] = React.useState();

  const handleChangeSearch = React.useMemo(
    () =>
      debounce((input) => {
        setSearch(input);
      }, 100),
    []
  );

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <SearchTextField
          onChange={handleChangeSearch}
          placeholder={t("Suchen…")}
          className={classes.search}
        />
      </div>
      <LogTable
        className={classes.table}
        data={loading ? null : items}
        search={search}
        order="desc"
        orderBy="date"
        size="small"
        EnhancedTableHeadProps={{ size: "medium" }}
        articleType={articleType}
        placeholder={
          <TablePlaceholder>
            {error ? (
              <>
                {t("Beim Laden der Historie ist ein Fehler aufgetreten.")}
                <br />
                <br />
                {error.graphQLErrors?.length === 0 &&
                  t("Bitte überprüfen Sie Ihre Internetverbindung.")}
              </>
            ) : (
              <>
                {t("Die Historie enthält noch keine Einträge.")}
                <br />
                <br />
                {t(
                  "Sobald Sie beginnen, den StoreKeeper Smart zu nutzen, finden Sie an dieser Stelle eine Auflistung sämtlicher Änderungen der {{type}}-Bestände und -Artikel.",
                  {
                    type: registry.articleTypes[articleType].displayName(1, {
                      t,
                    }),
                    context: articleType,
                  }
                )}
              </>
            )}
          </TablePlaceholder>
        }
      />
    </div>
  );
}

export default withStyles(styles)(Log);
