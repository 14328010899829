import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { withStyles, TextField } from "@material-ui/core";
import {
  TagTextOutline,
  Buffer,
  RulerSquare,
  NoteOutline,
} from "mdi-material-ui";
import UnitTextField from "../../../../components/UnitTextField";
import InitialStockSelector from "../../../articles/components/InitialStockSelector";
import { useTranslation } from "react-i18next";
import { useRegistry } from "../../../plugins/registry";
import CoilCalculatorButton, { getSpecificWeight } from "../CoilCalculator";
import {
  LENGTH_M,
  LENGTH_MM,
  WEIGHT,
  roundToDigits,
} from "../../../../util/numberFormat";
import CoilStockInputField from "./CoilStockInputField";

const styles = (theme) => ({
  root: {},
  fieldset: {
    display: "flex",
    "& > div": {
      flex: 1,
    },
  },
  icon: {
    marginTop: 20,
    marginRight: 16,
    color: theme.palette.action.active,
  },
  row: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: 16,
    },
  },
  multiline: {
    "& > div": {
      padding: "24px 12px 6px",
    },
  },
  initialStock: {
    overflow: "hidden",
  },
});

function ArticleForm({
  classes,
  inputProps,
  form,
  variant = "new",
  noteColumnTitles,
}) {
  const { t } = useTranslation();
  const registry = useRegistry();

  const handleApplyCalculatedValues = useCallback(
    (values) => {
      form.setValues(
        variant === "new"
          ? {
              "coil.weight": roundToDigits(values.weight, WEIGHT),
              "coil.length": roundToDigits(values.length / 1000, LENGTH_M),
              "coil.width": roundToDigits(values.width, LENGTH_MM),
              "coil.thickness": values.thickness,
            }
          : {
              "details.weight": roundToDigits(values.weight, WEIGHT),
              "details.length": roundToDigits(values.length / 1000, LENGTH_M),
              "details.width": roundToDigits(values.width, LENGTH_MM),
              "details.thickness": values.thickness,
            }
      );
    },
    [form, variant]
  );

  return (
    <div className={classes.root}>
      <div className={classes.fieldset}>
        <TagTextOutline className={classes.icon} />
        <div>
          <TextField
            label={t("Artikelnummer")}
            margin="dense"
            variant="filled"
            fullWidth
            autoFocus
            {...inputProps("sku")}
          />
          <TextField
            label={t("Bezeichnung")}
            className={classes.textField}
            margin="dense"
            variant="filled"
            fullWidth
            {...inputProps("name")}
          />
          <TextField
            label={t("Werkstoff")}
            margin="dense"
            variant="filled"
            fullWidth
            {...inputProps(
              variant === "new" ? "coil.material" : "details.material"
            )}
          />
        </div>
      </div>
      <div className={classes.fieldset}>
        <RulerSquare className={classes.icon} />
        <div>
          <div className={classes.row}>
            <UnitTextField
              label={t("Dicke")}
              margin="dense"
              variant="filled"
              fullWidth
              unit="mm"
              type="number"
              {...inputProps(
                variant === "new" ? "coil.thickness" : "details.thickness"
              )}
            />
            <UnitTextField
              label={t("Breite")}
              margin="dense"
              variant="filled"
              fullWidth
              unit="mm"
              type="number"
              {...inputProps(
                variant === "new" ? "coil.width" : "details.width"
              )}
            />
          </div>
          <div className={classes.row}>
            <UnitTextField
              label={t("Länge")}
              margin="dense"
              variant="filled"
              fullWidth
              unit="m"
              type="number"
              {...inputProps(
                variant === "new" ? "coil.length" : "details.length"
              )}
            />
            <UnitTextField
              label={t("Gewicht")}
              margin="dense"
              variant="filled"
              fullWidth
              unit="kg"
              type="number"
              {...inputProps(
                variant === "new" ? "coil.weight" : "details.weight"
              )}
            />
            <CoilCalculatorButton
              ButtonProps={{
                style: { alignSelf: "center", marginLeft: -8 },
              }}
              defaultWidth={
                variant === "new"
                  ? form.values.coil.width
                  : form.values.details.width
              }
              defaultThickness={
                variant === "new"
                  ? form.values.coil.thickness
                  : form.values.details.thickness
              }
              defaultSpecificWeight={
                getSpecificWeight(
                  variant === "new"
                    ? form.values.coil.material ?? ""
                    : form.values.details.material ?? ""
                ) ?? 7.85
              }
              onSubmit={handleApplyCalculatedValues}
            />
          </div>
        </div>
      </div>
      <div className={classes.fieldset}>
        <Buffer className={classes.icon} />
        <div className={classes.initialStock}>
          {variant === "new" && (
            <InitialStockSelector
              value={form.values.initialStock}
              onChange={(initialStock) => {
                form.setValues({ initialStock });
              }}
              unit={registry.stockItemTypes.coil.stockInfo.unit({ t })}
              articleType="coil"
              StockInputField={CoilStockInputField}
              StockInputFieldProps={{ article: form.values }}
            />
          )}
          <UnitTextField
            label={t("Meldebestand")}
            margin="dense"
            variant="filled"
            fullWidth
            unit={registry.stockItemTypes.coil.stockInfo.unit({ t })}
            type="number"
            {...inputProps("minimumStock")}
          />
        </div>
      </div>
      {variant === "new" && (
        <div className={classes.fieldset}>
          <NoteOutline className={classes.icon} />
          <div>
            {noteColumnTitles.map((title, i) => (
              <TextField
                key={`note${i}`}
                label={title}
                margin="dense"
                variant="filled"
                fullWidth
                multiline
                className={classes.multiline}
                {...inputProps(i === 0 ? "note" : `note${i + 1}`)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

ArticleForm.propTypes = {
  variant: PropTypes.oneOf(["new", "edit"]),
  noteColumnTitles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default withStyles(styles)(ArticleForm);
