import React, { ReactNode, createContext, useContext, useMemo } from "react";
import useLocalStorageState from "../../../util/useLocalStorageState";

interface User {
  features: {
    articleTypes: {
      [type: string]: boolean;
    };
    loadCarriers: boolean;
  };
}

const SklContext = createContext<{
  type: string;
  setType: (type: string) => void;
  employee?: string;
  setEmployee: (name: string) => void;
  route: string;
  user?: User;
}>(null!);

export function SklContextProvider({
  route,
  children,
  user,
}: {
  route: string;
  children: ReactNode;
  user?: User;
}) {
  const [type, setType] = useLocalStorageState("skl-articleType", "sheet");
  const [employee, setEmployee] = useLocalStorageState("skl-employee", "");

  return (
    <SklContext.Provider
      value={useMemo(
        () => ({
          type: type || "sheet",
          setType,
          employee: employee || "",
          setEmployee,
          route,
          user,
        }),
        [type, setType, employee, setEmployee, route, user]
      )}
    >
      {children}
    </SklContext.Provider>
  );
}

export function useArticleType() {
  const { type, setType } = useContext(SklContext);
  return [type, setType];
}

export function useEmployee() {
  const { employee, setEmployee } = useContext(SklContext);
  return [employee, setEmployee];
}

export function useUser() {
  const { user } = useContext(SklContext);
  return user;
}

export interface FeatureToggles {
  articleTypes: Record<string, boolean>;
  loadCarriers: boolean;
  strictArticleSearch: boolean;
  strictStockSearch: boolean;
}

export function useFeatures(): Partial<FeatureToggles> {
  const user = useUser();
  return useMemo(() => user?.features ?? {}, [user]);
}

export function useRoute() {
  const { route } = useContext(SklContext);
  return route;
}
