import { useEffect } from "react";
import PropTypes from "prop-types";

export default function BarcodeScanner({
  onScan,
  children = null,
}: {
  onScan: (barcode: string) => void;
  children?: JSX.Element | null;
}) {
  useEffect(() => {
    let scanDoneTimeout: NodeJS.Timeout;
    let input = "";
    const handleKeyPress = (e: KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();
      clearTimeout(scanDoneTimeout);
      if (/^[A-Za-z0-9.,-ß]$/.test(e.key)) {
        input += e.key;
        scanDoneTimeout = setTimeout(
          () => {
            onScan(cleanupBarcode(input));
            input = "";
          },
          process.env.NODE_ENV === "production" ? 500 : 5000
        );
      } else if (e.key === "Enter" || e.key === "Tab") {
        clearTimeout(scanDoneTimeout);
        onScan(cleanupBarcode(input));
        input = "";
      }
    };

    document.addEventListener("keydown", handleKeyPress, true);
    return () => {
      clearTimeout(scanDoneTimeout);
      document.removeEventListener("keydown", handleKeyPress, true);
    };
  }, [onScan]);

  return children;
}

BarcodeScanner.propTypes = {
  children: PropTypes.node,
  onScan: PropTypes.func.isRequired,
};

function cleanupBarcode(barcode: string): string {
  return barcode
    .trim() // remove obsolete whitespace
    .replace(/ß/g, "-"); // dash with wrong keyboard layout (us vs. de keyboard layout)
}
