import React, { useMemo } from "react";
import { gql } from "graphql.macro";
import Log from "../components/Log";
import { useQuery } from "react-apollo";
import { useArticleType } from "../../core/context/applicationContext";
import { useRegistry } from "../../plugins/registry";

const GET_LOG = gql`
  query GetLog($type: ArticleType) {
    log(articleType: $type) {
      id
      date
      type
      employee
    }
  }
`;

// TODO pagination
export default function LogContainer() {
  const registry = useRegistry();
  const [type] = useArticleType();

  const query = useMemo(() => {
    const selections = [
      ...GET_LOG.definitions[0].selectionSet.selections[0].selectionSet
        .selections,
    ];
    for (const { logItemFragment } of Object.values(registry.logItemTypes)) {
      selections.push({
        kind: "InlineFragment",
        directives: [],
        selectionSet: logItemFragment.definitions[0].selectionSet,
        typeCondition: logItemFragment.definitions[0].typeCondition,
      });
    }
    return {
      ...GET_LOG,
      definitions: [
        {
          ...GET_LOG.definitions[0],
          selectionSet: {
            ...GET_LOG.definitions[0].selectionSet,
            selections: [
              {
                ...GET_LOG.definitions[0].selectionSet.selections[0],
                selectionSet: {
                  ...GET_LOG.definitions[0].selectionSet.selections[0]
                    .selectionSet,
                  selections,
                },
              },
            ],
          },
        },
      ],
    };
  }, [registry]);

  const { data, error, loading } = useQuery(query, {
    variables: { type },
    fetchPolicy: "network-only",
    pollInterval: 15000,
  });
  return (
    <Log
      items={(data && data.log) || []}
      articleType={type}
      error={error}
      loading={loading}
    />
  );
}
