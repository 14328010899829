import React, { useMemo } from "react";
import { useMutation } from "react-apollo";
import { gql } from "graphql.macro";
import { MenuItem } from "@material-ui/core";
import { useSnackbar } from "material-ui-snackbar-provider";
import VectorDifferenceBa from "mdi-material-ui/VectorDifferenceBa";
import articlesTableConfig from "./articlesTable";
import stockTableConfig from "./stockTable";
// import restTableConfig from "./restTable";
import restVariantTableConfig from "./restVariantTable";
import ArticleForm from "./ArticleForm/LongGoodArticleForm";
import createArticleFormConfig from "./ArticleForm/createArticleForm";
import editArticleFormConfig from "./ArticleForm/editArticleForm";
import {
  addArticleType,
  addStockItemType,
  useRegistry,
  articleProperties,
} from "../../plugins/registry";
import CreateRestLongGoodForm from "./CreateRestLongGoodForm";
import { articleFragment, GET_STOCK } from "../../stock/containers/Stock";
import { useEmployee } from "../../core/context/applicationContext";
import { formatWithUnit } from "../../../util/numberFormat";
import { useTranslation } from "react-i18next";

const stockInfo = {
  unit: () => "m",
  unitName: ({ t }) => t("Länge"),
  deltaUnit: ({ t }) => t("Stk."),
  deltaUnitName: ({ t }) => t("Anzahl", { context: "deltaUnitName" }),
  deltaToActual: (value, article) => value * article.details.length,
  formatWithUnit: (value, article, { t }) =>
    article && article.details && article.details.length
      ? `${formatWithUnit(value, "m")} (${Math.floor(
          value / article.details.length
        )} ${t("Stk.")})`
      : formatWithUnit(value, "m"),
  allowDecimals: false,
  calculateNewStock: (currentStock, delta, item) =>
    currentStock + delta * item.article.details.length >= 0
      ? currentStock + delta * item.article.details.length
      : NaN,
  supportsDeltaByWeight: (article) =>
    article.details.specificWeight != null &&
    !isNaN(article.details.specificWeight),
  weightByStock: (item, count) =>
    count * item.article.details.length * item.article.details.specificWeight,
};

const createRemnantAction = {
  label: ({ t }) => t("Restlänge anlegen"),
  MenuItem: ({ item, onClose, ...other }) => {
    const { t } = useTranslation();
    return <MenuItem {...other}>{t("Restlänge anlegen")}</MenuItem>;
  },
  PopoverForm: ({ item, onClose }) => {
    const [employee] = useEmployee();
    const { t } = useTranslation();
    const snackbar = useSnackbar();
    const registry = useRegistry();
    const [createRestLongGood] = useMutation(
      gql`
        mutation CreateRestLongGood(
          $item: CreateItemInput!
          $initialStock: Float!
          $changeItemStock: Float
          $employee: String
          $note: String
        ) {
          createItem(
            item: $item
            initialStock: $initialStock
            changeItemStock: $changeItemStock
            employee: $employee
            note: $note
          ) {
            id
            sku
            article {
              ...StockArticle
            }
          }
        }
        ${articleFragment}
      `,
      {
        refetchQueries: () => [
          {
            query: GET_STOCK,
            variables: { type: "longGood", articleType: "longGood" },
          },
        ],
      }
    );
    const [revertCreateRestLongGood] = useMutation(
      gql`
        mutation RevertCreateRestLongGood(
          $id: ID!
          $item: CreateItemInput!
          $initialStock: Float!
          $changeItemStock: Float
          $employee: String
        ) {
          revertCreateItem(
            id: $id
            item: $item
            initialStock: $initialStock
            changeItemStock: $changeItemStock
            employee: $employee
          ) {
            id
            article {
              ...StockArticle
            }
          }
        }
        ${articleFragment}
      `,
      {
        refetchQueries: () => [
          {
            query: GET_STOCK,
            variables: { type: "longGood", articleType: "longGood" },
          },
        ],
      }
    );
    return (
      <CreateRestLongGoodForm
        item={item}
        withCount
        onSubmit={async ({
          articleId,
          length,
          count,
          loadCarrier,
          compartment,
          updateStock,
          note,
        }) => {
          try {
            const articleLength = item.article.details.length;
            const variables = {
              item: {
                articleId,
                itemId: item.id,
                restLongGoodVariant: { length },
                type: "restLongGoodVariant",
                loadCarrierId: loadCarrier,
                compartmentId: compartment,
              },
              initialStock: count,
              changeItemStock: updateStock
                ? -Math.ceil((length * count) / articleLength) * articleLength
                : null,
              employee,
              note,
            };
            const restItem = await createRestLongGood({
              variables,
            }).then((result) => result.data.createItem);
            snackbar.showMessage(
              t("Die Restlänge {{sku}} wurde angelegt", {
                sku: restItem.sku,
              }),
              t("Rückgängig"),
              async () => {
                try {
                  await revertCreateRestLongGood({
                    variables: { ...variables, id: restItem.id },
                  });
                } catch (e) {
                  console.error(e);
                  snackbar.showMessage(
                    t(
                      "Das Anlegen der Restlänge konnte nicht rückgängig gemacht werden"
                    )
                  );
                }
              }
            );
            onClose();
          } catch (e) {
            console.error(e);
            snackbar.showMessage(
              t("Die Restlänge konnte nicht angelegt werden")
            );
          }
        }}
      />
    );
  },
};

addArticleType({
  id: "longGood",
  displayName: (_, { t }) => t("Langgut"),
  stockInfo,
  articlesTableConfig,
  stockTableConfig,
  ArticleForm,
  createArticleFormConfig,
  editArticleFormConfig,
  stockActions: [
    /*{
      label: "Restlänge anlegen",
      MenuItem: ({ item, onClose, ...other }) => {
        return <MenuItem {...other}>Restlänge anlegen</MenuItem>;
      },
      PopoverForm: ({ item, onClose }) => {
        const [employee] = useEmployee();
        const snackbar = useSnackbar();
        const registry = useRegistry();
        const [createRestLongGood] = useMutation(gql`
          mutation CreateRestLongGood(
            $articleId: ID!
            $length: Float!
            $employee: String
          ) {
            createItem(
              item: { articleId: $articleId, type: restLongGood }
              initialStock: $length
              employee: $employee
            ) {
              id
              sku
              article {
                ...StockArticle
              }
            }
          }
          ${articleFragment}
        `);
        return (
          <CreateRestLongGoodForm
            item={item}
            onSubmit={async ({ articleId, length }) => {
              try {
                const item = await createRestLongGood({
                  variables: { articleId, length, employee }
                }).then(result => result.data.createItem);
                snackbar.showMessage(
                  `Die Restlänge ${item.sku} wurde angelegt`
                );
                onClose();
              } catch (e) {
                console.error(e);
                snackbar.showMessage(
                  "Die Restlänge konnte nicht angelegt werden"
                );
              }
            }}
          />
        );
      }
    },*/
    createRemnantAction,
  ],
  articleActions: [
    {
      ...createRemnantAction,
      PopoverForm: ({ article, ...other }) => {
        const item = useMemo(
          () => ({
            article,
            initial: 0,
            in: 0,
            out: 0,
            type: "longGood",
          }),
          [article]
        );

        return <createRemnantAction.PopoverForm {...other} item={item} />;
      },
    },
  ],
  properties: {
    ...articleProperties,
    "longGood.material": { displayName: (_, { t }) => t("Werkstoff") },
    "longGood.specificWeight": {
      displayName: (_, { t }) => t("Gewicht"),
      formatValue: (value) => formatWithUnit(parseFloat(value), "kg/m"),
    },
    "longGood.length": {
      displayName: (_, { t }) => t("Länge pro Stück"),
      formatValue: (value) => formatWithUnit(parseFloat(value), "m"),
    },
  },
});

/* addStockItemType({
  id: "restLongGood",
  articleType: "longGood",
  displayName: n => (n === 1 ? "Restlänge" : "Restlängen"),
  icon: VectorDifferenceBa,
  stockInfo: {
    unit: () => "m",
    unitName: () => "Länge",
    formatWithUnit: value => formatWithUnit(value, "m"),
    allowDecimals: true,
    calculateNewStock: (currentStock, delta) =>
      currentStock + delta >= 0 ? currentStock + delta : NaN,
    sku: item => `${item.article.sku}-R${item.id}`,
    supportsDeltaByWeight: article =>
      article.details.specificWeight != null &&
      !isNaN(article.details.specificWeight),
    weightByStock: (item, count) => count * item.article.details.specificWeight
  },
  stockTableConfig: restTableConfig,
  stockActions: [
    {
      label: "Restlänge ausblenden",
      MenuItem: ({ item, onClose, ...other }) => {
        const [handleHideItem] = useMutation(
          gql`
            mutation HideStockItem($id: ID!) {
              hideItem(id: $id) {
                id
              }
            }
          `,
          {
            update: (cache, { data: { hideItem } }) => {
              const data = cache.readQuery({
                query: GET_STOCK,
                variables: { type: "restLongGood", articleType: "longGood" }
              });
              cache.writeQuery({
                query: GET_STOCK,
                variables: { type: "restLongGood", articleType: "longGood" },
                data: {
                  ...data,
                  stock: data.stock.filter(item => item.id !== hideItem.id)
                }
              });
            }
          }
        );
        return (
          <MenuItem
            disabled={item.initial + item.in - item.out > 0}
            {...other}
            onClick={async () => {
              await handleHideItem({ variables: { id: item.id } });
              onClose();
            }}
          >
            Restlänge ausblenden
          </MenuItem>
        );
      }
    }
  ]
}); */

addStockItemType({
  id: "restLongGoodVariant",
  articleType: "longGood",
  displayName: (n, { t }) => t("Restlänge", { count: n }),
  icon: VectorDifferenceBa,
  stockInfo: {
    unit: ({ t }) => t("Stk."),
    unitName: ({ t }) => t("Anzahl"),
    formatWithUnit: (value, article, { t }) =>
      `${value.toLocaleString()} ${t("Stk.")}`,
    allowDecimals: false,
    calculateNewStock: (currentStock, delta) =>
      currentStock + delta >= 0 ? currentStock + delta : NaN,
    sku: (item) => `${item.article.sku}-R${item.id}`,
    supportsDeltaByWeight: (article) =>
      article.details.specificWeight != null &&
      !isNaN(article.details.specificWeight),
    weightByStock: (item, count) =>
      count * item.details.length * item.article.details.specificWeight,
  },
  stockTableConfig: restVariantTableConfig,
  stockActions: [
    {
      label: ({ t }) => t("Restlänge anlegen"),
      MenuItem: ({ item, onClose, ...other }) => {
        const { t } = useTranslation();
        return <MenuItem {...other}>{t("Restlänge anlegen")}</MenuItem>;
      },
      PopoverForm: ({ item, onClose }) => {
        const { t } = useTranslation();
        const [employee] = useEmployee();
        const snackbar = useSnackbar();
        const registry = useRegistry();
        const [createRestLongGood] = useMutation(
          gql`
            mutation CreateRestLongGood(
              $item: CreateItemInput!
              $initialStock: Float!
              $changeItemStock: Float
              $employee: String
              $note: String
            ) {
              createItem(
                item: $item
                initialStock: $initialStock
                changeItemStock: $changeItemStock
                employee: $employee
                note: $note
              ) {
                id
                sku
                article {
                  ...StockArticle
                }
              }
            }
            ${articleFragment}
          `,
          {
            refetchQueries: [
              {
                query: GET_STOCK,
                variables: {
                  type: "restLongGoodVariant",
                  articleType: "longGood",
                },
              },
            ],
          }
        );
        const [revertCreateRestLongGood] = useMutation(
          gql`
            mutation RevertCreateRestLongGood(
              $id: ID!
              $item: CreateItemInput!
              $initialStock: Float!
              $changeItemStock: Float
              $employee: String
            ) {
              revertCreateItem(
                id: $id
                item: $item
                initialStock: $initialStock
                changeItemStock: $changeItemStock
                employee: $employee
              ) {
                id
                sku
                article {
                  ...StockArticle
                }
              }
            }
            ${articleFragment}
          `,
          {
            refetchQueries: () => [
              {
                query: GET_STOCK,
                variables: {
                  type: "restLongGoodVariant",
                  articleType: "longGood",
                },
              },
            ],
          }
        );
        return (
          <CreateRestLongGoodForm
            item={item}
            withCount
            onSubmit={async ({
              articleId,
              length,
              count,
              loadCarrier,
              compartment,
              updateStock,
              note,
            }) => {
              try {
                const variables = {
                  item: {
                    articleId,
                    itemId: item.id,
                    restLongGoodVariant: { length },
                    type: "restLongGoodVariant",
                    loadCarrierId: loadCarrier,
                    compartmentId: compartment,
                  },
                  initialStock: count,
                  changeItemStock: updateStock
                    ? -Math.ceil((length * count) / item.details.length)
                    : null,
                  employee,
                  note,
                };
                const restItem = await createRestLongGood({
                  variables,
                }).then((result) => result.data.createItem);
                snackbar.showMessage(
                  t("Die Restlänge {{sku}} wurde angelegt", {
                    sku: restItem.sku,
                  }),
                  t("Rückgängig"),
                  async () => {
                    try {
                      await revertCreateRestLongGood({
                        variables: { ...variables, id: restItem.id },
                      });
                    } catch (e) {
                      console.error(e);
                      snackbar.showMessage(
                        t(
                          "Das Anlegen der Restlänge konnte nicht rückgängig gemacht werden"
                        )
                      );
                    }
                  }
                );
                onClose();
              } catch (e) {
                console.error(e);
                snackbar.showMessage(
                  t("Die Restlänge konnte nicht angelegt werden")
                );
              }
            }}
          />
        );
      },
    },
    {
      label: ({ t }) => t("Restlänge ausblenden"),
      MenuItem: ({ item, onClose, ...other }) => {
        const { t } = useTranslation();
        const snackbar = useSnackbar();
        const [handleHideItem] = useMutation(
          gql`
            mutation HideStockItem($id: ID!) {
              hideItem(id: $id) {
                id
              }
            }
          `,
          {
            refetchQueries: [
              {
                query: GET_STOCK,
                variables: {
                  type: "restLongGoodVariant",
                  articleType: "longGood",
                },
              },
            ],
          }
        );
        return (
          <MenuItem
            disabled={item.initial + item.in - item.out > 0}
            {...other}
            onClick={async () => {
              try {
                await handleHideItem({ variables: { id: item.id } });
                onClose();
              } catch (e) {
                console.error(e);
                snackbar.showMessage(
                  t("Die Restlänge konnte nicht ausgeblendet werden")
                );
              }
            }}
          >
            Restlänge ausblenden
          </MenuItem>
        );
      },
    },
  ],
  stockActionsConfig: {
    canCreateStockItems: false,
    canHideStockItems: false,
  },
  properties: {
    "restLongGoodVariant.length": {
      displayName: (_, { t }) => t("Länge"),
      formatValue: (value) => formatWithUnit(parseFloat(value) * 1000, "mm"),
    },
    "restLongGoodVariant.location": {
      displayName: (__, { t }) => t("Lagerort"),
    },
    "restLongGoodVariant.chargeCarrier": {
      displayName: (_, { t }) => t("Ladungsträger"),
    },
  },
});
