import {
  commonCreateArticleFields,
  nullIfNaN,
} from "../../../articles/components/articleForm";

export default {
  ...commonCreateArticleFields({ allowDecimals: true }),
  initialStock: {
    defaultValue: [],
    error: (initialStock) => initialStock.some((item) => item.amount < 0),
    convertOnSubmit: (initialStock, { longGood }) =>
      initialStock.map(({ amount, location }) => ({
        ...location,
        amount: nullIfNaN(parseFloat(amount) * longGood.length) ?? 0,
      })),
  },
  minimumStock: {
    defaultValue: 0,
    error: (minimumStock) => minimumStock < 0,
    convert: (value) => nullIfNaN(parseInt(value, 10)),
    convertBackInitial: (value, { longGood }) =>
      value === 0 ? 0 : Math.floor(value / longGood.length),
    convertOnSubmit: (value, { longGood }) => value * longGood.length,
  },
  "longGood.material": {
    defaultValue: "",
  },
  "longGood.specificWeight": {
    defaultValue: null,
    error: (specificWeight) =>
      !isNaN(specificWeight) && parseFloat(specificWeight) <= 0,
    convert: (value) => parseFloat(value) || null, // input -> value
    convertBack: (value) => value || "", // value -> input
  },
  "longGood.length": {
    defaultValue: null,
    error: (length) => !isNaN(length) && parseFloat(length) <= 0,
    convert: (value) => parseFloat(value) || null, // input -> value
    convertBack: (value) => value || "", // value -> input
  },
};
