import React, { ChangeEvent, FocusEventHandler, useCallback } from "react";
import cx from "classnames";
import UnitTextField from "../../../components/UnitTextField";
import CoilCalculator, { getSpecificWeight } from "./CoilCalculator";
import { LENGTH_M, roundToDigits } from "../../../util/numberFormat";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { InputLabelProps as InputLabelPropsType } from "@material-ui/core/InputLabel";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    input: {
      flex: 1,
    },
    calculator: {
      marginLeft: theme.spacing.unit,
    },
  });

function CoilStockInputField({
  classes,
  className,
  method,
  label,
  autoFocus,
  InputLabelProps,
  unit,
  inputRef,
  value,
  onChange,
  onFocus,
  item,
  article,
}: {
  className: string;
  method: "unit" | "weight";
  label: string;
  autoFocus?: boolean;
  InputLabelProps?: InputLabelPropsType;
  unit: string;
  inputRef: any;
  value: string;
  onChange: (
    value: string,
    e?: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>
  ) => void;
  onFocus: FocusEventHandler<
    HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
  >;
  item: any;
  article: any;
} & WithStyles<typeof styles>) {
  const handleChange = useCallback(
    (
      e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>
    ) => {
      onChange(e.target.value, e);
    },
    [onChange]
  );

  const handleSubmit = useCallback(
    ({ length }: { length: number }) => {
      const currentStock = item.initial + item.in - item.out;
      const newStock = length / 1000;
      onChange(roundToDigits(newStock - currentStock, LENGTH_M).toString());
    },
    [onChange, item]
  );

  return (
    <div className={cx(classes.root, className)}>
      <UnitTextField
        label={label}
        autoFocus={autoFocus}
        InputLabelProps={InputLabelProps}
        inputRef={inputRef}
        value={value}
        onChange={handleChange}
        onFocus={onFocus}
        type="number"
        unit={unit}
        className={classes.input}
      />
      {method === "unit" && (
        <CoilCalculator
          ButtonProps={{ className: classes.calculator }}
          defaultWidth={article.details.width}
          defaultThickness={article.details.thickness}
          defaultSpecificWeight={(
            getSpecificWeight(article.details.material ?? "") ?? 7.85
          ).toLocaleString()}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(CoilStockInputField);
