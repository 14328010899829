export const AREA = 1;
export const WEIGHT = 1;
export const LENGTH_M = 3;
export const LENGTH_MM = 0;

export function numberToFixed(
  number: number,
  fractionalDigits: number
): string {
  return number.toLocaleString(undefined, {
    minimumFractionDigits: fractionalDigits,
    maximumFractionDigits: fractionalDigits,
  });
}

const digitsByUnit = {
  "m²": AREA,
  kg: WEIGHT,
  "kg/m": WEIGHT,
  "kg/m²": WEIGHT,
  m: LENGTH_M,
  mm: LENGTH_MM,
};

export function formatWithUnit(
  number: number,
  unit?: string,
  fractionalDigits: number = unit != null && unit in digitsByUnit
    ? digitsByUnit[unit as keyof typeof digitsByUnit]
    : 0
): string {
  return unit
    ? `${numberToFixed(number, fractionalDigits)} ${unit}`
    : numberToFixed(number, fractionalDigits);
}

export function roundToDigits(
  number: number,
  fractionalDigits: number
): number {
  return (
    Math.round(number * Math.pow(10, fractionalDigits)) /
    Math.pow(10, fractionalDigits)
  );
}
