import React from "react";
import { IconButton, withStyles } from "@material-ui/core";
import { Close } from "mdi-material-ui";
import UnitTextField from "../../../components/UnitTextField";
import ComponentExtensionPoint from "../../plugins/ComponentExtensionPoint";
import DefaultArticleLocation from "./DefaultArticleLocation";
import { useTranslation } from "react-i18next";

const styles = {
  root: {},
  item: {
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: 16,
    },
    "& > button": {
      marginLeft: -8,
      padding: 4,
    },
  },
  input: {
    width: "100%",
  },
};

function DefaultStockInputField({ value, onChange, unit, label }) {
  return (
    <UnitTextField
      label={label}
      unit={unit}
      value={value}
      onChange={(e) => onChange(e.target.value, e)}
      margin="dense"
      variant="filled"
      fullWidth
      type="number"
    />
  );
}

function InitialStockSelector({
  classes,
  value,
  onChange,
  unit,
  StockInputField = DefaultStockInputField,
  StockInputFieldProps,
}) {
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      {[
        ...value.map((item, i) => (
          <div key={i} className={classes.item}>
            <StockInputField
              {...StockInputFieldProps}
              label={t("Bestand")}
              unit={unit}
              value={item.amount ?? ""}
              onChange={(amount) => {
                onChange(
                  value.map((oldItem) =>
                    oldItem === item
                      ? {
                          ...item,
                          amount,
                        }
                      : oldItem
                  )
                );
              }}
            />
            <ComponentExtensionPoint
              name="articleFormLocation"
              variant="single"
              innerProps={{
                className: classes.input,
                value: item.location,
                onChange: ({ compartment, loadCarrier }) => {
                  onChange(
                    value.map((oldItem) =>
                      oldItem === item
                        ? {
                            ...item,
                            location: { compartment, loadCarrier },
                          }
                        : oldItem
                    )
                  );
                },
              }}
              DefaultComponent={DefaultArticleLocation}
            />
            <IconButton
              onClick={() => onChange(value.filter((v) => v !== item))}
            >
              <Close />
            </IconButton>
          </div>
        )),
        <div key={value.length} className={classes.item}>
          <StockInputField
            {...StockInputFieldProps}
            label={t("Bestand")}
            unit={unit}
            value=""
            onChange={(amount) => {
              onChange([...value, { amount, location: null }]);
            }}
          />
          <ComponentExtensionPoint
            name="articleFormLocation"
            variant="single"
            innerProps={{
              className: classes.input,
              value: "",
              onChange: ({ compartment, loadCarrier }) => {
                if (compartment != null || loadCarrier != null) {
                  onChange([
                    ...value,
                    {
                      amount: "",
                      location: { compartment, loadCarrier },
                    },
                  ]);
                }
              },
            }}
            DefaultComponent={DefaultArticleLocation}
          />
        </div>,
      ]}
    </div>
  );
}

export default withStyles(styles)(InitialStockSelector);
