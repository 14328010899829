import React, { ReactChild } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { InputProps as InputPropsType } from "@material-ui/core/Input";
import { TextFieldProps } from "@material-ui/core/TextField";

export default function UnitTextField({
  unit,
  InputProps,
  endAdornment,
  ...other
}: {
  unit?: string;
  endAdornment?: ReactChild;
  InputProps?: InputPropsType;
} & Partial<TextFieldProps>) {
  return (
    <TextField
      {...other}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            {unit}
            {endAdornment}
          </InputAdornment>
        ),
      }}
    />
  );
}
