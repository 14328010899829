import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { gql } from "graphql.macro";
import { Button, Popover, TextField, withStyles } from "@material-ui/core";
import TowersTable from "./TowersTable";
import Tower from "./Tower";
import { useSnackbar } from "material-ui-snackbar-provider";
import TablePlaceholder from "../../components/TablePlaceholder";
import { Trans, useTranslation } from "react-i18next";

export const GET_TOWERS = gql`
  {
    towers {
      id
      rfidBus {
        id
        serial
      }
      location
      compartments {
        id
      }
    }
  }
`;

const CREATE_TOWER = gql`
  mutation CreateTower($tower: CreateTowerInput!) {
    createTower(tower: $tower) {
      id
      rfidBus {
        id
        serial
      }
      location
      compartments {
        id
      }
    }
  }
`;

const styles = {
  root: {
    padding: "24px 24px 14px",
    flex: 1,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    marginBottom: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  table: {
    flex: 1,
  },
  deltaPopover: {
    padding: 16,
    marginTop: -8,
    marginLeft: 24,
    minWidth: 350,
  },
  input: {
    marginBottom: 16,
  },
  submit: {
    float: "right",
    marginLeft: 24,
  },
};

function TowersContainer({ classes }) {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const [tower, setTower] = React.useState();

  const { data, error, loading } = useQuery(GET_TOWERS, {
    fetchPolicy: "network-only",
    pollInterval: 15000,
  });

  const [createTowerMutation] = useMutation(CREATE_TOWER, {
    update: (cache, { data: { createTower: newTower } }) => {
      const { towers } = cache.readQuery({ query: GET_TOWERS });
      cache.writeQuery({
        query: GET_TOWERS,
        data: { towers: [...towers, newTower] },
      });
    },
  });

  const [newTower, setNewTower] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const handleShowNewTowerForm = useCallback((e) => {
    setAnchorEl(e.currentTarget);
    setNewTower({ location: "", compartments: "" });
  }, []);

  const handleAddTower = useCallback(async () => {
    try {
      const {
        data: { createTower },
      } = await createTowerMutation({
        variables: {
          tower: {
            location: newTower.location,
            compartments: Array(parseInt(newTower.compartments, 10) || 0)
              .fill(null)
              .map((_, i) => ({
                compartment: `${newTower.location}-${`${i + 1}`.padStart(
                  2,
                  "0"
                )}`,
              })),
          },
        },
      });
      snackbar.showMessage(
        t('Der Turm "{{tower}}" wurde angelegt', { tower: newTower.location })
      );
      setNewTower(null);
      setTower(createTower.id);
    } catch (e) {
      console.error(e);
      snackbar.showMessage(t("Der Turm konnte nicht erstellt werden"));
    }
  }, [createTowerMutation, snackbar, newTower, t]);

  return (
    <div className={classes.root}>
      {tower ? (
        <Tower id={tower} onClose={() => setTower(null)} />
      ) : (
        <>
          <div className={classes.header}>
            <Button variant="outlined" onClick={handleShowNewTowerForm}>
              {t("Turm hinzufügen")}
            </Button>
            <Popover
              open={newTower != null}
              onClose={() => setNewTower(null)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              classes={{ paper: classes.deltaPopover }}
            >
              <TextField
                label={t("Bezeichnung des Turms")}
                value={newTower?.location ?? ""}
                onChange={(e) => {
                  const location = e.target.value;
                  setNewTower((t) => ({ ...t, location }));
                }}
                fullWidth
                className={classes.input}
                autoFocus
              />
              <TextField
                label={t("Anzahl der Fächer")}
                value={newTower?.compartments ?? ""}
                onChange={(e) => {
                  const compartments = e.target.value;
                  setNewTower((t) => ({ ...t, compartments }));
                }}
                fullWidth
                type="number"
                inputProps={{ minimum: 1 }}
                className={classes.input}
              />
              <div>
                <Button
                  className={classes.submit}
                  variant="outlined"
                  color="primary"
                  onClick={handleAddTower}
                  disabled={
                    newTower?.location.trim().length === 0 ||
                    (parseInt(newTower?.compartments, 10) || 0) < 0
                  }
                >
                  {t("Hinzufügen")}
                </Button>
              </div>
            </Popover>
          </div>
          <TowersTable
            data={loading ? null : data?.towers ?? []}
            className={classes.table}
            onRowClick={(e, id) => setTower(id)}
            size="small"
            EnhancedTableHeadProps={{ size: "medium" }}
            placeholder={
              <TablePlaceholder>
                {error ? (
                  <>
                    {t(
                      "Beim Laden der Lagerplätze ist ein Fehler aufgetreten."
                    )}
                    <br />
                    <br />
                    {error.graphQLErrors?.length === 0 &&
                      t("Bitte überprüfen Sie Ihre Internetverbindung.")}
                  </>
                ) : (
                  <>
                    {t("Es wurden noch keine Lagerplätze eingerichtet.")}
                    <br />
                    <br />
                    <Trans>
                      Klicken Sie auf <em>Turm hinzufügen</em>, um einen neuen
                      Turm hinzuzufügen.
                    </Trans>
                  </>
                )}
              </TablePlaceholder>
            }
          />
        </>
      )}
    </div>
  );
}

export default withStyles(styles)(TowersContainer);
