import React from "react";
import { gql } from "graphql.macro";
import { addLogItemType } from "../plugins/registry";

addLogItemType({
  id: "stockChange",
  logItemFragment: gql`
    fragment StockChangeLogItemData on StockChangeLogItem {
      note
      stockItem {
        id
        sku
        type
      }
      change {
        delta
        type
      }
      article {
        id
        sku
        type
        name
      }
    }
  `,
  sku: (row) => row.stockItem.sku ?? row.article.sku,
  change: (row, { registry, t }) => {
    const stockInfo = registry.stockItemTypes[row.stockItem.type].stockInfo;
    const delta = stockInfo.formatWithUnit(row.change.delta, row.article, {
      t,
    });
    return row.change.delta > 0 ? `+${delta}` : delta;
  },
  type: (row, { t }) =>
    row.change.type === "initial"
      ? t("Artikel hinzugefügt")
      : t("Bestandsänderung"),
});

addLogItemType({
  id: "stockItemChange",
  logItemFragment: gql`
    fragment StockItemChangeLogItemData on StockItemChangeLogItem {
      note
      stockItem {
        id
        sku
        type
        article {
          id
          sku
          type
          name
        }
      }
      changedFields {
        field
        oldValue
        newValue
      }
    }
  `,
  sku: (row) => row.stockItem.sku ?? row.stockItem.article.sku,
  change: (row, { t, registry }) => {
    const stockItemType = registry.stockItemTypes[row.stockItem.type];
    const { properties } = stockItemType;
    const propertyName = (key) =>
      properties[key] != null
        ? properties[key].displayName(undefined, { t })
        : key;
    const formatValue = (key, value) =>
      properties[key] != null && properties[key].formatValue != null
        ? properties[key].formatValue(value, stockItemType, { t })
        : value;

    return (
      <>
        {row.changedFields
          .filter((field) => !properties[field.field]?.hideInLog)
          .map((changedField) => (
            <React.Fragment key={changedField.field}>
              {propertyName(changedField.field)}:{" "}
              <del>
                {changedField.oldValue != null &&
                  formatValue(changedField.field, changedField.oldValue)}
              </del>{" "}
              <ins>
                {changedField.newValue != null &&
                  formatValue(changedField.field, changedField.newValue)}
              </ins>
              <br />
            </React.Fragment>
          ))}
      </>
    );
  },
  type: (row, { t }) => t("Bestand aktualisiert"),
});
