import React from "react";
import {
  withStyles,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const styles = {
  columns: {
    padding: "0 8px",
    "& > *": {
      width: "calc(50% - 8px)",
    },
  },
  formats: {
    margin: "0 8px",
  },
  bottom: {
    display: "flex",
    flexDirection: "row",
    margin: "0 8px 8px",
    alignItems: "flex-end",
  },
  filename: {
    flex: 1,
    margin: "0 16px 0 0",
  },
  submit: {},
};

function TableExportForm({
  classes,
  children,
  columns,
  onSubmit,
  defaultFilename,
}) {
  const { t } = useTranslation();
  const [selectedColumns, setSelectedColumns] = React.useState([]);
  const [filename, setFilename] = React.useState(`${defaultFilename}.xlsx`);
  const [format, setFormat] = React.useState("xlsx");

  React.useState(() => {
    setSelectedColumns(columns.map((c) => c.id));
  }, [columns, setSelectedColumns]);

  const handleToggleColumn = (id) => (e) => {
    if (e.target.checked) {
      setSelectedColumns([...selectedColumns, id]);
    } else {
      setSelectedColumns(selectedColumns.filter((c) => c !== id));
    }
  };

  return (
    <React.Fragment>
      <div className={classes.columns}>
        {columns
          .filter((c) => !c.export || !c.export.exclude)
          .map((column) => (
            <FormControlLabel
              key={column.id}
              control={
                <Checkbox
                  checked={selectedColumns.includes(column.id)}
                  onChange={handleToggleColumn(column.id)}
                  value={column.id}
                />
              }
              label={column.title}
              style={{ maxWidth: "50%" }}
            />
          ))}
      </div>
      <Divider />
      {children}
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="position"
          name="position"
          row
          className={classes.formats}
          value={format}
          onChange={(e, value) => {
            setFormat(value);
            if (!filename.endsWith(`.${value}`)) {
              setFilename(`${filename.match(/(.+?)(\.[^.]+)?$/)[1]}.${value}`);
            }
          }}
        >
          <FormControlLabel
            value="xlsx"
            control={<Radio />}
            label={t("Excel (.xlsx)")}
            labelPlacement="left"
          />
          <FormControlLabel
            value="csv"
            control={<Radio />}
            label={t("CSV (.csv)")}
            labelPlacement="left"
          />
        </RadioGroup>
      </FormControl>
      <div className={classes.bottom}>
        <TextField
          label={t("Dateiname")}
          placeholder=".csv"
          className={classes.filename}
          margin="dense"
          value={filename}
          onChange={(e) => setFilename(e.target.value)}
          onBlur={() => {
            if (!filename.endsWith(`.${format}`)) {
              setFilename(`${filename}.${format}`);
            }
          }}
        />
        <Button
          className={classes.submit}
          variant="outlined"
          color="primary"
          onClick={() => onSubmit(selectedColumns, filename, format)}
          disabled={selectedColumns.length === 0}
        >
          {t("Speichern")}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles)(TableExportForm);
