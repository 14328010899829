import React from "react";
import { red } from "@material-ui/core/colors";
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import { numberColumn } from "../../../util/enhancedTableUtils";
import {
  aggregatedRowSum,
  currentStockFilter,
  ifStockItem,
} from "../../stock/components/stockTable";
import { formatWithUnit } from "../../../util/numberFormat";
import { compareNaturalBy } from "../../../util/compareNatural";

function getStock(row) {
  return row.initial + row.in - row.out;
}

function getWeightByLength(row, length) {
  return row.article.details.specificWeight * length;
}

export default ({ t }) => [
  {
    id: "name",
    title: t("Artikel", { count: 1 }),
    contentString: (row) => row.article.name,
    compare: compareNaturalBy((row) => row.article.name),
    filter: {
      type: StringSearchFilter,
    },
  },
  {
    id: "sku",
    title: t("Artikelnr."),
    contentString: (row) => row.sku ?? row.article.sku,
    compare: compareNaturalBy((row) => row.sku ?? row.article.sku),
    filter: {
      type: StringSearchFilter,
    },
  },
  numberColumn({
    id: "current",
    title: t("Realer Bestand"),
    getNumber: aggregatedRowSum(getStock),
    content: ifStockItem(
      (row, { nestedOf }) => {
        const current = getStock(row);
        if (
          (nestedOf == null || nestedOf.flattened) && // nur in aggregierten Zeilen anzeigen
          row.article.minimumStock != null &&
          current <= row.article.minimumStock
        ) {
          return (
            <b style={{ color: red[500] }}>{formatWithUnit(current, "m")}</b>
          );
        }
        return <b>{formatWithUnit(current, "m")}</b>;
      },
      (row) => {
        const current = row.stockItems.reduce(
          (sum, stockItem) => sum + getStock(stockItem),
          0
        );
        if (
          row.article.minimumStock != null &&
          current <= row.article.minimumStock
        ) {
          return (
            <b style={{ color: red[500] }}>{formatWithUnit(current, "m")}</b>
          );
        }
        return <b>{formatWithUnit(current, "m")}</b>;
      }
    ),
    unit: { position: "end", name: "m" },
    range: { min: 0, max: null },
    filter: currentStockFilter({ getStock }),
  }),
  numberColumn({
    id: "currentWeight",
    title: t("Gesamtgewicht"),
    getNumber: aggregatedRowSum((row) => getWeightByLength(row, getStock(row))),
    unit: {
      position: "end",
      name: "kg",
    },
    range: {
      min: 0,
      max: null,
    },
  }),
  numberColumn({
    id: "minimumStock",
    title: t("Meldebestand"),
    getNumber: (row) => row.article.minimumStock,
    unit: { position: "end", name: "m" },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "article.length",
    title: t("Länge pro Stück"),
    getNumber: (row) => row.article.details.length,
    unit: {
      position: "end",
      name: "m",
    },
    range: {
      min: 0,
      max: null,
    },
  }),
  {
    id: "article.material",
    title: t("Werkstoff"),
    content: (row) => row.article.details.material,
    contentString: (row) => row.article.details.material,
    filter: {
      type: StringSearchFilter,
    },
  },
  numberColumn({
    id: "details.restLongGoodCount",
    title: t("Restlängen"),
    getNumber: ifStockItem(
      (row) =>
        // show sum only for aggregated rows or single-item rows (SKL-290 would fix this)
        row.parent.stockItems.length < 2
          ? row.details?.restLongGoodCount ?? 0
          : null,
      (aggregatedRow) => aggregatedRow.details?.restLongGoodCount ?? 0
    ),
    unit: { position: "end", name: t("Stk.") },
    range: { min: 0, max: null },
  }),
];
