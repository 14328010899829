import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ApolloProvider } from "react-apollo";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { MuiThemeProvider } from "@material-ui/core/styles";
import AppFrame from "../components/Frame";
import { createApolloClient, getCurrentUser } from "../api";
import { useRegistry } from "../../plugins/registry";
import { SklContextProvider } from "../context/applicationContext";
import theme from "../components/theme";
import DataTableLocaleProvider from "../context/DataTableLocaleProvider";
import EnhancedSnackbar from "../components/EnhancedSnackbar";
import { useTranslation } from "react-i18next";
import { AbilityContext } from "../context/authorization";
import { PureAbility, createMongoAbility } from "@casl/ability";

function AppFrameContainer(props) {
  const { i18n } = useTranslation();
  const registry = useRegistry();
  const [authStatus, setAuthStatus] = useState("unknown");

  const [route, setRoute] = useState({
    route: "Stock",
    selectedPage: "Stock",
  });

  const handleChangeRoute = (route) => {
    const [selectedPage, match] = route.split("/", 2);
    setRoute({ route, selectedPage, match });
  };

  const pages = useMemo(() => {
    const pluginPagesMap = {};
    for (const { route, Component, component } of registry.pages) {
      pluginPagesMap[route] = Component || component;
    }
    return pluginPagesMap;
  }, [registry]);

  const ActivePage = pages[route.selectedPage];

  const AppWrapper = registry.getAppWrapper();

  const [apolloClient] = useState(() =>
    createApolloClient({
      onAuthError: () => {
        setAuthStatus("unauthenticated");
      },
    })
  );

  const [user, setUser] = useState();
  useEffect(() => {
    if (authStatus === "authenticated") {
      let stale = false;
      getCurrentUser()
        .then((user) => {
          if (!stale) {
            setUser(user);
          }
        })
        .catch((e) => {
          console.error("Could not get current user", e);
        });
      return () => {
        stale = true;
      };
    }
  }, [authStatus]);

  const handleChangeAuthStatus = useCallback(
    (authStatus, user) => {
      setAuthStatus(authStatus);
      setUser(user);
      if (user?.preferredLanguage) {
        i18n.changeLanguage(user?.preferredLanguage);
      }
    },
    [i18n]
  );

  const handleLogout = useCallback(() => {
    localStorage.removeItem("skl-access-token");
    setAuthStatus("unauthenticated");
    setUser(null);
  }, []);

  const [ability, setAbility] = useState(new PureAbility([]));

  useEffect(() => {
    if (user) {
      const newAbility = createMongoAbility(
        user.permissions?.map((permission) => ({
          action: permission.action,
          subject: permission.subject,
          conditions: permission.conditions
            ? JSON.parse(
                permission.conditions.replaceAll(
                  /\$\{user\.id\}/g,
                  user.id.toString()
                )
              )
            : undefined,
          inverted: permission.inverted,
          fields: permission.fields,
        })) ?? []
      );
      setAbility(newAbility);

      // select the first selectable menu item
      const features = user?.features ?? {};
      const visibleMenuItems = registry.menuItems.filter(
        ({ hidden, ifCan }) =>
          !hidden?.({ features, pages: registry.pages }) &&
          (!ifCan || newAbility.can(...ifCan))
      );
      visibleMenuItems.sort((a, b) => (a.order || 30) - (b.order || 30));
      const applicableRoute = visibleMenuItems[0];
      if (applicableRoute) {
        const [selectedPage, match] = applicableRoute.route.split("/", 2);
        setRoute({ route: applicableRoute.route, selectedPage, match });
      }
    } else {
      setAbility(new PureAbility([]));
    }
  }, [user, registry]);

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ApolloProvider client={apolloClient}>
          <SnackbarProvider
            snackbarProps={{ autoHideDuration: 10000 }}
            SnackbarComponent={EnhancedSnackbar}
          >
            <DataTableLocaleProvider>
              <AbilityContext.Provider value={ability}>
                <SklContextProvider route={route} user={user}>
                  <AppWrapper>
                    <AppFrame
                      activeRoute={route.route}
                      onChangeRoute={handleChangeRoute}
                      authStatus={authStatus}
                      onAuthStatusChange={handleChangeAuthStatus}
                      onLogout={handleLogout}
                      {...props}
                    >
                      {authStatus === "authenticated" && ActivePage && (
                        <ActivePage match={route.match} />
                      )}
                    </AppFrame>
                  </AppWrapper>
                </SklContextProvider>
              </AbilityContext.Provider>
            </DataTableLocaleProvider>
          </SnackbarProvider>
        </ApolloProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default AppFrameContainer;
