import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import loadPlugins from "./modules/plugins/pluginLoader";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { languages } from "./modules/core/i18n";

Sentry.init({
  dsn: "https://5c50dc695aad45e59e7b39c049dc9ee9@o1042094.ingest.sentry.io/6215743",
  release: process.env.REACT_APP_VERSION || "dev",
  enabled: process.env.NODE_ENV === "production",
});

moment.locale(languages[localStorage.getItem("skl-language")]?.moment || "en");

i18next.use(initReactI18next).init({
  resources: Object.fromEntries(
    Object.keys(languages).map((code) => [
      code,
      {
        translation: require(`./locales/translation/${code}.json`),
        table: require(`./locales/table/${code}.json`),
        changelog: ["de", "en"].includes(code)
          ? require(`./locales/changelog/${code}.json`)
          : {},
        "plugin-led-tower": require(`./locales/plugin-led-tower/${code}.json`), // TODO move this into the plugin
      },
    ])
  ),
  lng: localStorage.getItem("skl-language") || "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  nsSeparator: ":",
});

i18next.on("languageChanged", (code) => {
  const language = languages[code];
  moment.locale(language.moment);
  localStorage.setItem("skl-language", code);
});

(async () => {
  try {
    await loadPlugins();
  } finally {
    ReactDOM.render(<App />, document.getElementById("root"));
  }
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
