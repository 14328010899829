import React, { ChangeEvent, useCallback } from "react";
import UnitTextField from "../../../../components/UnitTextField";
import CoilCalculator, { getSpecificWeight } from "../CoilCalculator";
import { LENGTH_M, roundToDigits } from "../../../../util/numberFormat";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      minWidth: 170,
      alignItems: "center",
    },
    calculator: {
      marginLeft: theme.spacing.unit,
    },
  });

function CoilStockInputField({
  classes,
  value,
  onChange,
  label,
  unit,
  article,
}: {
  value: string;
  onChange: (
    amount: number | string,
    event?: ChangeEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >
  ) => void;
  label: string;
  unit: string;
  article: any;
} & WithStyles<typeof styles>) {
  const handleChange = useCallback(
    (
      e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>
    ) => {
      onChange(e.target.value, e);
    },
    [onChange]
  );

  const handleSubmit = useCallback(
    ({ length }: { length: number }) => {
      onChange(roundToDigits(length / 1000, LENGTH_M));
    },
    [onChange]
  );

  return (
    <div className={classes.root}>
      <UnitTextField
        label={label}
        unit={unit}
        value={value ?? ""}
        onChange={handleChange}
        margin="dense"
        variant="filled"
        fullWidth
        type="number"
      />
      <CoilCalculator
        ButtonProps={{ className: classes.calculator }}
        defaultWidth={article.coil.width}
        defaultThickness={article.coil.thickness}
        defaultSpecificWeight={(
          getSpecificWeight(article.coil.material ?? "") ?? 7.85
        ).toLocaleString()}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default withStyles(styles)(CoilStockInputField);
