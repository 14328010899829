import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { withStyles, TextField } from "@material-ui/core";
import {
  TagTextOutline,
  Buffer,
  RulerSquare,
  NoteOutline,
} from "mdi-material-ui";
import UnitTextField from "../../../../components/UnitTextField";
import ToggleButton from "../../../../components/ToggleButton";
import InitialStockSelector from "../../../articles/components/InitialStockSelector";
import { useTranslation } from "react-i18next";
import { getSpecificWeight } from "../../coil/CoilCalculator";
import { WEIGHT, roundToDigits } from "../../../../util/numberFormat";
import SheetCalculatorButton from "./SheetCalculator";

const styles = (theme) => ({
  root: {},
  fieldset: {
    display: "flex",
    "& > div": {
      flex: 1,
    },
  },
  icon: {
    marginTop: 20,
    marginRight: 16,
    color: theme.palette.action.active,
  },
  row: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: 16,
    },
  },
  measurementModeColumn: {
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
  },
  multiline: {
    "& > div": {
      padding: "24px 12px 6px",
    },
  },
});

function ArticleForm({
  classes,
  form,
  inputProps,
  variant = "new",
  noteColumnTitles,
}) {
  const { t } = useTranslation();
  const usesLengthWidth = form.values.details && !form.values.details.area;
  const [measurementMode, setMeasurementMode] = React.useState(
    variant === "new" || usesLengthWidth ? "lengthWidth" : "area"
  );

  const handleApplyCalculatedValues = useCallback(
    (values) => {
      form.setValues(
        variant === "new"
          ? {
              "sheet.specificWeight": roundToDigits(
                values.specificWeight,
                WEIGHT
              ),
              "sheet.thickness": values.thickness,
            }
          : {
              "details.specificWeight": roundToDigits(
                values.specificWeight,
                WEIGHT
              ),
              "details.thickness": values.thickness,
            }
      );
    },
    [form, variant]
  );

  return (
    <div className={classes.root}>
      <div className={classes.fieldset}>
        <TagTextOutline className={classes.icon} />
        <div>
          <TextField
            label={t("Artikelnummer")}
            margin="dense"
            variant="filled"
            fullWidth
            autoFocus
            {...inputProps("sku")}
          />
          <TextField
            label={t("Bezeichnung")}
            className={classes.textField}
            margin="dense"
            variant="filled"
            fullWidth
            {...inputProps("name")}
          />
          <TextField
            label={t("Werkstoff")}
            margin="dense"
            variant="filled"
            fullWidth
            {...inputProps(
              variant === "new" ? "sheet.material" : "details.material"
            )}
          />
        </div>
      </div>
      <div className={classes.fieldset}>
        <RulerSquare className={classes.icon} />
        <div>
          <div className={classes.row}>
            <div className={classes.measurementModeColumn}>
              <ToggleButton
                value={measurementMode === "lengthWidth"}
                onClick={() => {
                  setMeasurementMode("lengthWidth");
                  form.setValues({
                    [variant === "new" ? "sheet.area" : "details.area"]: null,
                  });
                }}
                size="tiny"
              >
                {t("Abmessungen")}
              </ToggleButton>
              <ToggleButton
                value={measurementMode === "area"}
                onClick={() => {
                  setMeasurementMode("area");
                  form.setValues({
                    [variant === "new" ? "sheet.width" : "details.width"]: null,
                    [variant === "new" ? "sheet.length" : "details.length"]:
                      null,
                  });
                }}
                size="tiny"
              >
                {t("Fläche")}
              </ToggleButton>
            </div>
            {measurementMode === "lengthWidth" && (
              <UnitTextField
                label={t("Länge")}
                margin="dense"
                variant="filled"
                fullWidth
                unit="mm"
                type="number"
                {...inputProps(
                  variant === "new" ? "sheet.length" : "details.length"
                )}
              />
            )}
            {measurementMode === "lengthWidth" && (
              <UnitTextField
                label={t("Breite")}
                margin="dense"
                variant="filled"
                fullWidth
                unit="mm"
                type="number"
                {...inputProps(
                  variant === "new" ? "sheet.width" : "details.width"
                )}
              />
            )}
            {measurementMode === "area" && (
              <UnitTextField
                label={t("Fläche")}
                margin="dense"
                variant="filled"
                fullWidth
                unit="m²"
                type="number"
                {...inputProps(
                  variant === "new" ? "sheet.area" : "details.area"
                )}
              />
            )}
          </div>
          <div className={classes.row}>
            <UnitTextField
              label={t("Dicke")}
              margin="dense"
              variant="filled"
              fullWidth
              unit="mm"
              type="number"
              {...inputProps(
                variant === "new" ? "sheet.thickness" : "details.thickness"
              )}
            />
            <UnitTextField
              label={t("Gewicht")}
              margin="dense"
              variant="filled"
              fullWidth
              unit="kg/m²"
              type="number"
              {...inputProps(
                variant === "new"
                  ? "sheet.specificWeight"
                  : "details.specificWeight"
              )}
            />
            <SheetCalculatorButton
              ButtonProps={{
                style: { alignSelf: "center", marginLeft: -8 },
              }}
              defaultThickness={
                variant === "new"
                  ? form.values.sheet.thickness
                  : form.values.details.thickness
              }
              defaultSpecificWeight={
                getSpecificWeight(
                  variant === "new"
                    ? form.values.sheet.material ?? ""
                    : form.values.details.material ?? ""
                ) ?? 7.85
              }
              measurementMode={measurementMode}
              onSubmit={handleApplyCalculatedValues}
            />
          </div>
        </div>
      </div>
      <div className={classes.fieldset}>
        <Buffer className={classes.icon} />
        <div>
          {variant === "new" && (
            <InitialStockSelector
              value={form.values.initialStock}
              onChange={(initialStock) => {
                form.setValues({ initialStock });
              }}
              unit={t("Stk.")}
              articleType="sheet"
            />
          )}
          <UnitTextField
            label={t("Meldebestand")}
            margin="dense"
            variant="filled"
            fullWidth
            unit={t("Stk.")}
            type="number"
            {...inputProps("minimumStock")}
          />
        </div>
      </div>
      {variant === "new" && (
        <div className={classes.fieldset}>
          <NoteOutline className={classes.icon} />
          <div>
            {noteColumnTitles.map((title, i) => (
              <TextField
                key={`note${i}`}
                label={title}
                margin="dense"
                variant="filled"
                fullWidth
                multiline
                className={classes.multiline}
                {...inputProps(i === 0 ? "note" : `note${i + 1}`)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

ArticleForm.propTypes = {
  variant: PropTypes.oneOf(["new", "edit"]),
  noteColumnTitles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default withStyles(styles)(ArticleForm);
