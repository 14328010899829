import { useEffect, useRef } from "react";

export const useThrottledEffect = (callback, delay, deps = []) => {
  const lastRan = useRef({ date: Date.now() });

  useEffect(() => {
    const handler = setTimeout(function () {
      if (Date.now() - lastRan.current.date >= delay) {
        if (lastRan.current.cleanup) {
          lastRan.current.cleanup();
          lastRan.current.cleanup = null;
        }
        const cleanup = callback();
        lastRan.current = { date: Date.now(), cleanup };
      }
    }, delay - (Date.now() - lastRan.current.date));

    return () => {
      clearTimeout(handler);
      if (lastRan.current.cleanup) {
        lastRan.current.cleanup();
        lastRan.current.cleanup = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...deps]);
};

export default useThrottledEffect;
