import React from "react";
import {
  addArticleType,
  changeArticleType,
  addArticleAction,
  addArticleToolbarAction,
  addStockItemType,
  changeStockItemType,
  addStockAction,
  addStockToolbarAction,
  addStockTableColumns,
  addPageAction,
  addPageToolbarAction,
  addLogTableModifier,
  addLogItemType,
  addAppWrapper,
  addComponentToExtensionPoint,
  addMenuItem,
  addSettingsMenuItem,
  addPage,
  useRegistry,
  articleProperties,
} from "./registry";
import { numberColumn } from "../../util/enhancedTableUtils";
import { formatWithUnit } from "../../util/numberFormat";
import useLocalStorageState from "../../util/useLocalStorageState";
import TablePlaceholder from "../../components/TablePlaceholder";
import ButtonDialogTitle from "../../components/ButtonDialogTitle";
import TooltipIconButton from "../../components/TooltipIconButton";
import UnitTextField from "../../components/UnitTextField";
import InitialStockSelector from "../articles/components/InitialStockSelector";
import {
  aggregatedRowSum,
  compartmentColumn,
  currentStockFilter,
  ifStockItem,
  loadCarrierColumn,
  towerColumn,
} from "../stock/components/stockTable";
import BarcodeScanner from "../../components/BarcodeScanner";
import { serverUrl, getAuthorizationHeader } from "../core/api";
import {
  commonCreateArticleFields,
  commonEditArticleFields,
} from "../articles/components/articleForm";

import {
  useRoute,
  useArticleType,
  useEmployee,
} from "../core/context/applicationContext";
import { compareNaturalBy } from "../../util/compareNatural";

const QrCodeReader = React.lazy(() => import("../../components/QrCodeReader"));

export default {
  addArticleType,
  changeArticleType,
  addArticleAction,
  addArticleToolbarAction,
  addStockItemType,
  changeStockItemType,
  addStockAction,
  addStockToolbarAction,
  addStockTableColumns,
  addPageAction,
  addPageToolbarAction,
  addLogTableModifier,
  addLogItemType,
  addAppWrapper,
  addComponentToExtensionPoint,
  addMenuItem,
  addSettingsMenuItem,
  addPage,
};

const stockTableUtils = {
  aggregatedRowSum,
  compartmentColumn,
  currentStockFilter,
  ifStockItem,
  loadCarrierColumn,
  towerColumn,
};

const tableUtils = {
  compareNaturalBy,
  numberColumn,
};

export const otherExports = {
  useRoute,
  useArticleType,
  useEmployee,
  /** @deprecated use tableUtils.numberColumn instead */
  numberColumn,
  useRegistry,
  getServerUrl: () => serverUrl,
  getAuthorizationHeader,

  stockTableUtils,
  tableUtils,

  commonCreateArticleFields,
  commonEditArticleFields,
  articleProperties,

  formatWithUnit,
  useLocalStorageState,

  TablePlaceholder,
  ButtonDialogTitle,
  TooltipIconButton,
  BarcodeScanner,
  QrCodeReader,
  UnitTextField,
  InitialStockSelector,

  version: process.env.REACT_APP_VERSION,
};
