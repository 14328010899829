import { getDeep } from "./deepAccess";

export function prepareSmartSearch(search: string) {
  const words =
    search != null && search.trim().length > 0 ? search.split(/\s+/) : [];

  const rawFilters = words.filter((word) => /^[a-zA-Z0-9.]+:/.test(word));
  const filters = rawFilters
    .map((filter) => {
      const match = /^([a-zA-Z0-9.]+):(.+)$/.exec(filter);
      if (match) {
        const expectedValueLowerCase = match[2].toLowerCase();
        return {
          path: match[1],
          expectedValueLowerCase,
          isMatch: (row: any) =>
            getDeep(row, match[1])?.toString().toLowerCase() ===
            expectedValueLowerCase,
        };
      }
      return undefined;
    })
    .filter((filter) => !!filter) as Array<{
    path: string;
    expectedValueLowerCase: string;
  }>;

  const searchTerms = words.filter((word) => rawFilters.indexOf(word) < 0);

  return { filters, searchTerms };
}
