import React, { useMemo } from "react";
import EnhancedTable from "@wa/werkstoff-data-table";
import withLocalStorageConfig from "@wa/werkstoff-data-table/lib/withLocalStorageConfig";
import withVerticalScroll from "@wa/werkstoff-data-table/lib/withVerticalScroll";
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import { useRegistry } from "../plugins/registry";
import { useTranslation } from "react-i18next";

const RawLoadCarriersTable = withLocalStorageConfig("storekeeper-loadCarriers")(
  withVerticalScroll()(EnhancedTable)
);

const getColumns = (registry, t) => [
  {
    id: "name",
    title: t("Ladungsträger"),
    filter: {
      type: StringSearchFilter,
    },
  },
  {
    id: "stockItems",
    title: t("Inhalt"),
    filter: {
      type: StringSearchFilter,
    },
    contentString: (row) =>
      row.stockItems.map((item) => item.sku ?? item.article.sku).join(", "),
  },
  {
    id: "rfidTag",
    title: t("RFID-Tags"),
    content: (row) => row.rfidTags?.map((tag) => tag.rfidTag).join(", ") ?? "",
    filter: {
      type: StringSearchFilter,
    },
  },
  {
    id: "tower",
    title: t("Turm"),
    filter: {
      type: StringSearchFilter,
    },
    contentString: (row) => row.compartment?.tower?.location ?? "",
  },
  {
    id: "compartment",
    title: t("Fach"),
    filter: {
      type: StringSearchFilter,
    },
    contentString: (row) => row.compartment?.compartment ?? "",
  },
];

export default function LoadCarriersTable(props) {
  const { t } = useTranslation();
  const registry = useRegistry();
  const columns = useMemo(() => getColumns(registry, t), [registry, t]);

  return (
    <RawLoadCarriersTable
      rowId={(row) => row.id}
      columns={columns}
      {...props}
    />
  );
}
