import { withStyles } from "@material-ui/core";
import React from "react";

const styles = {
  root: {
    padding: 24,
    maxWidth: 700,
    margin: "0 auto",
  },
};

function TablePlaceholder({ classes, ...props }) {
  return <div {...props} className={classes.root} />;
}

export default withStyles(styles)(TablePlaceholder);
