import React from "react";
import PropTypes from "prop-types";
import { useRegistry } from "./registry";

let cache = [];

export default function ComponentExtensionPoint({
  name,
  InnerComponent,
  innerProps,
  variant,
  DefaultComponent
}) {
  const registry = useRegistry();
  React.useEffect(() => {
    cache = {};
  }, [registry]);

  if (variant === "nested") {
    if (!cache[name]) {
      let OuterComponent = InnerComponent;
      for (const Component of registry.componentExtensionsPoints[name] ?? []) {
        OuterComponent = Component(OuterComponent);
      }
      cache[name] = OuterComponent;
    }
    const Component = cache[name];
    return <Component {...innerProps} />;
  }
  if (variant === "list") {
    return (
      <>
        {(registry.componentExtensionsPoints[name] ?? []).map(
          (Component, i) => (
            <Component key={i} {...innerProps} />
          )
        )}
      </>
    );
  }
  if (variant === "single") {
    const Component =
      registry.componentExtensionsPoints[name]?.[0] ?? DefaultComponent;
    return <Component {...innerProps} />;
  }
  throw new Error(`Unknown variant: ${variant}`);
}

ComponentExtensionPoint.propTypes = {
  variant: PropTypes.oneOf(["list", "nested", "single"]).isRequired,
  name: PropTypes.string.isRequired,
  InnerComponent: PropTypes.elementType,
  innerProps: PropTypes.object,
  DefaultComponent: PropTypes.elementType
};
