import React from "react";
import { withStyles, Typography, Button, TextField } from "@material-ui/core";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useMutation } from "react-apollo";
import { gql } from "graphql.macro";
import { useEmployee } from "../../core/context/applicationContext";
import { GET_STOCK } from "./Stock";
import ComponentExtensionPoint from "../../plugins/ComponentExtensionPoint";
import DefaultCompartmentSelector from "../../articles/components/DefaultCompartmentSelector";
import { useTranslation } from "react-i18next";

const styles = {
  fields: {
    margin: "8px 0 16px",
    display: "flex",
    "& > *": {
      width: "100%",
    },
  },
  noteField: {
    margin: "4px 0 16px",
    width: "100%",
    "& textarea": {
      minHeight: 20, // fix scroll bar showing if empty
    },
  },
  bottom: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    "& > *": {
      flex: 1,
    },
  },
  checkbox: { padding: "6px 6px 6px 12px" },
  submit: {
    flex: "none",
  },
  footnote: {
    margin: "4px 0 -4px",
  },
};

function MoveLoadCarrierForm({ classes, item, onClose }) {
  const { t } = useTranslation();
  const [employee] = useEmployee();
  const snackbar = useSnackbar();

  const [updateLoadCarrier] = useMutation(
    gql`
      mutation UpdateLoadCarrier(
        $id: ID!
        $compartmentId: ID # TODO add employee and note for logging
      ) {
        updateLoadCarrier(id: $id, compartmentId: $compartmentId) {
          id
          compartment {
            id
            compartment
            tower {
              id
              location
            }
          }
        }
      }
    `,
    {
      refetchQueries: () => [
        {
          query: GET_STOCK,
          variables: { type: item.type, articleType: item.article.type },
        },
      ],
    }
  );

  const [compartment, setCompartment] = React.useState(
    item.loadCarrier.compartment?.id
  );
  const [note, setNote] = React.useState("");

  const handleSubmit = async () => {
    const oldItem = item;
    try {
      await updateLoadCarrier({
        variables: {
          id: item.loadCarrier.id,
          compartmentId: compartment,
          employee,
          note,
        },
      });
      snackbar.showMessage(
        t("Der Ladungsträger wurde aktualisiert"),
        t("Rückgängig"),
        async () => {
          try {
            await updateLoadCarrier({
              variables: {
                id: item.loadCarrier.id,
                compartmentId: oldItem.loadCarrier?.compartment?.id ?? null,
                employee,
              },
            });
          } catch (e) {
            console.error(e);
            snackbar.showMessage(
              t(
                "Das Bearbeiten des Ladungsträgers konnte nicht rückgängig gemacht werden"
              )
            );
          }
        }
      );
      onClose();
    } catch (e) {
      console.error(e);
      snackbar.showMessage(
        t("Der Ladungsträger konnte nicht aktualisiert werden")
      );
    }
  };

  return (
    <React.Fragment>
      <Typography variant="body1">{t("Ladungsträger bewegen")}</Typography>
      <Typography variant="caption">{item.loadCarrier.name}</Typography>
      <div className={classes.fields}>
        <ComponentExtensionPoint
          name="compartmentSelector"
          variant="single"
          innerProps={{
            value: compartment,
            variant: "standard",
            onChange: setCompartment,
            fullWidth: true,
          }}
          DefaultComponent={DefaultCompartmentSelector}
        />
      </div>
      <TextField
        className={classes.noteField}
        label={t("Bemerkung")}
        InputLabelProps={{ shrink: true }}
        placeholder={t("(optional)")}
        fullWidth
        multiline
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      <div className={classes.bottom}>
        <div className={classes.flex} />
        <Button
          className={classes.submit}
          variant="outlined"
          color="primary"
          onClick={handleSubmit}
        >
          {t("Übernehmen")}
        </Button>
      </div>
      <Typography variant="caption" className={classes.footnote}>
        {t(
          "Die Lagerorte aller Artikel auf diesem Ladungsträger werden automatisch aktualisiert."
        )}
      </Typography>
    </React.Fragment>
  );
}

export default withStyles(styles)(MoveLoadCarrierForm);
