import React from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "mdi-material-ui/Menu";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  flex: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyItems: "space-between"
  },
  drawerButton: {
    marginLeft: -12,
    marginRight: 12
  },
  rightButton: {
    marginLeft: 12,
    marginRight: -12
  }
};

function AppBar({
  classes,
  title,
  onButtonClick,
  children,
  leftIcon: LeftIcon = MenuIcon,
  rightIconButton: RightIconButton
}) {
  return (
    <MuiAppBar position="static">
      <Toolbar>
        {LeftIcon && (
          <IconButton
            onClick={onButtonClick}
            color="inherit"
            className={classes.drawerButton}
            disabled={!onButtonClick}
          >
            {LeftIcon}
          </IconButton>
        )}
        <div className={classes.flex}>
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>
          {children}
        </div>
        {RightIconButton &&
          React.cloneElement(RightIconButton, {
            color: "inherit",
            className: classes.rightButton
          })}
      </Toolbar>
    </MuiAppBar>
  );
}

export default withStyles(styles)(AppBar);
