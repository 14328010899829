import React from "react";
import { blueGrey, red, green } from "@material-ui/core/colors";

function getScale(canvas, { width, length }) {
  return Math.min((canvas.width - 40) / width, (canvas.height - 40) / length);
}

function paintCanvas(
  canvas,
  ctx,
  { width, length, grid, selectedBoxes, newBox }
) {
  const scale = getScale(canvas, { width, length });
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.save();
  ctx.translate(
    0.5 * (canvas.width - width * scale - 40),
    0.5 * (canvas.height - length * scale - 40)
  );
  ctx.save();
  ctx.scale(scale, scale);

  // draw the sheet
  ctx.rect(0, 0, width, length);
  ctx.fillStyle = blueGrey[400];
  ctx.fill();

  // draw selected boxes
  for (const box of selectedBoxes) {
    ctx.clearRect(box.x1, box.y1, box.x2 - box.x1, box.y2 - box.y1);
  }
  if (newBox != null) {
    ctx.beginPath();
    ctx.rect(0, 0, width, length);
    ctx.clip();
    ctx.beginPath();
    ctx.rect(
      Math.min(newBox.x1, newBox.x2),
      Math.min(newBox.y1, newBox.y2),
      Math.abs(newBox.x2 - newBox.x1),
      Math.abs(newBox.y2 - newBox.y1)
    );
    ctx.fillStyle = newBox.remove ? red[500] : green[500];
    ctx.fill();
  }

  ctx.restore();

  // draw the grid
  ctx.lineWidth = 1;
  ctx.strokeStyle = "rgba(0,0,0,0.25)";
  ctx.beginPath();
  for (let x = 0; x <= width; x += grid) {
    ctx.moveTo(x * scale, 0);
    ctx.lineTo(x * scale, length * scale);
  }
  for (let y = 0; y <= length; y += grid) {
    ctx.moveTo(0, y * scale);
    ctx.lineTo(width * scale, y * scale);
  }
  ctx.stroke();
  ctx.strokeStyle = "black";
  ctx.beginPath();
  ctx.strokeRect(0, 0, Math.floor(width * scale), length * scale);

  // draw width and length
  ctx.font = "16px Roboto";
  ctx.fillStyle = "black";
  const wTextWidth = ctx.measureText(`${width.toLocaleString()} mm`).width;
  const lTextWidth = ctx.measureText(`${length.toLocaleString()} mm`).width;
  if (wTextWidth + 20 < width * scale + 10) {
    ctx.moveTo(0, length * scale + 15);
    ctx.lineTo((width * scale - wTextWidth) / 2 - 10, length * scale + 15);
    ctx.moveTo((width * scale + wTextWidth) / 2 + 10, length * scale + 15);
    ctx.lineTo(width * scale, length * scale + 15);
    ctx.stroke();
  }
  ctx.fillText(
    `${width.toLocaleString()} mm`,
    Math.max(0, (width * scale - wTextWidth) / 2),
    length * scale + 20
  );
  ctx.save();
  ctx.translate(width * scale + 20, 0);
  ctx.rotate(Math.PI / 2);
  if (lTextWidth + 20 < length * scale + 10) {
    ctx.moveTo(0, 5);
    ctx.lineTo((length * scale - lTextWidth) / 2 - 10, 5);
    ctx.moveTo((length * scale + lTextWidth) / 2 + 10, 5);
    ctx.lineTo(length * scale, 5);
    ctx.stroke();
  }
  ctx.fillText(
    `${length.toLocaleString()} mm`,
    Math.max(0, (length * scale - lTextWidth) / 2),
    10
  );
  ctx.restore();

  if (newBox != null) {
    const tooltip = `${Math.abs(
      newBox.x1 - newBox.x2
    ).toLocaleString()} x ${Math.abs(newBox.y1 - newBox.y2).toLocaleString()}`;
    ctx.font = "bold 12px Roboto";
    ctx.fillStyle = "rgba(0,0,0,0.5)";
    ctx.fillRect(
      Math.max(newBox.x1, newBox.x2) * scale + 5,
      Math.max(newBox.y1, newBox.y2) * scale - 16,
      ctx.measureText(tooltip).width + 4,
      16
    );
    ctx.fillStyle = "white";
    ctx.fillText(
      tooltip,
      Math.max(newBox.x1, newBox.x2) * scale + 7,
      Math.max(newBox.y1, newBox.y2) * scale - 3
    );
  }

  ctx.restore();
}

function rectangleIntersect(a, b) {
  const x1 = Math.max(Math.min(a.x1, a.x2), Math.min(b.x1, b.x2));
  const y1 = Math.max(Math.min(a.y1, a.y2), Math.min(b.y1, b.y2));
  const x2 = Math.min(Math.max(a.x1, a.x2), Math.max(b.x1, b.x2));
  const y2 = Math.min(Math.max(a.y1, a.y2), Math.max(b.y1, b.y2));
  if (x1 < x2 && y1 < y2) {
    return { x1, y1, x2, y2 };
  }
}

function rectangleContains(a, b) {
  return a.x1 <= b.x1 && a.x2 >= b.x2 && a.y1 <= b.y1 && a.y2 >= b.y2;
}

function* rectangleDifference(rectangles, b) {
  for (const a of rectangles) {
    const inter = rectangleIntersect(a, b);
    if (inter == null) {
      yield a;
    } else {
      if (inter.x1 > a.x1) {
        yield { x1: a.x1, y1: a.y1, x2: inter.x1, y2: a.y2 };
      }
      if (inter.y1 > a.y1) {
        yield { x1: inter.x1, y1: a.y1, x2: inter.x2, y2: inter.y1 };
      }
      if (a.y2 > inter.y2) {
        yield { x1: inter.x1, y1: inter.y2, x2: inter.x2, y2: a.y2 };
      }
      if (a.x2 > inter.x2) {
        yield { x1: inter.x2, y1: a.y1, x2: a.x2, y2: a.y2 };
      }
    }
  }
}

/** Get all rects that are not contained in any other rectangle. */
function* removeObsoleteRects(rects) {
  for (const rect of rects) {
    if (!rects.some((r) => r !== rect && rectangleContains(r, rect))) {
      // there is no rectangle that contains `rect`
      yield rect;
    }
  }
}

export default function ShapeSelector({
  grid,
  sheetLength,
  sheetWidth,
  width,
  height,
  value,
  onChange,
}) {
  const [boxes, setBoxes] = React.useState(value || []);
  const [newBox, setNewBox] = React.useState();

  React.useEffect(() => {
    if (canvas.current != null && ctx.current != null) {
      paintCanvas(canvas.current, ctx.current, {
        width: sheetWidth,
        length: sheetLength,
        grid,
        selectedBoxes: boxes,
        newBox,
      });
    }
  }, [sheetLength, sheetWidth, boxes, grid, newBox]);

  React.useEffect(() => {
    if (onChange) {
      onChange(boxes);
    }
  }, [boxes, onChange]);

  const canvas = React.useRef();
  const ctx = React.useRef();
  const setCanvasRef = (ref) => {
    canvas.current = ref;
    if (ref != null) {
      ctx.current = ref.getContext("2d");
    } else {
      ctx.current = null;
    }
  };

  const getBoxAt = (e) => {
    const bounds = e.target.getBoundingClientRect();
    const scale = getScale(canvas.current, {
      width: sheetWidth,
      length: sheetLength,
    });
    const x =
      e.clientX -
      bounds.left -
      0.5 * (canvas.current.width - sheetWidth * scale - 40);
    const y =
      e.clientY -
      bounds.top -
      0.5 * (canvas.current.height - sheetLength * scale - 40);

    return {
      x: Math.min(
        Math.floor(Math.round(x / scale) / grid) * grid,
        Math.ceil(sheetWidth / grid - 1) * grid
      ),
      y: Math.min(
        Math.floor(Math.round(y / scale) / grid) * grid,
        Math.ceil(sheetLength / grid - 1) * grid
      ),
    };
  };

  const handleStartNewBox = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { x, y } = getBoxAt(e);
    setNewBox({
      x1: x,
      y1: y,
      x2: x + grid,
      y2: y + grid,
      start: { x, y },
      remove: e.button === 0 && !e.shiftKey,
    });
  };

  const handleChangeNewBox = (e) => {
    if (newBox == null) return;
    let { x, y } = getBoxAt(e);
    x += grid;
    y += grid;
    setNewBox({
      ...newBox,
      x1: x > newBox.start.x ? newBox.start.x : x - grid,
      y1: y > newBox.start.y ? newBox.start.y : y - grid,
      x2: x > newBox.start.x ? x : newBox.start.x + grid,
      y2: y > newBox.start.y ? y : newBox.start.y + grid,
    });
  };

  const handleAddNewBox = (e) => {
    if (newBox == null) return;
    const box = {
      x1: newBox.x1,
      y1: newBox.y1,
      x2: newBox.x2,
      y2: newBox.y2,
    };
    if (newBox.remove) {
      setBoxes([...removeObsoleteRects([...boxes, box])]);
    } else {
      setBoxes([...removeObsoleteRects([...rectangleDifference(boxes, box)])]);
    }
    setNewBox(null);
  };

  return (
    <canvas
      width={width}
      height={height}
      ref={setCanvasRef}
      onMouseDown={onChange ? handleStartNewBox : undefined}
      onMouseMove={onChange ? handleChangeNewBox : undefined}
      onMouseUp={onChange ? handleAddNewBox : undefined}
      onContextMenu={onChange ? (e) => e.preventDefault() : undefined}
    />
  );
}
