import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import { numberColumn } from "../../../util/enhancedTableUtils";

export default ({ t }) => [
  {
    id: "name",
    title: t("Artikel"),
    filter: {
      type: StringSearchFilter,
    },
  },
  {
    id: "sku",
    title: t("Artikelnr."),
    filter: {
      type: StringSearchFilter,
    },
  },
  {
    id: "details.material",
    title: t("Werkstoff"),
    content: (row) => row.details.material,
    contentString: (row) => row.details.material,
    filter: {
      type: StringSearchFilter,
    },
  },
  numberColumn({
    id: "details.specificWeight",
    title: t("Gewicht"),
    getNumber: (row) => row.details.specificWeight,
    unit: { position: "end", name: "kg/m" },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "details.length",
    title: t("Länge pro Stück"),
    getNumber: (row) => row.details.length,
    unit: { position: "end", name: "m" },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "minimumStock",
    title: t("Meldebestand"),
    getNumber: (row) => row.minimumStock,
    unit: { position: "end", name: "m" },
    range: { min: 0, max: null },
  }),
];
