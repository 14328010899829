import React from "react";
import {
  Buffer,
  History,
  NfcVariant,
  TagTextOutline,
  TrayFull,
  Warehouse,
} from "mdi-material-ui";
import { addMenuItem, addPage, addSettingsMenuItem } from "../plugins/registry";
import Articles from "../articles";
import Stock from "../stock";
import Log from "../log";
import Towers from "../towers/Towers";
import LoadCarriers from "../loadCarriers/LoadCarriers";

addMenuItem({
  name: ({ t }) => t("Bestand"),
  icon: <Buffer />,
  route: "Stock",
  ifCan: ["read", "Stock"],
  order: 10,
});

addMenuItem({
  name: ({ t }) => t("Artikel"),
  icon: <TagTextOutline />,
  route: "Articles",
  ifCan: ["read", "Article"],
  order: 20,
});

addMenuItem({
  name: ({ t }) => t("Historie"),
  icon: <History />,
  route: "Log",
  ifCan: ["read", "Log"],
  order: 90,
});

addSettingsMenuItem({
  name: ({ t }) => t("Lagerplätze"),
  icon: <Warehouse />,
  route: "towers",
});

addSettingsMenuItem({
  name: ({ t }) => t("Ladungsträger", { context: "plural" }),
  icon: <TrayFull />,
  route: "loadCarriers",
  hidden: ({ features }) => !features.loadCarriers,
});

addSettingsMenuItem({
  name: ({ t }) => t("RFID-Tags"),
  icon: <NfcVariant />,
  route: "rfidTags",
  disabled: ({ pages }) => !pages.some((p) => p.route === "rfidTags"),
});

addPage({
  route: "Articles",
  Component: Articles,
});

addPage({
  route: "Log",
  Component: Log,
});

addPage({
  route: "Stock",
  Component: Stock,
});

addPage({
  route: "towers",
  Component: Towers,
});

addPage({
  route: "loadCarriers",
  Component: LoadCarriers,
});
