import React from "react";
import { withStyles, Typography, Button, TextField } from "@material-ui/core";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useMutation } from "react-apollo";
import { gql } from "graphql.macro";
import { useRegistry } from "../../plugins/registry";
import {
  useEmployee,
  useFeatures,
} from "../../core/context/applicationContext";
import { articleFragment, GET_STOCK } from "./Stock";
import ComponentExtensionPoint from "../../plugins/ComponentExtensionPoint";
import DefaultArticleLocation from "../../articles/components/DefaultArticleLocation";
import { useTranslation } from "react-i18next";

const styles = {
  fields: {
    maxWidth: 420,
    margin: "8px 0 16px",
    display: "flex",
    "& > *": {
      flex: 1,
    },
    "& > *:first-child:not(:last-child)": {
      marginRight: 8,
    },
    "& > *:last-child:not(:first-child)": {
      marginLeft: 8,
    },
  },
  noteField: {
    margin: "4px 0 16px",
    width: "100%",
    "& textarea": {
      minHeight: 20, // fix scroll bar showing if empty
    },
  },
  bottom: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    "& > *": {
      flex: 1,
    },
  },
  checkbox: { padding: "6px 6px 6px 12px" },
  submit: {
    flex: "none",
  },
};

function ChangeLocationForm({ classes, item, onClose }) {
  const { t } = useTranslation();
  const registry = useRegistry();
  const [employee] = useEmployee();
  const features = useFeatures();
  const snackbar = useSnackbar();
  const itemType = registry.stockItemTypes[item.type];

  const [updateItem] = useMutation(
    gql`
      mutation UpdateItem(
        $item: UpdateItemInput!
        $employee: String
        $note: String
      ) {
        updateItem(item: $item, employee: $employee, note: $note) {
          id
          article {
            ...StockArticle
          }
        }
      }
      ${articleFragment}
    `,
    {
      refetchQueries: () => [
        {
          query: GET_STOCK,
          variables: { type: item.type, articleType: item.article.type },
        },
      ],
    }
  );

  const [loadCarrier, setLoadCarrier] = React.useState(item.loadCarrier?.id);
  const [compartment, setCompartment] = React.useState(item.compartment?.id);
  const [note, setNote] = React.useState("");

  const handleSubmit = async () => {
    const oldItem = item;
    try {
      await updateItem({
        variables: {
          item: {
            id: item.id,
            loadCarrierId: loadCarrier,
            compartmentId: compartment,
          },
          employee,
          note,
        },
      });
      snackbar.showMessage(
        t("Der Lagerort wurde aktualisiert"),
        t("Rückgängig"),
        async () => {
          try {
            await updateItem({
              variables: {
                item: {
                  id: oldItem.id,
                  loadCarrierId: oldItem.loadCarrier?.id ?? null,
                  compartmentId: oldItem.compartment?.id ?? null,
                },
                employee,
              },
            });
          } catch (e) {
            console.error(e);
            snackbar.showMessage(
              t(
                "Das Bearbeiten des Lagerortes konnte nicht rückgängig gemacht werden"
              )
            );
          }
        }
      );
      onClose();
    } catch (e) {
      console.error(e);
      snackbar.showMessage(t("Der Lagerort konnte nicht aktualisiert werden"));
    }
  };

  return (
    <React.Fragment>
      <Typography variant="body1">
        {features.loadCarriers
          ? t("Lagerort/Ladungsträger ändern")
          : t("Lagerort ändern")}
      </Typography>
      <Typography variant="caption">
        {itemType.displayName(1, { t })} &middot; {item.article.name} &middot;{" "}
        {item.sku ?? item.article.sku}
      </Typography>
      <div className={classes.fields}>
        <ComponentExtensionPoint
          name="articleFormLocation"
          variant="single"
          innerProps={{
            className: classes.input,
            value: { compartment, loadCarrier },
            onChange: ({ compartment, loadCarrier }) => {
              setCompartment(compartment);
              setLoadCarrier(loadCarrier);
            },
            variant: "standard",
          }}
          DefaultComponent={DefaultArticleLocation}
        />
      </div>
      <TextField
        className={classes.noteField}
        label={t("Bemerkung")}
        InputLabelProps={{ shrink: true }}
        placeholder={t("(optional)")}
        fullWidth
        multiline
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      <div className={classes.bottom}>
        <div className={classes.flex} />
        <Button
          className={classes.submit}
          variant="outlined"
          color="primary"
          onClick={handleSubmit}
        >
          {t("Übernehmen")}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles)(ChangeLocationForm);
