import React from "react";
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import { numberColumn } from "../../../util/enhancedTableUtils";
import ShapePreview from "./ShapePreview";
import {
  aggregatedRowSum,
  ifStockItem,
} from "../../stock/components/stockTable";
import { compareNaturalBy } from "../../../util/compareNatural";

function getStock(row) {
  return row.initial + row.in - row.out;
}

export default ({ t }) => [
  {
    id: "name",
    title: t("Artikel", { count: 1 }),
    contentString: (row) => row.article.name,
    compare: compareNaturalBy((row) => row.article.name),
    filter: {
      type: StringSearchFilter,
    },
  },
  {
    id: "sku",
    title: t("Artikelnr."),
    contentString: (row) => row.sku ?? row.article.sku,
    compare: compareNaturalBy((row) => row.sku ?? row.article.sku),
    filter: {
      type: StringSearchFilter,
    },
  },
  numberColumn({
    id: "current",
    title: t("Realer Bestand"),
    content: ifStockItem(
      (row) => (
        <b>
          {getStock(row).toLocaleString()} {t("Stk.")}
        </b>
      ),
      (aggregatedRow) => (
        <b>
          {aggregatedRow.stockItems
            .reduce((sum, stockItem) => getStock(stockItem) + sum, 0)
            .toLocaleString()}{" "}
          {t("Stk.")}
        </b>
      )
    ),
    getNumber: aggregatedRowSum(getStock),
    unit: { position: "end", name: t("Stk.") },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "currentWeight",
    title: t("Gesamtgewicht"),
    getNumber: aggregatedRowSum((row) => {
      const count = getStock(row);
      const { area } = row.details;
      const { specificWeight } = row.article.details;
      if (isNaN(specificWeight)) {
        return null;
      }
      return count * (area * specificWeight * 1e-6);
    }),
    unit: { position: "end", name: "kg" },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "article.length",
    title: t("Länge"),
    getNumber: ifStockItem((row) => row.details.length),
    unit: { position: "end", name: "mm" },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "article.width",
    title: t("Breite"),
    getNumber: ifStockItem((row) => row.details.width),
    unit: { position: "end", name: "mm" },
    range: { min: 0, max: null },
  }),
  numberColumn({
    id: "details.area",
    title: t("Fläche"),
    getNumber: ifStockItem((row) => row.details.area * 1e-6),
    unit: { position: "end", name: "m²" },
    range: { min: 0, max: null },
  }),
  {
    id: "details.shape",
    title: t("Form"),
    content: ifStockItem((row) => (
      <ShapePreview
        cutouts={row.details.cutouts}
        sheetWidth={row.details.width}
        sheetLength={row.details.length}
        width={32}
        height={32}
        sku={row.sku}
        style={{ verticalAlign: "middle" }}
      />
    )),
    tooltip: () => null,
    sortable: false,
    export: {
      exclude: true,
    },
  },
  numberColumn({
    id: "article.details.thickness",
    title: t("Dicke"),
    getNumber: (row) => row.article.details.thickness,
    fractionalDigits: 2,
    unit: { position: "end", name: "mm" },
    range: { min: 0, max: null },
  }),
  {
    id: "article.details.material",
    title: t("Werkstoff"),
    content: (row) => row.article.details.material,
    contentString: (row) => row.article.details.material,
    filter: {
      type: StringSearchFilter,
    },
  },
];
