import React from "react";
import EnhancedTable from "@wa/werkstoff-data-table";
import StringValuesFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringValuesFilter";
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import DateRangeFilter from "@wa/werkstoff-data-table/lib/FilterPopover/DateRangeFilter";
import withLocalStorageConfig from "@wa/werkstoff-data-table/lib/withLocalStorageConfig";
import withVerticalScroll from "@wa/werkstoff-data-table/lib/withVerticalScroll";
import { useRegistry } from "../../plugins/registry";
import { useTranslation } from "react-i18next";
import { compareNaturalBy } from "../../../util/compareNatural";

const RawLogTable = withLocalStorageConfig("storekeeper-log")(
  withVerticalScroll()(EnhancedTable)
);

function logItemToStrings(item, columns, rawValue = true) {
  return columns
    .filter((c) => c.export == null || !c.export.exclude)
    .map((c) => {
      if (rawValue !== false && c.export && c.export.getRawValue) {
        return c.export.getRawValue(item);
      }
      if (c.contentString) {
        return c.contentString(item);
      } else if (c.content) {
        return c.content(item);
      } else {
        return item[c.id];
      }
    })
    .map((value) => (value == null ? "" : value.toString()));
}

export default function LogTable({ search, articleType, ...other }) {
  const { t } = useTranslation();
  const registry = useRegistry();

  const columns = registry.getLogTableConfig(articleType, [
    {
      id: "date",
      title: t("Datum"),
      content: (row) => new Date(row.date).toLocaleString(),
      compare: (a, b) => new Date(a.date) - new Date(b.date),
      filter: {
        type: DateRangeFilter,
        getDate: (row) => new Date(row.date),
      },
    },
    {
      id: "name",
      title: t("Artikel"),
      contentString: (row) =>
        registry.logItemTypes[row.type]?.articleName?.(row, { registry }) ??
        row.stockItem?.article?.name ??
        row.article?.name ??
        "",
      compare: compareNaturalBy(
        (row) => row.stockItem?.article?.name ?? row.article?.name
      ),
      filter: {
        type: StringSearchFilter,
      },
    },
    {
      id: "sku",
      title: t("Artikelnr."),
      contentString: (row) =>
        registry.logItemTypes[row.type]?.sku(row, { registry }) ?? "",
      compare: compareNaturalBy((row) =>
        registry.logItemTypes[row.type]?.sku(row, { registry })
      ),
      filter: {
        type: StringSearchFilter,
      },
    },
    {
      id: "change",
      title: t("Änderung"),
      content: (row) =>
        registry.logItemTypes[row.type]?.change(row, { registry, t }) ?? "",
      contentString: (row) => JSON.stringify(row.change), // TODO this is slightly fuzzy but sufficient for searching
      filter: {
        type: StringSearchFilter,
      },
      sortable: false,
    },
    {
      id: "employee",
      title: t("Mitarbeiter"),
      contentString: (row) => row.employee || "–",
      filter: {
        type: StringValuesFilter,
        getString: (row) => row.employee || t("(keine Angabe)"),
      },
    },
    {
      id: "type",
      title: t("Aktion"),
      contentString: (row) =>
        registry.logItemTypes[row.type]?.type(row, { t }) ?? "",
      filter: {
        type: StringValuesFilter,
      },
    },
    {
      id: "note",
      title: t("Bemerkung"),
      contentString: (row) => row.note || "",
      filter: {
        type: StringSearchFilter,
        getString: (row) => row.note || "",
      },
    },
  ]);

  return (
    <RawLogTable
      rowId={(row) => row.id}
      columns={columns}
      rowFilter={
        search == null || search.trim().length === 0
          ? undefined
          : (row) =>
              search
                .toLowerCase()
                .split(/\s+/)
                .every((s) =>
                  logItemToStrings(row, columns, false).some(
                    (value) => value.toString().toLowerCase().indexOf(s) >= 0
                  )
                )
      }
      {...other}
    />
  );
}
