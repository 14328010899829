import * as Sentry from "@sentry/react";
import { serverUrl } from "../core/api";
import api, { otherExports } from "./pluginApi";

const plugins = {};
const registerPluginCallbacks = [];

window.storekeeperPluginHook = {
  registerPlugin(name, implementation) {
    plugins[name] = implementation(api);
    console.log(`[PluginLoader] ${name} initialized`);
    registerPluginCallbacks.forEach((cb) => cb(name));
    return api;
  },
  ...otherExports,
};

export default async function loadPlugins() {
  try {
    const pluginsList = await fetch(`${serverUrl}/plugins/client`).then((r) =>
      r.json()
    );
    const pluginScripts = (
      await Promise.all(
        pluginsList.map(async (plugin) => ({
          plugin,
          src: await fetch(`${serverUrl}${plugin}`).then((r) => {
            if (r.ok) {
              return r.text();
            }
            console.error(`[PluginLoader] Plugin ${plugin} failed to load`);
            Sentry.captureMessage(
              `Plugin ${plugin} failed to load`,
              (scope) => {
                scope.setExtra("plugin", plugin);
                scope.setLevel("error");
              }
            );
          }),
        }))
      )
    ).filter((loaded) => !!loaded);

    for (const { plugin, src } of pluginScripts) {
      const warning =
        process.env.NODE_ENV === "production"
          ? `${plugin} threw an error during initial execution. Maybe you're using a dev build of the plugin in a production build of StoreKeeper Smart?`
          : `${plugin} threw an error during initial execution. Maybe you're using a production build of the plugin in a dev build of StoreKeeper Smart?`;

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.text = `try{${src}}catch(e){console.warn("${warning}");console.error(e)}`;
      let failed = false;
      const handleError = (e) => {
        e.stopPropagation();
        e.preventDefault();
        console.error(`[PluginLoader] Injecting plugin ${plugin} failed`);
        console.error(e.error);
        failed = true;
        Sentry.captureException(e.error, (scope) => {
          scope.setExtra("plugin", plugin);
        });
      };
      window.addEventListener("error", handleError);
      document.head.appendChild(script);
      window.removeEventListener("error", handleError);
      if (!failed) {
        console.log(`[PluginLoader] ${plugin} injected`);
      }
    }
  } catch (e) {
    console.error("[PluginLoader] Loading plugins failed", e);
  }
}
