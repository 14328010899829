import React, { useCallback, useRef, useState } from "react";
import {
  Button,
  Popover,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DefaultCompartmentSelector from "../articles/components/DefaultCompartmentSelector";
import ComponentExtensionPoint from "../plugins/ComponentExtensionPoint";
import { useRegistry } from "../plugins/registry";

const styles = {
  inputField: {
    margin: "4px 0 16px",
    width: "100%",
  },
  bottom: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  submit: {
    float: "right",
    marginLeft: 24,
  },
  popover: {
    padding: 16,
    maxWidth: 350,
    display: "flex",
    flexDirection: "column",
  },
  autoRfidSubmit: {
    margin: "16px 0",
  },
};

function LoadCarrierForm({
  classes,
  loadCarrier: initialLoadCarrier,
  onSubmit,
  onRemove,
  onAutoRfid,
}) {
  const { t } = useTranslation();
  const { pages } = useRegistry();
  const rfidPluginAvailable = pages.some((p) => p.route === "rfidTags");

  const [loadCarrier, setLoadCarrier] = useState({
    ...initialLoadCarrier,
  });

  const handleChangeName = useCallback((e) => {
    const name = e.target.value;
    setLoadCarrier((l) => ({ ...l, name }));
  }, []);

  const anchorEl = useRef();
  const [autoRfidTagOpen, setAutoRfidTagOpen] = useState(false);

  const handleAutoRfidTag = useCallback((e) => {
    anchorEl.current = e.target;
    setAutoRfidTagOpen(true);
  }, []);

  const [compartment, setCompartment] = useState();
  const handleConfirmAutoRfid = useCallback(() => {
    if (compartment) {
      onAutoRfid(loadCarrier, compartment);
    }
    setAutoRfidTagOpen(false);
  }, [onAutoRfid, compartment, loadCarrier]);

  const isNew = loadCarrier.id == null;

  return (
    <>
      <TextField
        label={t("Bezeichnung des Ladungsträgers")}
        value={loadCarrier?.name ?? ""}
        onChange={handleChangeName}
        fullWidth
        className={classes.inputField}
        autoFocus={isNew}
      />
      <div className={classes.bottom}>
        <Button
          className={classes.submit}
          variant="outlined"
          color="primary"
          onClick={() => onSubmit(loadCarrier)}
          disabled={loadCarrier?.name.trim().length === 0}
        >
          {isNew ? t("Hinzufügen") : t("Speichern")}
        </Button>
        {!isNew && rfidPluginAvailable && (
          <Button
            className={classes.submit}
            variant="outlined"
            onClick={handleAutoRfidTag}
          >
            {t("RFID-Tag aus Fach…")}
          </Button>
        )}
        {!isNew && (
          <Button
            variant="outlined"
            onClick={() => onRemove(initialLoadCarrier)}
          >
            {t("Entfernen")}
          </Button>
        )}
      </div>

      <Popover
        anchorEl={anchorEl.current}
        open={autoRfidTagOpen}
        onClose={() => setAutoRfidTagOpen(false)}
        classes={{ paper: classes.popover }}
      >
        <Typography variant="body1">
          {t("RFID-Tag aus Fach übernehmen")}
        </Typography>
        <Typography variant="caption">{loadCarrier.name}</Typography>
        <ComponentExtensionPoint
          name="compartmentSelector"
          variant="single"
          innerProps={{
            value: compartment,
            variant: "standard",
            onChange: setCompartment,
            fullWidth: true,
          }}
          DefaultComponent={DefaultCompartmentSelector}
        />
        <Button
          className={classes.autoRfidSubmit}
          variant="outlined"
          color="primary"
          onClick={handleConfirmAutoRfid}
          disabled={compartment == null}
        >
          {t("Übernehmen")}
        </Button>
        <Typography variant="caption" className={classes.footnote}>
          {t(
            "Der RFID-Tag des Ladungsträgers und die Lagerorte aller Artikel auf diesem Ladungsträger werden automatisch aktualisiert."
          )}
        </Typography>
      </Popover>
    </>
  );
}

export default withStyles(styles)(LoadCarrierForm);
