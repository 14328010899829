import React from "react";
import PropTypes from "prop-types";
import { DialogTitle, withStyles, Typography } from "@material-ui/core";
import cx from "classnames";

const styles = theme => ({
  root: {
    "& > button": {
      position: "absolute",
      right: theme.spacing.unit,
      top: theme.spacing.unit,
      color: theme.palette.action.active
    }
  }
});

function ButtonDialogTitle({ classes, className, children, button, ...other }) {
  return (
    <DialogTitle
      {...other}
      disableTypography
      className={cx(classes.root, className)}
    >
      <Typography variant="h6">{children}</Typography>
      {button}
    </DialogTitle>
  );
}

ButtonDialogTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  button: PropTypes.node.isRequired
};

export default withStyles(styles)(ButtonDialogTitle);
