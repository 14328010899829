import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { BarcodeScan } from "mdi-material-ui";
import React, { Suspense, useCallback, useEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import BarcodeScanner from "../../../../components/BarcodeScanner";
import useLocalStorageState from "../../../../util/useLocalStorageState";

const QrCodeReader = React.lazy(() =>
  import("../../../../components/QrCodeReader")
);

const styles = (theme) => ({
  employee: {
    width: 250,
    margin: `${theme.spacing.unit * 3}px auto 0`,
    display: "block",
  },
  alternative: {
    display: "flex",
  },
  qrScanner: {
    height: 400,
    marginTop: theme.spacing.unit * 2,
  },
  barcodeScanner: {
    fontSize: "48px",
    margin: `${theme.spacing.unit * 3}px auto 0`,
    display: "block",
  },
  flex: {
    flex: 1,
  },
  cameraLoading: {
    marginTop: theme.spacing.unit * 2,
    background: "#000",
    height: 400,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.shape.borderRadius,
    "& > *": {
      color: "#fff",
    },
  },
  loading: {
    margin: "0 auto",
  },
  selectRoot: {
    marginLeft: theme.spacing.unit,
  },
  select: {
    paddingLeft: theme.spacing.unit,
    fontSize: "0.875rem",
  },
});

function UserForm({ classes, onSubmit, loading, authenticationError }) {
  const { t } = useTranslation();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [mode, setMode] = useLocalStorageState(
    "skl-user-login-mode",
    "credentials"
  );

  const handleSetMode = useCallback(
    (e) => {
      setMode(e.target.value);
    },
    [setMode]
  );

  const onSubmitRef = useRef(onSubmit);
  useEffect(() => {
    onSubmitRef.current = onSubmit;
  }, [onSubmit]);

  const codeProcessing = useRef(false);
  const handleLoginCode = useCallback(async (content) => {
    if (
      codeProcessing.current ||
      !(content.startsWith("ey") || content.startsWith("L-"))
    )
      return;
    codeProcessing.current = true;
    try {
      await onSubmitRef.current(
        content.startsWith("ey")
          ? { accessToken: content }
          : { barcode: content }
      );
    } finally {
      codeProcessing.current = false;
    }
  }, []);

  return (
    <>
      <DialogContent>
        <DialogContentText color="textPrimary" variant="body2">
          {mode === "credentials" ? (
            <Trans>
              Bitte geben Sie Ihre Zugangsdaten ein, um den StoreKeeper Smart zu
              benutzen.
            </Trans>
          ) : (
            <Trans>
              Bitte scannen Sie Ihren QR-Code oder Barcode, um den StoreKeeper
              Smart zu benutzen.
            </Trans>
          )}
        </DialogContentText>
        {mode === "credentials" && (
          <>
            <TextField
              className={classes.employee}
              label={t("Benutzername")}
              margin="dense"
              variant="filled"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoFocus
              error={authenticationError}
              InputLabelProps={{ shrink: true }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  onSubmit({ username, password });
                }
              }}
            />
            <TextField
              className={classes.employee}
              label={t("Passwort")}
              margin="dense"
              variant="filled"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={authenticationError}
              helperText={
                authenticationError ? t("Falsche Zugangsdaten") : undefined
              }
              InputLabelProps={{ shrink: true }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  onSubmit({ username, password });
                }
              }}
            />
          </>
        )}
        {mode === "camera" && (
          <Suspense
            fallback={
              <div className={classes.cameraLoading}>
                <CircularProgress />
              </div>
            }
          >
            <QrCodeReader
              className={classes.qrScanner}
              onScan={handleLoginCode}
            />
          </Suspense>
        )}
        {mode === "scanner" && (
          <BarcodeScanner onScan={handleLoginCode}>
            <BarcodeScan className={classes.barcodeScanner} />
          </BarcodeScanner>
        )}
        {mode !== "credentials" && authenticationError && (
          <Typography color="error">
            {t("Der gescannte Code ist ungültig.")}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Select
          value={mode}
          onChange={handleSetMode}
          disableUnderline
          classes={{ select: classes.select, root: classes.selectRoot }}
        >
          <MenuItem value="credentials">{t("Benutzername/Passwort")}</MenuItem>
          <MenuItem value="camera">{t("QR-Code (Kamera)")}</MenuItem>
          <MenuItem value="scanner">
            {t("QR-Code/Barcode (Barcode-Scanner)")}
          </MenuItem>
        </Select>
        <div className={classes.flex} />
        <Button
          color="primary"
          onClick={() => onSubmit({ username, password })}
          disabled={
            username === "" ||
            password === "" ||
            loading ||
            mode !== "credentials"
          }
        >
          {t("Weiter", { context: "setup" })}
        </Button>
      </DialogActions>
    </>
  );
}

export default withStyles(styles)(UserForm);
