import React from "react";
import StringSearchFilter from "@wa/werkstoff-data-table/lib/FilterPopover/StringSearchFilter";
import NumberRangeFilter from "@wa/werkstoff-data-table/lib/FilterPopover/NumberRangeFilter";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import NoteButton from "../../../components/NoteButton";
import RenamePopover from "../../../components/RenamePopover";
import { compareNatural } from "../../../util/compareNatural";
import { useTranslation } from "react-i18next";

export const noteColumn = ({
  type,
  noteIndex,
  note,
  defaultTitle,
  title,
  itemId,
  ...other
}) => ({
  content: (row, { cellContentParams: { handleSaveNote } }) => (
    <NoteButton
      note={note(row)}
      id={itemId(row)}
      onSaveNote={(e, { ...other }) =>
        handleSaveNote(e, { ...other, noteIndex, type })
      }
    />
  ),
  contentString: (row) => note(row)?.text || "",
  tooltip: (row) => note(row)?.text || "",
  compare: (a, b) => {
    const noteA = note(a);
    const noteB = note(b);
    if (noteA && noteB) {
      return compareNatural(noteA?.text, noteB?.text);
    } else if (noteA) {
      return -1;
    } else if (noteB) {
      return 1;
    } else {
      return 0;
    }
  },
  filter: {
    type: StringSearchFilter,
    getSearchString: (row) => note(row)?.text || "",
  },
  headerActions: ({ handleSaveNoteColumnTitle }) => (
    <RenamePopover
      key={title || defaultTitle(noteIndex)}
      defaultTitle={title || defaultTitle(noteIndex)}
      onSubmit={(e, title) =>
        handleSaveNoteColumnTitle(e, { noteIndex, title, type })
      }
    />
  ),
  title: title || defaultTitle(noteIndex),
  ...other,
});

export const currentStockFilter = (config) => ({
  type: {
    ...NumberRangeFilter,
    defaultFilterValue: {
      ...NumberRangeFilter.defaultFilterValue,
      minimumStockReached: false,
    },
    filter: (filter, column, row) => {
      if (
        filter.minimumStockReached &&
        (row.article.minimumStock == null ||
          row.article.minimumStock < config.getStock(row))
      ) {
        return false;
      }
      return NumberRangeFilter.filter(filter, column, row);
    },
    popoverComponent: (props) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { t } = useTranslation();
      const { minimumStockReached = false } = props.filter;
      const handleChangeMinimumStockReached = (e) => {
        props.onUpdateFilter({
          ...props.filter,
          minimumStockReached: e.target.checked,
          inactive:
            props.filter.min == null &&
            props.filter.max == null &&
            !e.target.checked,
        });
      };

      const handleUpdateFilter = (newValue) => {
        props.onUpdateFilter({
          ...newValue,
          minimumStockReached: newValue.inactive
            ? false
            : props.filter.minimumStockReached,
        });
      };

      return (
        <React.Fragment>
          <FormControlLabel
            control={
              <Checkbox
                checked={minimumStockReached}
                onChange={handleChangeMinimumStockReached}
                style={{ padding: "0 12px" }}
              />
            }
            label={t("Meldebestand erreicht")}
            style={{ marginBottom: 8 }}
          />
          <NumberRangeFilter.popoverComponent
            {...props}
            onUpdateFilter={handleUpdateFilter}
          />
        </React.Fragment>
      );
    },
  },
});

export const compartmentColumn = ({ t }) => ({
  id: "compartment",
  title: t("Fach"),
  contentString: (row) =>
    row.compartment?.compartment ?? row.loadCarrier?.compartment?.compartment,
  compare: emptyToBottom(
    maybeAggregated(
      (item) =>
        (item.compartment?.compartment ??
          item.loadCarrier?.compartment?.compartment) ||
        ""
    )
  ),
  filter: {
    type: StringSearchFilter,
  },
});

export const towerColumn = ({ t }) => ({
  id: "location",
  title: t("Turm"),
  contentString: (row) =>
    row.compartment?.tower?.location ??
    row.loadCarrier?.compartment?.tower?.location,
  compare: emptyToBottom(
    maybeAggregated(
      (item) =>
        (item.compartment?.tower?.location ??
          item.loadCarrier?.compartment?.tower?.location) ||
        ""
    )
  ),
  filter: {
    type: StringSearchFilter,
  },
});

export const loadCarrierColumn = ({ t }) => ({
  id: "chargeCarrier",
  title: t("Ladungsträger"),
  contentString: (row) => row.loadCarrier?.name,
  compare: emptyToBottom(maybeAggregated((item) => item.loadCarrier?.name)),
  filter: {
    type: StringSearchFilter,
  },
});

export const aggregatedRowSum = (getStockItemValue) => {
  return (row, ...args) => {
    if (row.__typename === "AggregatedStockItems") {
      return row.stockItems.reduce(
        (sum, row) => sum + (getStockItemValue(row, ...args) || 0),
        0
      );
    } else {
      return getStockItemValue(row, ...args);
    }
  };
};

export const ifStockItem =
  (getValue, getAggregatedValue) =>
  (row, ...args) =>
    row.__typename === "AggregatedStockItems"
      ? getAggregatedValue?.(row, ...args)
      : getValue?.(row, ...args);

export const emptyToBottom = (getValue) => (a, b) => {
  const aValue = getValue(a);
  const bValue = getValue(b);
  if (!aValue) {
    return bValue ? 1 : 0;
  }
  return bValue ? aValue.localeCompare(bValue) : -1;
};

export const maybeAggregated = (cb) => (itemOrAggregated) =>
  cb(
    itemOrAggregated.stockItems?.length === 1
      ? itemOrAggregated.stockItems[0]
      : itemOrAggregated
  );
