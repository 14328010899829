import React, { useCallback } from "react";
import { Button, Snackbar } from "@material-ui/core";

export default function EnhancedSnackbar({
  message,
  action,
  ButtonProps,
  SnackbarProps,
  customParameters,
}) {
  const handleClose = useCallback(
    (...args) => {
      SnackbarProps.onClose(...args);
      if (customParameters && customParameters.onClose) {
        customParameters.onClose(...args);
      }
    },
    [SnackbarProps, customParameters]
  );

  return (
    <Snackbar
      {...SnackbarProps}
      autoHideDuration={
        customParameters && "autoHideDuration" in customParameters
          ? customParameters.autoHideDuration
          : SnackbarProps.autoHideDuration
      }
      onClose={handleClose}
      message={message || ""}
      action={
        action != null && (
          <Button color="secondary" size="small" {...ButtonProps}>
            {action}
          </Button>
        )
      }
    />
  );
}
