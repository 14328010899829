import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Button,
  IconButton,
  Popover,
  TextField,
  withStyles,
} from "@material-ui/core";
import { Pencil } from "mdi-material-ui";
import cx from "classnames";
import { useTranslation } from "react-i18next";

const styles = {
  root: {
    padding: 4,
    width: 24,
    height: 24,
  },
  icon: {
    width: "100%",
    height: "100%",
  },
  popoverPaper: {
    width: 300,
    padding: 16,
  },
  input: {
    width: "100%",
    flex: 1,
  },
  submitButton: {
    margin: "8px -8px -8px 0",
    float: "right",
  },
};

function RenamePopover(props2) {
  const { t } = useTranslation();

  const { classes, className, defaultTitle, onSubmit } = props2;
  const [anchorEl, setAnchorEl] = useState(null);
  const [title, setTitle] = useState(defaultTitle);

  const handleOpen = useCallback(
    (e) => {
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
      setTitle(defaultTitle);
    },
    [defaultTitle]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChangeInput = useCallback((e) => {
    setTitle(e.target.value);
  }, []);

  const handleSave = useCallback(
    (e) => {
      handleClose();
      onSubmit(e, title);
    },
    [handleClose, onSubmit, title]
  );

  return (
    <React.Fragment>
      <IconButton className={cx(classes.root, className)} onClick={handleOpen}>
        <Pencil className={classes.icon} />
      </IconButton>
      <Popover
        id="column-popover"
        open={anchorEl != null}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{ paper: classes.popoverPaper }}
      >
        <TextField
          value={title}
          onChange={handleChangeInput}
          className={classes.input}
          autoFocus
        />
        <Button
          variant="text"
          size="small"
          className={classes.submitButton}
          onClick={handleSave}
          disabled={title.trim() === ""}
        >
          {t("Speichern")}
        </Button>
      </Popover>
    </React.Fragment>
  );
}

RenamePopover.propTypes = {
  /** @ignore */
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  defaultTitle: PropTypes.string,
};

export default withStyles(styles)(RenamePopover);
