import React from "react";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

export function EnhancedTooltip(props: TooltipProps) {
  return <Tooltip disableFocusListener enterDelay={250} {...props} />;
}

export type TooltipIconButtonProps = {
  tooltip?: string;
  TooltipProps?: TooltipProps;
  closeTooltipOnClick?: boolean;
} & Partial<IconButtonProps>;

export default function TooltipIconButton({
  tooltip,
  TooltipProps,
  closeTooltipOnClick = true,
  ...other
}: TooltipIconButtonProps) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (other.disabled) {
      setOpen(false);
    }
  }, [other.disabled]);

  return tooltip ? (
    <EnhancedTooltip
      title={tooltip}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      {...TooltipProps}
    >
      <IconButton
        {...other}
        onClick={(e) => {
          if (closeTooltipOnClick) {
            setOpen(false);
          }
          other.onClick && other.onClick(e);
        }}
      />
    </EnhancedTooltip>
  ) : (
    <IconButton {...other} />
  );
}
